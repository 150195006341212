import React, { useState, useCallback, createContext, ReactNode } from 'react';
import { FeatureFlag } from 'src/domain/featureFlag';

export type FeatureToggleContextProps = {
  enabledFeatures: FeatureFlag[];
  featureIsEnabled: (featureFlag: FeatureFlag) => boolean;
  setEnabledFeatures: (features: FeatureFlag[]) => void;
};

export const FeatureToggleContext = createContext<FeatureToggleContextProps>(null);

export const FeatureToggleProvider = ({ children }: { children: ReactNode }) => {
  const [enabledFeatures, setEnabledFeatures] = useState<FeatureFlag[]>([]);

  const featureIsEnabled = useCallback(
    featureName => enabledFeatures && enabledFeatures.includes(featureName),
    [enabledFeatures]
  );

  return (
    <FeatureToggleContext.Provider
      value={{ featureIsEnabled, enabledFeatures, setEnabledFeatures }}
    >
      {children}
    </FeatureToggleContext.Provider>
  );
};
