import React from 'react';
import styled from 'styled-components';
import KeyboardArrowDownSharp from '@material-ui/icons/KeyboardArrowDownSharp';
import { StealthButton } from 'src/components/Buttons';

const RotatingArrow = styled(KeyboardArrowDownSharp)<{ open: boolean }>`
  && {
    transition: 0.3s ease-out;
    transform: rotate(${props => (props.open ? '180deg' : '0deg')});
  }
`;

type CollapseButtonProps = {
  open: boolean;
  [x: string]: unknown;
};

export default function CollapseButton({ open, ...props }: CollapseButtonProps) {
  return (
    <StealthButton aria-expanded={open} {...props}>
      <RotatingArrow open={open} />
    </StealthButton>
  );
}
