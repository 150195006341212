export const DESC = 1;
export const ASC = 0;

export type Direction = typeof ASC | typeof DESC;

export type KeysWithStringValue<T> = {
  [K in keyof T]: T[K] extends string ? K : never;
}[keyof T];

export type Sort<T extends Record<string, unknown>> = {
  field: KeysWithStringValue<T>;
  direction: Direction;
};

function numberWithZeroPadding(n: number | string) {
  const number = `${n}`;
  return number.length >= 4 ? number : new Array(4 - number.length + 1).join('0') + number;
}

// Brukes til å sortere tall som er formatert som tekst ref: https://stackoverflow.com/questions/4340227/sort-mixed-alpha-numeric-array
const reA = /([\d]+)(-([\d]+))?/;

export function sortByAlphaNum<T extends Record<string, unknown>>(sort: Sort<T>) {
  return (a: T, b: T) => {
    if (a[sort.field] === null) return 1;
    if (b[sort.field] === null) return -1;

    const aMatch = (<string>a[sort.field]).match(reA);
    const bMatch = (<string>b[sort.field]).match(reA);

    if (!aMatch || !bMatch) return 0;
    let aA = numberWithZeroPadding(aMatch[1]);
    let bA = numberWithZeroPadding(bMatch[1]);
    aA += numberWithZeroPadding(parseInt(aMatch[3], 10) + 1 || 0);
    bA += numberWithZeroPadding(parseInt(bMatch[3], 10) + 1 || 0);

    if (aA === null || bA === null) return 0;
    if (aA === bA) {
      return 0;
    }
    if (aA > bA) {
      return sort.direction === DESC ? -1 : 1;
    }
    return sort.direction === DESC ? 1 : -1;
  };
}

function isNotValid(value: unknown) {
  return value == null;
}

export function sortBy<T extends Record<string, unknown>>(sort: Sort<T>) {
  return (a: T, b: T) => {
    const valA = <string>a[sort.field];
    const valB = <string>b[sort.field];

    if (isNotValid(valA) && isNotValid(valB)) {
      return 0;
    }
    if (isNotValid(valA)) {
      return 1;
    }
    if (isNotValid(valB)) {
      return -1;
    }

    const nameA = valA.toUpperCase();
    const nameB = valB.toUpperCase();

    if (nameA < nameB) {
      return sort.direction === DESC ? 1 : -1;
    }
    if (nameA > nameB) {
      return sort.direction === DESC ? -1 : 1;
    }
    return DESC ? 1 : -1;
  };
}
