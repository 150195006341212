import React from 'react';
import { useSelector } from 'react-redux';
import { Sak } from 'src/domain/sak';
import useFetch from 'src/hooks/useFetch';
import { useAppDispatch } from 'src/store';
import { sakerActions } from 'src/store/sakerStore';
import { selectSaker } from 'src/store/selectors/sakerSelectors';
import { WithLoaderProps } from './withLoader';

type WithSakerProps = {
  saker: Sak[];
};

type WithSakProps = {
  sak: Sak;
};

type EnhancedWithSakProps = {
  sakId: string;
};

export const withSaker =
  <P extends WithLoaderProps>(
    Component: React.ComponentType<P & WithSakerProps>
  ): React.ComponentType<P> =>
  props => {
    const dispatch = useAppDispatch();
    const saker = useSelector(selectSaker);

    const response = useFetch<Sak[]>('/saker', saker => {
      dispatch(sakerActions.setSaker(saker));
    });

    const isLoading = props.isLoading || response.isNotAsked || response.isPending;
    const hasError = props.hasError || response.isError;

    return <Component {...props} saker={saker} isLoading={isLoading} hasError={hasError} />;
  };

export const withSak = <P extends WithLoaderProps>(
  Component: React.ComponentType<P & WithSakProps>
): React.ComponentType<P & EnhancedWithSakProps> =>
  withSaker(props => {
    const sak = props.saker.find(x => x.sakId == props.sakId);
    const notFound = props.notFound || !sak;

    return <Component {...props} sak={sak} notFound={notFound} />;
  });
