import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { brukerActions } from 'src/store/brukerStore';
import { PrimaryButton, TertiaryButton } from 'src/components/Buttons';
import ActionBar from 'src/components/ActionBar';
import { withInnloggetBrukerPage } from 'src/hoc/withPage';
import useTranslate from 'src/hooks/useTranslate';
import { Språk } from 'src/constants';
import { BrukervilkårContainer } from './Brukervilkår.style';
import { post, isSuccess } from 'src/ajax';
import useShowErrorMessage from 'src/hooks/useShowErrorMessage';
import { withFetchBrukervilkår } from 'src/hoc/withFetch';
import withLoader from 'src/hoc/withLoader';
import { selectValgtSpråk } from 'src/store/selectors/brukerSelectors';
import { Brukervilkar } from 'src/domain/brukerVilkar';
import { useAppDispatch } from 'src/store';
import { GrayContainer } from 'src/components/Container/Container';
import { H2 } from 'src/components/Typography';

type BrukervilkårProps = {
  brukervilkår: Brukervilkar;
};

function Brukervilkår({ brukervilkår }: BrukervilkårProps) {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const valgtSpråk = useSelector(selectValgtSpråk);
  const translate = useTranslate();
  const showErrorMessage = useShowErrorMessage();

  const { bokmålHtml, nynorskHtml, samiskHtml, versjon } = brukervilkår;

  function aksepter(): Promise<void> {
    return post('/brukervilkar/aksepter', versjon).then(response => {
      if (isSuccess(response)) {
        dispatch(brukerActions.aksepterBrukervilkår());
        history.replace('/');
      } else {
        showErrorMessage('brukervilkår.aksepter-feilet');
      }
    });
  }

  function avslå() {
    history.replace('/avslabrukervilkar');
  }

  const språkToHtml: { [key in Språk]: string } = {
    [Språk.Bokmål]: bokmålHtml,
    [Språk.Nynorsk]: nynorskHtml,
    [Språk.Samisk]: samiskHtml,
  };

  const html = språkToHtml[valgtSpråk];

  return (
    <GrayContainer>
      <H2>{translate('brukervilkår.tittel')}</H2>
      <BrukervilkårContainer dangerouslySetInnerHTML={{ __html: html }} />
      <ActionBar>
        <TertiaryButton onClick={avslå}>{translate('brukervilkår.avslå')}</TertiaryButton>
        <PrimaryButton onClickAsync={aksepter}>{translate('brukervilkår.godta')}</PrimaryButton>
      </ActionBar>
    </GrayContainer>
  );
}

export default withInnloggetBrukerPage(
  withFetchBrukervilkår(
    withLoader('brukervilkår.henter', 'brukervilkår.henter-feilet')(Brukervilkår)
  )
);
