import React from 'react';
import styled from 'styled-components';
import Page from 'src/components/Page';

const AvregistrertContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default function Avregistrert() {
  return (
    <Page hideLogout>
      <AvregistrertContainer>
        <iframe
          className="airtable-embed airtable-dynamic-height"
          src="https://airtable.com/embed/shrSVm6oh6X3mQgy7?backgroundColor=yellow"
          width="100%"
          frameBorder={0}
          height="930"
          style={{ background: 'transparent' }}
        />
      </AvregistrertContainer>
    </Page>
  );
}
