import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { oppgaverActions } from 'src/store/oppgaverStore';
import { isSuccess, multipartPost } from 'src/ajax';
import useTranslate from 'src/hooks/useTranslate';
import { withAkseptertBrukervilkårPage } from 'src/hoc/withPage';
import withRouteSakId from 'src/hoc/withRouteSakId';
import { withSak } from 'src/hoc/withSaker';
import withLoader from 'src/hoc/withLoader';
import Dropdown from 'src/components/InputFields/Dropdown';
import Dropzone from 'src/components/Dropzone';
import Textarea from 'src/components/InputFields/Textarea';
import { InformationBox } from 'src/components/InformationBox/InformationBox';
import {
  H1,
  BodyType,
  InfoType,
  ErrorType,
  RegularType,
  LeadType,
  LabelType,
} from 'src/components/Typography';
import { PrimaryButton, SecondaryButton } from 'src/components/Buttons';
import { getFritakOptions, FritakOption } from './FritakOptions';
import Kvittering from './Kvittering';
import Footer from './Footer';

import { HorizontalHr, SøknadForm, DropzoneContainerFritak } from './Søknad.style';

import useShowErrorMessage from 'src/hooks/useShowErrorMessage';
import createBase64EncodedSha256Hash from 'src/utils/checksum';
import { composeValidators, maxLength, required } from 'src/utils/validators';
import { Sak } from 'src/domain/sak';
import { SoeknadOmFritak } from 'src/domain/soeknadOmFritak';
import { InfoOutlined } from '@material-ui/icons';
import { P } from '../Sak/components/MineOppgaver/Oppgaver/Oppgaver.style';
import { Ul, Li } from './Søknad.style';
import { DefaultAlert } from 'src/components/Alert/AlertBox';

// Declared outside of render loop so that object reference doesn't change
const dropzoneInitialValue: File[] = [];

type FormValues = {
  type?: FritakOption;
  begrunnelse?: string;
  files?: File[];
};

type FritakProps = {
  sak: Sak;
};

const Fritak = ({ sak }: FritakProps) => {
  const history = useHistory();

  const dispatch = useDispatch();
  const translate = useTranslate();
  const showErrorMessage = useShowErrorMessage();

  const [visKvittering, setVisKvittering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const validateDropdown = (type: FritakOption) => {
    return !type ? translate('søknad.fritak.dropdown-feil') : undefined;
  };

  const validateBegrunnelse = composeValidators(
    required(translate('søknad.textarea-feil')),
    maxLength(translate('validation.maxLength', 500), 500)
  );

  const onSubmit = async ({ files = [], begrunnelse = '', type }: FormValues) => {
    setIsLoading(true);

    const checksums = await Promise.all(files.map(createBase64EncodedSha256Hash));
    const data: SoeknadOmFritak = {
      sakId: sak.sakId,
      begrunnelse: begrunnelse,
      type: type?.value,
      embete: sak.domstol.kode,
      checksums,
    };

    const response = await multipartPost('/saker/fritak', data, files);

    if (isSuccess(response)) {
      dispatch(oppgaverActions.closeBekreftOppmøte());
      setVisKvittering(true);
    } else {
      if (response.statusCode === 418) {
        // Response code used by clammit when ClamAV detects virus
        const regexFilename = /File (.*) has a virus!/;
        const matches = regexFilename.exec(response.text);
        const filename = Array.isArray(matches) ? matches[1] : undefined;
        const link = 'https://www.domstol.no/virussjekk';

        if (filename) {
          showErrorMessage('søknad.innsending-feil-virus-i-fil', [filename], link);
        } else {
          showErrorMessage('søknad.innsending-feil-virus-ukjent-fil', [], link);
        }
      } else {
        showErrorMessage('søknad.innsending-feil');
      }
    }

    setIsLoading(false);
  };

  const epost = sak.domstol.epost;
  const telefon = sak.domstol.telefon;
  const gåTilSak = () => history.replace(`/saker/${sak.sakId}/mine-oppgaver`);

  return visKvittering ? (
    <Kvittering
      tittel={translate('søknad.fritak.tittel')}
      epost={epost}
      telefon={telefon}
      onTilbake={gåTilSak}
    >
      {translate('søknad.kvittering-info', sak.domstol.navn)}
    </Kvittering>
  ) : (
    <div>
      <H1>{translate('søknad.fritak.tittel')}</H1>
      <RegularType as="p">{translate('søknad.fritak.info1')}</RegularType>
      <RegularType as="p">{translate('søknad.fritak.info2')}</RegularType>
      <RegularType as="p">{translate('søknad.fritak.info3')}</RegularType>

      <DefaultAlert Icon={InfoOutlined} iconText={translate('søknad.fritak.infoboks-tittel')}>
        <InfoType>
          <Ul>
            <Li>{translate('søknad.fritak.infoboks1')}</Li>
            <Li>
              {translate('søknad.fritak.infoboks2')}
              <a href={`/saker/${sak.sakId}/inhabil`} target="_blank" rel="noreferrer noopener">
                {translate('søknad.fritak.inhabilitets-varsel')}
              </a>
            </Li>
            <Li>{translate('søknad.fritak.infoboks3')}</Li>
          </Ul>
        </InfoType>
      </DefaultAlert>

      <HorizontalHr />
      <LeadType as="p">{translate('søknad.fritak.saken-gjelder', sak.sakstema)}</LeadType>
      <LabelType as="p">{translate('søknad.fritak.saksnummer', sak.saksnummer)}</LabelType>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, values, errors, touched }) => (
          <SøknadForm onSubmit={handleSubmit}>
            <BodyType>{translate('søknad.fritak.plikt')} *</BodyType>
            <Field
              name="type"
              render={({ input, meta }) => (
                <Dropdown
                  placeholder={translate('søknad.fritak.dropdown-placeholder')}
                  options={getFritakOptions()}
                  isSearchable={false}
                  errorMessage={meta.touched ? meta.error : undefined}
                  aria-required="true"
                  {...input}
                />
              )}
              validate={validateDropdown}
            />

            <BodyType>{translate('søknad.fritak.hvorfor')} *</BodyType>
            <Field
              name="begrunnelse"
              render={({ input, meta }) => (
                <Textarea
                  {...input}
                  errorMessage={meta.touched ? meta.error : undefined}
                  aria-required="true"
                />
              )}
              validate={validateBegrunnelse}
            />

            <BodyType>{translate('søknad.fritak.dokumentasjon')}</BodyType>
            {values.type && (
              <InformationBox maxWidth="456px">
                <InfoType>{values.type.documentation}</InfoType>
              </InformationBox>
            )}
            <DropzoneContainerFritak showInfo={values.type != null}>
              <Field
                name="files"
                render={({ input }) => (
                  <Dropzone onChange={input.onChange} files={input.value} aria-required="true" />
                )}
                initialValue={dropzoneInitialValue}
              />
            </DropzoneContainerFritak>
            {touched?.files && !!errors?.files && <ErrorType>{errors.files}</ErrorType>}

            <P>
              <RegularType>{translate('søknad.fritak.domstol-info-1')}</RegularType>
            </P>
            <P>
              <RegularType>{translate('søknad.fritak.domstol-info-2')}</RegularType>
            </P>
            <P>
              <RegularType>
                <a
                  href="https://lovdata.no/dokument/NL/lov/1915-08-13-5/KAPITTEL_6#KAPITTEL_6"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {translate('søknad.fritak.domstol-info-3')}
                </a>
              </RegularType>
            </P>

            <HorizontalHr />

            <Footer epost={epost} telefon={telefon}>
              <SecondaryButton role="link" onClick={gåTilSak}>
                {translate('avbryt')}
              </SecondaryButton>
              <PrimaryButton type="submit" isLoading={isLoading}>
                {translate('søknad.domstol-send')}
              </PrimaryButton>
            </Footer>
          </SøknadForm>
        )}
      />
    </div>
  );
};

export default withAkseptertBrukervilkårPage(
  withRouteSakId(withSak(withLoader('søknad.henter', 'søknad.henter-feilet')(Fritak)))
);
