import React from 'react';
import styled, { css } from 'styled-components';
import NavLink from 'src/components/NavLink';
import { Color } from 'src/constants';
import { ButtonProps, NavigationButton } from 'src/components/Buttons';
import { NavLinkProps } from 'react-router-dom';

export const NavContainer = styled.nav`
  width: 100%;
  display: flex;
  align-items: center;

  *:first-child {
    margin-right: auto;
  }

  @media (max-width: 424px) {
    display: none;
  }
`;

type StyledNavButtonProps = {
  noUnderline?: boolean;
};

const navButtonStyle = css<StyledNavButtonProps>`
  text-decoration: none;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 1.5rem;
  border-bottom: ${props => (props.noUnderline ? null : `solid 1px rgba(42,42,42,0.48)`)};
  color: ${Color.GreyDark};
  box-sizing: border-box;
  padding: 4px 0;
  :hover {
    border-bottom: ${props => (props.noUnderline ? null : `solid 1px ${Color.GreyDark}`)};
  }
`;

export const StyledNavButton = styled(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ noUnderline, ...rest }: ButtonProps & StyledNavButtonProps) => (
    <NavigationButton noPadding {...rest} />
  )
)`
  ${navButtonStyle}
`;

export const StyledNavLink = styled(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ noUnderline, ...rest }: NavLinkProps & StyledNavButtonProps) => <NavLink {...rest} />
)`
  ${navButtonStyle}
`;

export const activeNavLink = {
  color: Color.Red,
  borderBottom: `solid 1px ${Color.Red}`,
};

export const VerticalHr = styled.div`
  border-left: solid 1px ${Color.GreyDark};
  height: 24px;
  margin-right: 32px;
  margin-left: 32px;
`;
