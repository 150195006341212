import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import Providers from './Providers';
import withPopulateFeatureToggleContext from './hoc/withPopulateFeatureToggleContext';
import { withAktørportalenLoader } from './hoc/withLoader';
import { GlobalStyle } from './App.style';
import { GlobalErrorBoundary } from 'src/components/ErrorBoundary';
import HiddenLogoutForm from './components/HiddenLogoutForm';
import ScrollToTop from './components/ScrollToTop';
import InactiveLogoutModal from './modals/InactiveLogoutModal';
import ErrorModal from './modals/ErrorModal';
import Saker from './pages/Saker';
import Sak from './pages/Sak';
import MinProfil from './pages/MinProfil';
import NotFound from './pages/NotFound';
import Informasjonskapsler from './pages/Informasjonskapsler';
import Innloggingsfeil from './pages/Innloggingsfeil';
import Utlogget from './pages/Utlogget';
import PDF from './pages/PDF';
import Brukervilkår from './pages/Brukervilkår/Brukervilkår';
import Avregistrering from './pages/Avregistrering';
import AvslåBrukervilkår from './pages/Brukervilkår/AvslåBrukervilkår';
import Inhabil from './pages/Søknad/Inhabil';
import Fritak from './pages/Søknad/Fritak';
import './fonts.css';
import Avregistrert from './pages/Avregistrert';
import BekreftProfil from './pages/MinProfil/BekreftProfil';

const EnhancedSwitch = withPopulateFeatureToggleContext(withAktørportalenLoader(Switch));

export default function App() {
  return (
    <Providers>
      <HiddenLogoutForm />
      <GlobalStyle />
      <BrowserRouter>
        <LastLocationProvider>
          <GlobalErrorBoundary>
            <ScrollToTop />
            <InactiveLogoutModal />
            <ErrorModal />
            <EnhancedSwitch>
              <Route exact path="/utlogget" component={Utlogget} />
              <Route exact path="/avregistrert" component={Avregistrert} />
              <Route exact path="/informasjonskapsler" component={Informasjonskapsler} />
              <Route exact path="/innloggingsfeil" component={Innloggingsfeil} />
              <Route path={'/saker/:sakId/dokumenter/:dokumentId'} component={PDF} />
              <Route exact path="/brukervilkar" component={Brukervilkår} />
              <Route exact path="/avslabrukervilkar" component={AvslåBrukervilkår} />
              <Route exact path="/avregistrering" component={Avregistrering} />
              <Route exact path="/" component={Saker} />
              <Route exact path="/saker/:sakId/inhabil" component={Inhabil} />
              <Route exact path="/saker/:sakId/fritak" component={Fritak} />
              <Route path={'/saker/:sakId'} component={Sak} />
              <Route exact path="/min-profil" component={MinProfil} />
              <Route exact path="/bekreft-profil" component={BekreftProfil} />
              <Route component={NotFound} />
            </EnhancedSwitch>
          </GlobalErrorBoundary>
        </LastLocationProvider>
      </BrowserRouter>
    </Providers>
  );
}
