import React from 'react';
import styled from 'styled-components';
import { Color } from 'src/constants';
import InfoSharp from '@material-ui/icons/InfoSharp';
import { InfoType } from 'src/components/Typography';
import { Driftsmelding } from 'src/domain/driftsmelding';

const DriftsmeldingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${Color.GreyLight};
  margin-bottom: 48px;
  padding: 36px 48px;
`;

const StyledInfoSharp = styled(InfoSharp)`
  margin-right: 8px;
`;

type DriftsmeldingPanelProps = {
  driftsmelding: Driftsmelding;
};

const DriftsmeldingPanel = ({ driftsmelding }: DriftsmeldingPanelProps) => {
  return (
    <DriftsmeldingContainer role="driftsmelding">
      <StyledInfoSharp />
      <InfoType>
        {driftsmelding.melding}{' '}
        {driftsmelding.merInfoUrl && <a href={driftsmelding.merInfoUrl}>Les mer.</a>}
      </InfoType>
    </DriftsmeldingContainer>
  );
};

export default DriftsmeldingPanel;
