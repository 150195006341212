import { InfoType, LabelType } from 'src/components/Typography';
import { Color } from 'src/constants';
import { Aktør, PartMedRepresentanter } from 'src/domain/aktor';
import { Sak } from 'src/domain/sak';
import useTranslate from 'src/hooks/useTranslate';
import styled from 'styled-components';

const Table = styled.table`
  box-sizing: border-box;
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
  margin-top: 0;
  margin-bottom: 1.5rem;
`;

const TableHeaderCell = styled(LabelType).attrs({ as: 'th' })`
  text-align: left;
  text-transform: uppercase;
`;

const TableHeaderRow = styled.tr`
  height: 3rem;
  border-top: solid 2px ${Color.GreyDark};
  border-bottom: solid 1px ${Color.GreyDark};
`;

const TableRow = styled.tr`
  height: 3rem;
  border-bottom: solid 1px ${Color.GreyLight2};
`;

const TableCell = styled(InfoType).attrs({ as: 'td' })`
  padding: 0.25rem;
`;

const RepresentanterList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const RepresentanterListItem = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Rolle = styled.span`
  color: ${Color.GreyNormal};
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
`;

const NyAktørLabel = styled.div`
  color: ${Color.Red};
  display: inline-flex;
  align-items: center;
`;

const Dot = styled.div`
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 50%;
  background: ${Color.Red};
  margin-right: 0.5rem;
`;

type ParterMedRepresentanterTabellProps = {
  sak: Sak;
  parter: PartMedRepresentanter[];
};

const PartEllerAktørLabel = ({ ny, navn }: { ny: boolean; navn: string }) => {
  const translate = useTranslate();

  if (ny) {
    return (
      <NyAktørLabel>
        <Dot /> {navn} <i style={{ marginLeft: 8 }}>({translate('ny')})</i>
      </NyAktørLabel>
    );
  }

  return <>{navn}</>;
};

const erNyPartEllerAktør = (parterOgAktører: string[], id: string) =>
  parterOgAktører.length > 0 && !parterOgAktører.includes(id);

export const ParterMedRepresentanterTabell = ({
  sak,
  parter,
}: ParterMedRepresentanterTabellProps) => {
  const translate = useTranslate();

  const rows = parter.map(part => {
    const representanter = part.representanter.map(r => {
      const erNyAktør = erNyPartEllerAktør(
        sak.habilitet.partOgAktørIderVedHabilitetsvurdering,
        r.id
      );
      return (
        <RepresentanterListItem key={r.id}>
          <Rolle>{r.rolle}</Rolle>
          <PartEllerAktørLabel ny={erNyAktør} navn={r.navn} />
        </RepresentanterListItem>
      );
    });

    const erNyPart = erNyPartEllerAktør(
      sak.habilitet.partOgAktørIderVedHabilitetsvurdering,
      part.id
    );

    return (
      <TableRow key={part.id}>
        <TableCell style={{ verticalAlign: 'baseline', paddingTop: '1.5rem' }}>
          <PartEllerAktørLabel ny={erNyPart} navn={part.navn} />
        </TableCell>
        <TableCell>
          <RepresentanterList>{representanter}</RepresentanterList>
        </TableCell>
      </TableRow>
    );
  });

  return (
    <Table>
      <thead>
        <TableHeaderRow>
          <TableHeaderCell>{translate('aktørliste.navn')}</TableHeaderCell>
          <TableHeaderCell>{translate('aktørliste.representert-av')}</TableHeaderCell>
        </TableHeaderRow>
      </thead>
      <tbody>{rows}</tbody>
    </Table>
  );
};

type AktørerTabellProps = {
  sak: Sak;
  aktører: Aktør[];
};

export const AktørerTabell = ({ sak, aktører }: AktørerTabellProps) => {
  const translate = useTranslate();

  const rows = aktører.map(aktør => {
    const erNyAktør = erNyPartEllerAktør(
      sak.habilitet.partOgAktørIderVedHabilitetsvurdering,
      aktør.id
    );
    return (
      <TableRow key={aktør.id}>
        <TableCell>{aktør.rolle}</TableCell>
        <TableCell>
          <PartEllerAktørLabel ny={erNyAktør} navn={aktør.navn} />
        </TableCell>
      </TableRow>
    );
  });

  return (
    <Table>
      <thead>
        <TableHeaderRow>
          <TableHeaderCell>{translate('aktørliste.rolle')}</TableHeaderCell>
          <TableHeaderCell>{translate('aktørliste.navn')}</TableHeaderCell>
        </TableHeaderRow>
      </thead>
      <tbody>{rows}</tbody>
    </Table>
  );
};
