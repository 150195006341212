import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { LabelType, InfoType } from 'src/components/Typography';
import { Color } from 'src/constants';
import CalendarTodaySharp from '@material-ui/icons/CalendarTodaySharp';
import usePost from 'src/hooks/usePost';
import dateFormat from 'date-fns/format';
import dateCompareAsc from 'date-fns/compareAsc';
import useTranslate from 'src/hooks/useTranslate';
import withRouteSakId from 'src/hoc/withRouteSakId';
import { withFetchMøterOgFrister } from 'src/hoc/withFetch';
import withLoader from 'src/hoc/withLoader';
import { InfoMessage } from 'src/components/Messages';
import { Møte } from 'src/domain/møte';
import { Frist } from 'src/domain/frist';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 500px) {
    & > div:first-child {
      border-top: solid 2px ${Color.GreyDark};
    }
  }
`;

const Header = styled.div`
  display: flex;
  padding: 16px;
  border-top: solid 2px ${Color.GreyDark};
  border-bottom: solid 1px ${Color.GreyDark};

  label {
    flex: 1;
  }

  label:last-child {
    text-align: center;
  }

  @media (max-width: 500px) {
    display: none;
  }
`;

const ListElement = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
  border-bottom: 1px solid ${Color.GreyLight2};

  span {
    flex: 1;
  }

  & > span:last-child {
    display: flex;
    justify-content: center;
  }

  @media (max-width: 500px) {
    & > div:first-child {
      margin-right: 24px;
    }

    & > span:last-child {
      display: flex;
      flex-direction: column;
      align-items: center;
      align-self: flex-start;
    }
  }
`;

const StyledCalendarButton = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
`;

const StyledCalIcon = styled(CalendarTodaySharp)`
  color: ${Color.GreyDark};
  cursor: pointer;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  span {
    padding-left: 12px;
    margin-bottom: 12px;
    margin-top: 8px;
  }
`;

type Hendelse = 'møte' | 'frist';

type HendelseType = {
  type: Hendelse;
};

type MøteWithtype = Møte & HendelseType;
type FristWithtype = Frist & HendelseType;

type MøterOgFristerProps = {
  møter: Møte[];
  frister: Frist[];
};

const MøterOgFrister = ({ møter, frister }: MøterOgFristerProps) => {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [LastNedMøteRequestState, LastNedMøteRequest] = usePost<string>('/saker/lastnedmoete');
  const [LastNedFristRequestState, LastNedFristRequest] = usePost<string>('/saker/lastnedfrist');
  const translate = useTranslate();

  useEffect(() => {
    const setInnerWidthFunc = () => {
      setInnerWidth(window.innerWidth);
    };

    window.addEventListener('resize', setInnerWidthFunc);

    return () => {
      window.removeEventListener('resize', setInnerWidthFunc);
    };
  }, []);

  useEffect(() => {
    if (LastNedMøteRequestState.isSuccess) {
      eksporterHendelse(LastNedMøteRequestState.data);
    }
  }, [LastNedMøteRequestState.isSuccess]);

  useEffect(() => {
    if (LastNedFristRequestState.isSuccess) {
      eksporterHendelse(LastNedFristRequestState.data);
    }
  }, [LastNedFristRequestState.isSuccess]);

  function eksporterHendelse(data: string) {
    const uri = `data:text/calendar;charset=utf-8,${escape(data)}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = uri;
    downloadLink.download = 'hendelse.ics';

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  const møterWithType: MøteWithtype[] = møter.map(møte => ({ type: 'møte', ...møte }));
  const fristerWithType: FristWithtype[] = frister.map(frist => ({ type: 'frist', ...frist }));

  const møterOgFrister = [...møterWithType, ...fristerWithType]
    .sort((a, b) => dateCompareAsc(new Date(a.dato), new Date(b.dato)))
    .map(x => {
      const datoTekst = x.dato ? dateFormat(new Date(x.dato), 'dd.MM.yyyy') : translate('mangler');
      return { datoTekst, ...x };
    });

  if (møterOgFrister.length === 0) {
    return <InfoMessage title={translate('frister.ingen-møter-og-frister')} />;
  }

  const CalendarButton = ({ entry }: { entry: MøteWithtype | FristWithtype }) => {
    if (!entry.dato || !entry.beskrivelse) {
      return null;
    }

    const lastNed = entry.type === 'frist' ? LastNedFristRequest : LastNedMøteRequest;

    return (
      <StyledCalendarButton onClick={() => lastNed(entry)}>
        <StyledCalIcon />
      </StyledCalendarButton>
    );
  };

  return (
    <Container>
      {innerWidth > 500 ? (
        <>
          <Header>
            <LabelType>{translate('frister.dato').toUpperCase()}</LabelType>
            <LabelType>{translate('frister.klokkeslett').toUpperCase()}</LabelType>
            <LabelType>{translate('frister.beskrivelse').toUpperCase()}</LabelType>
            <LabelType>{translate('frister.kalender').toUpperCase()}</LabelType>
          </Header>
          {møterOgFrister.map((entry, index) => (
            <ListElement key={index}>
              <InfoType>{entry.datoTekst}</InfoType>
              <InfoType>{entry.klokkeslettTekst}</InfoType>
              {entry.type === 'frist' ? (
                <span>
                  <InfoType>
                    <a color={Color.Blue} href={`/saker/${entry.sakId}/mine-oppgaver`}>
                      {entry.beskrivelse}
                    </a>
                  </InfoType>
                </span>
              ) : (
                <InfoType>{entry.beskrivelse}</InfoType>
              )}

              <span>
                <CalendarButton entry={entry} />
              </span>
            </ListElement>
          ))}
        </>
      ) : (
        møterOgFrister.map((entry, index) => (
          <ListElement key={index}>
            <InfoContainer>
              <LabelType>{translate('frister.dato').toUpperCase()}</LabelType>
              <InfoType>{entry.datoTekst}</InfoType>
              {entry.klokkeslettTekst && (
                <>
                  <LabelType>{translate('frister.klokkeslett').toUpperCase()}</LabelType>
                  <InfoType>{entry.klokkeslettTekst}</InfoType>
                </>
              )}
              <LabelType>{translate('frister.beskrivelse').toUpperCase()}</LabelType>
              {entry.type === 'frist' ? (
                <span>
                  <InfoType>
                    <a href={`/saker/${entry.sakId}/mine-oppgaver`}>{entry.beskrivelse}</a>
                  </InfoType>
                </span>
              ) : (
                <InfoType>{entry.beskrivelse}</InfoType>
              )}
            </InfoContainer>
            <span>
              <LabelType>{translate('frister.kalender').toUpperCase()}</LabelType>
              <CalendarButton entry={entry} />
            </span>
          </ListElement>
        ))
      )}
    </Container>
  );
};

export default withRouteSakId(
  withFetchMøterOgFrister(
    withLoader(
      'frister.hent-møter-og-frister',
      'frister.hent-møter-og-frister-feilet'
    )(MøterOgFrister)
  )
);
