import React from 'react';
import { useSelector } from 'react-redux';
import { Språk } from 'src/constants';
import useTranslate from 'src/hooks/useTranslate';
import { selectBruker } from 'src/store/selectors/brukerSelectors';
import LogoBokmål from './logo-bokmål.svg';
import LogoNynorsk from './logo-nynorsk.svg';
import LogoSamisk from './logo-samisk.svg';

function Logo() {
  const translate = useTranslate();
  const bruker = useSelector(selectBruker);
  const språk = (bruker && bruker.valgtSpråk) || Språk.Bokmål;

  return <img src={langToSrc(språk)} alt={translate("footer.norgesDomstoler")} />;
}

function langToSrc(lang: Språk) {
  switch (lang) {
    case Språk.Bokmål:
      return LogoBokmål;
    case Språk.Nynorsk:
      return LogoNynorsk;
    case Språk.Samisk:
      return LogoSamisk;
    default:
      return LogoBokmål;
  }
}

export default Logo;
