import React from 'react';

import useTranslate from 'src/hooks/useTranslate';
import Brand from 'src/components/Brand';
import { NavContainer, StyledNavLink, activeNavLink, VerticalHr } from './Menu.style';
import LogoutNavButton from './LogoutNavButton';

type MenuProps = {
  fullName: string;
  showLogout: boolean;
};

export default function Menu({ fullName, showLogout }: MenuProps) {
  const translate = useTranslate();

  const showProfile = !!fullName;

  const MinProfilNavLink = () => (
    <StyledNavLink
      to={'/min-profil'}
      exact
      activeStyle={activeNavLink}
      aria-label={translate('profil.tittel')}
    >
      {fullName}
    </StyledNavLink>
  );

  return (
    <NavContainer>
      <Brand />
      {showProfile && <MinProfilNavLink />}
      {showProfile && showLogout && <VerticalHr />}
      {showLogout && <LogoutNavButton />}
    </NavContainer>
  );
}
