import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import Saksliste from 'src/components/Saksliste';
import SearchField from 'src/components/SearchField';
import IngenSaker from 'src/components/IngenSaker';
import { InfoMessage } from 'src/components/Messages';
import { H1 } from 'src/components/Typography';
import { SakerContainer } from './Saker.style';
import useTranslate from 'src/hooks/useTranslate';
import { withAkseptertBrukervilkårPage } from 'src/hoc/withPage';
import withLoader from 'src/hoc/withLoader';
import { Sak } from 'src/domain/sak';
import { withSaker } from 'src/hoc/withSaker';
import { useLastLocation } from 'react-router-last-location';
import { InfoOutlined } from '@material-ui/icons';
import { InfoAlert } from 'src/components/Alert/AlertBox';
import { Color } from 'src/constants';

type SakerProps = {
  saker: Sak[];
};

const Saker = ({ saker }: SakerProps) => {
  const lastLocation = useLastLocation();
  const godtgjoerelseLink = 'https://www.domstol.no/no/utbetalingogfaktura/godtgjorelse/';

  const [searchValue, setSearchValue] = useState('');
  const translate = useTranslate();

  const sakerFiltrert = saker.filter(x => {
    const searchValueLowerCase = searchValue.toLowerCase();
    const searchFields = [x.sakstema, x.saksnummer, x.sakId];
    return searchFields.map(x => x.toLowerCase()).some(x => x.includes(searchValueLowerCase));
  });

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  if (saker.length === 0) {
    return <IngenSaker />;
  }

  if (saker.length === 1 && lastLocation === null) {
    // Kun redirect til saken ved første visning
    return <Redirect to={`/saker/${saker[0].sakId}/mine-oppgaver`} />;
  }

  return (
    <SakerContainer>
      <H1 noMargin>{translate('saksoversikt.tittel')}</H1>
      <SearchField
        value={searchValue}
        onChange={handleSearchInputChange}
        type="text"
        placeholder={translate('saksoversikt.søk-i-saker')}
        maxWidth="504px"
      />
      {sakerFiltrert.length === 0 ? (
        <InfoMessage title={translate('saksoversikt.ingen-saker')}></InfoMessage>
      ) : (
        <>
          <Saksliste saker={sakerFiltrert} />
          <InfoAlert
            Icon={InfoOutlined}
            iconText={
              <>
                {translate('saksoversikt.godtgjoerelse-info1')}
                <a
                  href={godtgjoerelseLink}
                  target="_blank"
                  rel="noreferrer noopener"
                  style={{ color: Color.dsGreyDark }}
                >
                  {translate('saksoversikt.godtgjoerelse-info2')}
                </a>
              </>
            }
          />
        </>
      )}
    </SakerContainer>
  );
};

export default withAkseptertBrukervilkårPage(
  withSaker(withLoader('saksoversikt.hent-saker', 'saksoversikt.hent-saker-feilet')(Saker))
);
