import styled, { css } from 'styled-components';

const flexColumn = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const PageContainer = styled.div`
  ${flexColumn};
  flex: auto;
  align-items: center;
  box-sizing: border-box;
  max-width: 1024px;
  padding-top: 40px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 120px;

  header {
    padding-bottom: 80px;
  }
`;

export const ContentContainer = styled.main`
  ${flexColumn};
  flex: auto;
`;
