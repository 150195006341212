import { css, ThemedCssFunction } from 'styled-components';

// Samme breakpoints som Bootstrap 4
// https://bootstrapcreative.com/bootstrap-4-media-queries/

const xs = <T extends Record<string, unknown>>(...args: Parameters<ThemedCssFunction<T>>) =>
  css`
    @media (max-width: 576px) {
      ${args}
    }
  `;

const s = <T extends Record<string, unknown>>(...args: Parameters<ThemedCssFunction<T>>) =>
  css`
    @media (max-width: 768px) {
      ${args}
    }
  `;

const m = <T extends Record<string, unknown>>(...args: Parameters<ThemedCssFunction<T>>) =>
  css`
    @media (max-width: 992px) {
      ${args}
    }
  `;

const l = <T extends Record<string, unknown>>(...args: Parameters<ThemedCssFunction<T>>) =>
  css`
    @media (max-width: 1200px) {
      ${args}
    }
  `;

const xl = <T extends Record<string, unknown>>(...args: Parameters<ThemedCssFunction<T>>) => {
  return css`
    @media (min-width: 1200px) {
      ${args}
    }
  `;
};

export default {
  xs,
  s,
  m,
  l,
  xl,
};
