import { configureStore } from '@reduxjs/toolkit';
import { reducer as requestStatesStoreReducer } from './requestStatesStore';
import { sakerReducer } from './sakerStore';
import { oppgaverReducer } from './oppgaverStore';
import { brukerReducer } from './brukerStore';
import { errorModalReducer } from './errorModalStore';
import { useDispatch } from 'react-redux';

const store = configureStore({
  reducer: {
    requestStatesStore: requestStatesStoreReducer,
    sakerStore: sakerReducer,
    oppgaverStore: oppgaverReducer,
    brukerStore: brukerReducer,
    errorModalStore: errorModalReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
