import React from 'react';
import { Provider } from 'react-redux';
import store from './store';
import { I18nProvider } from './contexts/I18n';
import { LogoutCallbackProvider } from './contexts/LogoutCallback';
import { FeatureToggleProvider } from './contexts/FeatureToggle';

export default function Providers({ children }: { children: React.ReactNode }) {
  return (
    <Provider store={store}>
      <I18nProvider>
        <LogoutCallbackProvider>
          <FeatureToggleProvider>{children}</FeatureToggleProvider>
        </LogoutCallbackProvider>
      </I18nProvider>
    </Provider>
  );
}
