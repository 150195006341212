import { BASE_URL } from 'src/constants';
import axios from 'axios';
import { handleAxiosErrors, handleAxiosResponse } from './responseHandlers';

export enum ApiStatus {
  Success = 'success',
  Error = 'error',
}

export type ApiSuccessResponse<T> = {
  status: ApiStatus.Success;
  statusCode: number;
  data: T;
};

export type ApiErrorResponse = {
  status: ApiStatus.Error;
  statusCode: number;
  error: string;
  text: string | undefined;
};

type ApiResponse<T = unknown> = ApiSuccessResponse<T> | ApiErrorResponse;

export function isSuccess<T>(response: ApiResponse<T>): response is ApiSuccessResponse<T> {
  return response.status === ApiStatus.Success;
}

export function isError(response: ApiResponse): response is ApiErrorResponse {
  return response.status === ApiStatus.Error;
}

let antiXsrfTokenPromise: Promise<string>;

const getAntiXsrfToken = (): Promise<string> => {
  if (!antiXsrfTokenPromise) {
    antiXsrfTokenPromise = axios
      .get(`${BASE_URL}/antiXsrfToken`, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          Accept: 'text/plain',
        },
      })
      .then(x => x.data);
  }

  return antiXsrfTokenPromise;
};

export async function fetch<T>(url: string): Promise<ApiResponse<T>> {
  return getAntiXsrfToken().then(antiXsrfToken =>
    axios
      .get(`${BASE_URL}${url}`, {
        headers: {
          'Anti-XSRF-Token': antiXsrfToken,
          'X-Requested-With': 'XMLHttpRequest',
          Accept: 'json',
        },
      })
      .then(res => handleAxiosResponse<T>(res))
      .catch(handleAxiosErrors)
  );
}

export async function post<T = unknown>(
  url: string,
  data?: Record<string, unknown> | string
): Promise<ApiResponse<T>> {
  return getAntiXsrfToken().then(antiXsrfToken =>
    axios
      .post(`${BASE_URL}${url}`, data, {
        headers: {
          'Anti-XSRF-Token': antiXsrfToken,
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
          Accept: 'json',
        },
      })
      .then(res => handleAxiosResponse<T>(res))
      .catch(handleAxiosErrors)
  );
}

export async function multipartPost(
  url: string,
  data: Record<string, unknown>,
  files: File[]
): Promise<ApiResponse> {
  const fd = new FormData();
  files.forEach(file => fd.append('files', file, file.name));
  fd.append('data', JSON.stringify(data));

  return getAntiXsrfToken().then(antiXsrfToken =>
    axios
      .post(`${BASE_URL}${url}`, fd, {
        headers: {
          'Anti-XSRF-Token': antiXsrfToken,
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'multipart/form-data',
          Accept: 'json',
        },
      })
      .then(res => handleAxiosResponse(res))
      .catch(handleAxiosErrors)
  );
}
