import styled from 'styled-components';
import { Color } from 'src/constants';

export const BrukervilkårContainer = styled.div`
  max-width: 536px;
  width: 100%;

  h1,
  h2,
  h3,
  h4 {
    color: ${Color.GreyDark};
  }

  h1 {
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 2.75rem;
    letter-spacing: -0.02px;
  }

  h2 {
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.25rem;
    letter-spacing: -0.02px;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.875rem;
  }

  h4 {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;
  }

  p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25rem;
  }
`;
