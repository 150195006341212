import styled from 'styled-components';
import { Color } from 'src/constants';

type VerticalHrProps = {
  height?: number;
};

export const VerticalHr = styled.div<VerticalHrProps>`
  border-left: solid 1px ${Color.GreyDark};
  height: ${props => (props.height ? `${props.height}px` : '40px;')};
  margin-right: 32px;
  margin-left: 32px;

  @media (max-width: 424px) {
    display: none;
  }
`;
