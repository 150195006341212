export const required =
  (errorMessage: string) =>
  (value: string): Nullable<string> =>
    !!value ? undefined : errorMessage;

export const minLength =
  (errorMessage: string, minLength: number) =>
  (value: string): Nullable<string> =>
    (value?.length ?? 0) >= minLength ? undefined : errorMessage;

export const maxLength =
  (errorMessage: string, maxLength: number) =>
  (value: string): Nullable<string> =>
    (value?.length ?? 0) <= maxLength ? undefined : errorMessage;

export const composeValidators = (...validators: ((value: string) => Nullable<string>)[]) => {
  return (value: string): Nullable<string> =>
    validators.reduce(
      (error: string | undefined, validator) => error || validator(value),
      undefined
    );
};
