import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html {
    line-height: 1;
    font-size: 16px;
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  html,
  button,
  input[type="text"] {
    font-family: 'Proxima Nova', Arial;
  }

  body {
    margin: 0;
  }
  
  #root {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
  }

  .focus-visible {
      outline: none;
      box-shadow: 0 0 0 2px rgba(210, 35, 30, 0.5);
      transition: all 100ms;
  }
`;
