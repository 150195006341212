import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { Color } from 'src/constants';

const ActionBarContainer = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: ${Color.White};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
  border-top: solid 1px ${Color.GreyDark};
`;

const ActionBarContentContainer = styled.span`
  display: flex;
  justify-content: flex-end;
  max-width: 1024px;
  width: 100%;
  padding: 0px 16px;

  & > button:first-child {
    margin-right: 40px;
  }

  @media (max-width: 624px) {
    flex-direction: column-reverse;
    align-items: flex-end;

    & > button:first-child {
      margin-top: 0px;
      margin-right: 0px;
    }
  }
`;

const ActionBar = ({ children }: { children: ReactNode }) => {
  return (
    <ActionBarContainer>
      <ActionBarContentContainer>{children}</ActionBarContentContainer>
    </ActionBarContainer>
  );
};

export default ActionBar;
