import React, { useContext, useEffect } from 'react';
import useFetch from 'src/hooks/useFetch';
import { FeatureToggleContext } from 'src/contexts/FeatureToggle';
import { FeatureFlag } from 'src/domain/featureFlag';
import { WithLoaderProps } from './withLoader';

const withPopulateFeatureToggleContext =
  <P extends Record<string, unknown>>(
    Component: React.ComponentType<P & WithLoaderProps>
  ): React.ComponentType<P & WithLoaderProps> =>
  (props: P & WithLoaderProps) => {
    const requestState = useFetch<FeatureFlag[]>('/featuretoggles');
    const { enabledFeatures, setEnabledFeatures } = useContext(FeatureToggleContext);

    useEffect(() => {
      if (!requestState.isError) {
        setEnabledFeatures(requestState.data);
      }
    }, [requestState]);

    const isLoading = props.isLoading || (!requestState.isError && enabledFeatures === null);
    const hasError = props.hasError || requestState.isError;

    return <Component {...props} isLoading={isLoading} hasError={hasError} />;
  };

export default withPopulateFeatureToggleContext;
