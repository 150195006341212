import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { H3 } from 'src/components/Typography';
import Page from 'src/components/Page';
import { PrimaryButton } from 'src/components/Buttons';
import useTranslate from 'src/hooks/useTranslate';

const UtloggetContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default function Utlogget() {
  const history = useHistory();
  const translate = useTranslate();

  return (
    <Page hideLogout>
      <UtloggetContainer>
        <H3>{translate('utlogget-av-aktørportalen')}</H3>
        <PrimaryButton onClick={() => history.push(`/`)}>{translate('logg-inn')}</PrimaryButton>
      </UtloggetContainer>
    </Page>
  );
}
