import React, { ReactNode } from 'react';
import styled from 'styled-components';
import ErrorOutlineSharp from '@material-ui/icons/ErrorOutlineSharp';
import Spinner from 'src/components/Spinner';
import { H3 } from 'src/components/Typography';

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  > div:first-child {
    flex-grow: 1;
  }

  > div:last-child {
    flex-grow: 4;
  }
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 16px;
  }
`;

function MessageContainer({ children }: { children: ReactNode }) {
  // Bruker divs med forskjellig flex-grow til å plassere meldingen litt høyere opp på siden
  return (
    <ColumnContainer>
      <div />
      {children}
      <div />
    </ColumnContainer>
  );
}

type MessageProps = {
  title: string;
  children?: ReactNode;
};

export function InfoMessage({ title, children }: MessageProps) {
  return (
    <MessageContainer>
      <H3>{title}</H3>
      {children}
    </MessageContainer>
  );
}

export function LoadingMessage({ title }: MessageProps) {
  return (
    <MessageContainer>
      <Spinner />
      <H3>{title}</H3>
    </MessageContainer>
  );
}

export function ErrorMessage({ title, children }: MessageProps) {
  return (
    <MessageContainer>
      <RowContainer>
        <ErrorOutlineSharp />
        <H3>{title}</H3>
        {children}
      </RowContainer>
    </MessageContainer>
  );
}
