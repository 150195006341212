import sha256 from 'crypto-js/sha256';
import base64encoder from 'crypto-js/enc-base64';
import WordArray from 'crypto-js/lib-typedarrays';

export default async function createBase64EncodedSha256Hash(blob: Blob) {
  const arrayBuffer = await new Response(blob).arrayBuffer();
  // WordArray.create works with an ArrayBuffer, but the type definition doesn't allow it
  const wordArray = WordArray.create(arrayBuffer as unknown as number[]);
  const hash = sha256(wordArray);
  const base64encodedHash = hash.toString(base64encoder);
  return base64encodedHash;
}
