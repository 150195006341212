import React, { createContext, ReactNode, useState } from 'react';

export type LogoutCallbackContext = {
  logoutCallback: () => void;
  setLogoutCallback: (callback: () => void) => void;
  redirectToAvregistreringPage: boolean;
  setRedirectToAvregistreringPage: (bool: boolean) => void;
};

export const LogoutCallbackContext = createContext<LogoutCallbackContext>(null);

export const LogoutCallbackProvider = ({ children }: { children: ReactNode }) => {
  // For å lagre en funksjon i useState så må man sende inn en funksjon som returnerer funksjonen
  const [logoutCallback, setLogoutCallback] = useState(() => () => {}); // noop
  const [redirectToAvregistreringPage, setRedirectToAvregistreringPage] = useState(false);

  // Wrapper funksjonen i en lambda slik at den som setter callbacken slipper
  const wrapInLambdaAndSetLogoutCallback = (callback: () => void) =>
    setLogoutCallback(() => callback);

  return (
    <LogoutCallbackContext.Provider
      value={{
        logoutCallback,
        setLogoutCallback: wrapInLambdaAndSetLogoutCallback,
        redirectToAvregistreringPage,
        setRedirectToAvregistreringPage,
      }}
    >
      {children}
    </LogoutCallbackContext.Provider>
  );
};
