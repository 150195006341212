import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Color } from 'src/constants';
import { InfoType, BodyType } from 'src/components/Typography';

const margin = 2;
const buttonMarginBottom = 0.75;
const collapsedMargin = margin - buttonMarginBottom;

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  > * {
    margin-bottom: ${buttonMarginBottom}rem;
  }

  > *:not(:last-child) {
    margin-right: 1rem;
  }
`;

export const Hr = styled.div`
  width: 100%;
  border-top: solid 1px ${Color.GreyNormal};
`;

export const Ul = styled.ul`
  color: ${Color.GreyNormal};
  margin: 1rem 0;
  margin-top: auto;
  padding-left: 1.5rem;
  padding-right: 1rem;
`;

export const Li = styled(InfoType).attrs({ as: 'li' })`
  margin: 1rem 0;
  padding: 0;
`;

export const P = styled(BodyType).attrs({ as: 'p' })``;

export const MarginContainer = styled.div`
  > * {
    margin: ${margin}rem 0;
  }

  > ${Hr}:first-child {
    margin-top: 0;
  }

  > ${FlexRow} {
    margin-bottom: ${collapsedMargin}rem;
  }

  > ${FlexRow} + * {
    margin-top: ${collapsedMargin}rem;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${Color.Blue};
`;
