import React from 'react';
import styled from 'styled-components';
import { LabelType, BodyType } from 'src/components/Typography';
import { VerticalHr } from 'src/components/Utils';
import { Color } from 'src/constants';
import AttachFileSharp from '@material-ui/icons/AttachFileSharp';
import DescriptionSharp from '@material-ui/icons/DescriptionSharp';
import useTranslate from 'src/hooks/useTranslate';
import { StealthButton } from 'src/components/Buttons';
import { DokumentType } from 'src/domain/enums';
import { Dokument } from 'src/domain/dokument';

const Container = styled.div`
  display: flex;
`;

const DokumentTypeLabelCountWrapper = styled(StealthButton)<{ selected: boolean }>`
  background-color: transparent;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  && * {
    color: ${Color.GreyNormal};
    cursor: pointer;
  }

  ${props =>
    props.selected &&
    `
    && {
      * {
        color: ${Color.Red};
      }
      label {
        color: ${Color.GreyDark}
      }
    }
  `}
`;

const CenterHorizontal = styled.div`
  display: flex;
  align-items: center;
`;

const DokumentTypeLabel = styled(LabelType)`
  margin-top: 0.5rem;
`;

function antallDokumenter(dokumenter: Dokument[]) {
  return dokumenter.filter(d => d.type === DokumentType.Hoveddokument).length;
}

function antallBilag(dokumenter: Dokument[]) {
  return dokumenter.filter(d => d.type === DokumentType.Bilag).length;
}

export type DokumentTyper = {
  [DokumentType.Hoveddokument]: boolean;
  [DokumentType.Bilag]: boolean;
};

type DokumentTypeFilterProps = {
  dokumenter: Dokument[];
  valgteDokumentTyper: DokumentTyper;
  oppdaterValgteDokumentTyper: (value: DokumentTyper) => void;
};

export default ({
  valgteDokumentTyper,
  oppdaterValgteDokumentTyper,
  dokumenter,
}: DokumentTypeFilterProps) => {
  const translate = useTranslate();

  const onValgtDokumentType = (type: DokumentType) => {
    oppdaterValgteDokumentTyper({
      ...valgteDokumentTyper,
      [type]: !valgteDokumentTyper[type],
    });
  };

  return (
    <Container>
      <DokumentTypeLabelCountWrapper
        selected={valgteDokumentTyper[DokumentType.Hoveddokument]}
        onClick={() => onValgtDokumentType(DokumentType.Hoveddokument)}
        aria-label={translate('saksdokumenter.filtrer-dokumenter')}
      >
        <CenterHorizontal>
          <DescriptionSharp />
          <BodyType noMargin>{antallDokumenter(dokumenter)}</BodyType>
        </CenterHorizontal>
        <DokumentTypeLabel>{translate('saksdokumenter.dokumenter')}</DokumentTypeLabel>
      </DokumentTypeLabelCountWrapper>
      <VerticalHr />
      <DokumentTypeLabelCountWrapper
        selected={valgteDokumentTyper[DokumentType.Bilag]}
        onClick={() => onValgtDokumentType(DokumentType.Bilag)}
        aria-label={translate('saksdokumenter.filtrer-bilag')}
      >
        <CenterHorizontal>
          <AttachFileSharp />
          <BodyType noMargin>{antallBilag(dokumenter)}</BodyType>
        </CenterHorizontal>
        <DokumentTypeLabel>{translate('saksdokumenter.bilag')}</DokumentTypeLabel>
      </DokumentTypeLabelCountWrapper>
    </Container>
  );
};
