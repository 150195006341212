import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  selectMåAkseptereBrukervilkår,
  selectMåBekrefteKontaktinformasjon,
} from 'src/store/selectors/brukerSelectors';

const withRedirect =
  (predicate: () => boolean, url: string) =>
  <P extends Record<string, unknown>>(Component: React.ComponentType<P>): React.ComponentType<P> =>
  props => {
    return predicate() ? <Redirect to={url} /> : <Component {...props} />;
  };

export default withRedirect;

export const withRedirectToBrukervilkår = withRedirect(
  () => useSelector(selectMåAkseptereBrukervilkår),
  '/brukervilkar'
);

export const withRedirectToProfil = withRedirect(
  () => useSelector(selectMåBekrefteKontaktinformasjon),
  '/bekreft-profil'
);
