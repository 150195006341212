import React from 'react';
import styled from 'styled-components';
import { Color } from 'src/constants';

type StyledSpinnerProps = SpinnerProps & {
  outerAnimationDelay: number;
  innerAnimationDelay: number;
};

const StyledSpinner = styled.svg<StyledSpinnerProps>`
  display: block;
  animation: rotate 2s linear infinite;
  animation-delay: ${props => props.outerAnimationDelay}ms;
  width: 30px;
  height: 30px;

  & .path {
    stroke: ${props => (props.color ? props.color : Color.GreyDark)};
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
    animation-delay: ${props => props.innerAnimationDelay}ms;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

type SpinnerProps = {
  color?: Color;
};

export default function Spinner({ color }: SpinnerProps) {
  const mountTime = React.useRef(Date.now());
  const outerAnimationDelay = -(mountTime.current % 2000);
  const innerAnimationDelay = -(mountTime.current % 1500);

  return (
    <StyledSpinner
      viewBox="0 0 50 50"
      color={color}
      outerAnimationDelay={outerAnimationDelay}
      innerAnimationDelay={innerAnimationDelay}
      role="Spinner"
    >
      <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="4" />
    </StyledSpinner>
  );
}
