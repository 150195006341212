import React from 'react';
import { Field } from 'react-final-form';
import useTranslate from 'src/hooks/useTranslate';
import Input from './Input';

type FinalFormPhoneProps = {
  name: string;
  label: string;
  [x: string]: unknown;
};

export default function FinalFormPhone({ name, label, ...rest }: FinalFormPhoneProps) {
  const translate = useTranslate();

  const validate = (number: string) =>
    !number || number.length !== 8 || !/^\d+$/.test(number)
      ? translate('profil.validering-telefon')
      : undefined;

  const formatPhoneNmb = (number: string) =>
    !number ? undefined : splitIntoNorwegianPhoneFormat(number);

  return (
    <Field
      name={name}
      validate={validate}
      format={formatPhoneNmb}
      render={({ input, meta }) => (
        <Input
          label={label}
          error={(meta.touched && meta.error) || ''}
          type="tel"
          maxLength={8}
          {...input}
          {...rest}
        />
      )}
    />
  );
}

function splitIntoNorwegianPhoneFormat(number: string) {
  const n = number.replace(' ', '').replace(' ', '');

  if (!n || n.length !== 8 || !/^\d+$/.test(n)) {
    return n;
  }
  return n.substring(0, 3) + ' ' + n.substring(3, 5) + ' ' + n.substring(5, 8);
}
