import React, { ErrorInfo } from 'react';
import { History, UnregisterCallback } from 'history';
import { post } from 'src/ajax';
import { withPage } from 'src/hoc/withPage';
import withHistory from 'src/hoc/withHistory';
import useTranslate from 'src/hooks/useTranslate';
import { ErrorMessage } from 'src/components/Messages';

type ErrorBoundaryProps = {
  history: History;
  children: React.ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
  unregisterCallback: UnregisterCallback | null;
};

const withErrorBoundary = (ErrorComponent: React.ComponentType) =>
  class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
      super(props);
      this.state = { hasError: false, unregisterCallback: null };
    }

    static getDerivedStateFromError() {
      // Denne blir kalt ETTER en exception har blitt kastet. Oppdaterer state til hasError = true
      return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
      post('/frontenderrorlog', {
        message: error.message || error,
        stack: error.stack,
        componentStack: errorInfo.componentStack,
      }).catch(console.warn);
    }

    componentDidMount() {
      const unregisterCallback = this.props.history.listen(() => {
        if (this.state.hasError) {
          this.setState({ hasError: false });
        }
      });

      this.setState({ unregisterCallback });
    }

    componentWillUnmount() {
      this.state.unregisterCallback();
    }

    render() {
      return this.state.hasError ? <ErrorComponent /> : this.props.children;
    }
  };

function ErrorBoundaryMessage() {
  const translate = useTranslate();
  return <ErrorMessage title={translate('en-feil-oppstod')} />;
}

export const ErrorBoundary = withHistory(withErrorBoundary(ErrorBoundaryMessage));

export const GlobalErrorBoundary = withHistory(withErrorBoundary(withPage(ErrorBoundaryMessage)));
