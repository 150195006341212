import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ErrorModalState = {
  open: boolean;
  title: string;
  message: string;
  link?: string;
};

const initialState: ErrorModalState = {
  open: false,
  title: '',
  message: '',
};

export const { actions: errorModalActions, reducer: errorModalReducer } = createSlice({
  name: 'errorModalStore',
  initialState,
  reducers: {
    show: (state, action: PayloadAction<{ message: string; title: string, link?: string }>) => {
      const { title, message, link } = action.payload;
      state.open = true;
      state.title = title;
      state.message = message;
      state.link = link
    },
    hide: state => {
      state.open = false;
    },
  },
});
