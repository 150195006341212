import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type RequestState<T = unknown> = {
  isPending: boolean;
  isNotAsked: boolean;
  isError: boolean;
  isSuccess: boolean;
  data: T;
  error: string;
};

export type RequestsState = {
  [key: string]: RequestState;
};

export type Request<T = unknown> = {
  uri: string;
  data?: T;
  error?: string;
};

export const initialState: RequestState = {
  isPending: false,
  isNotAsked: true,
  isError: false,
  isSuccess: false,
  data: null,
  error: null,
};

export const { actions, reducer } = createSlice({
  name: 'requestStatesStore',
  initialState: {} as RequestsState,
  reducers: {
    fetch: (state, action: PayloadAction<Request>) => {
      state[action.payload.uri] = {
        isPending: true,
        isNotAsked: false,
        isError: false,
        isSuccess: false,
        data: null,
        error: null,
      };
    },
    error: (state, action: PayloadAction<Request>) => {
      state[action.payload.uri] = {
        isPending: false,
        isNotAsked: false,
        isError: true,
        isSuccess: false,
        data: null,
        error: action.payload.error,
      };
    },
    success: (state, action: PayloadAction<Request>) => {
      state[action.payload.uri] = {
        isPending: false,
        isNotAsked: false,
        isError: false,
        isSuccess: true,
        data: action.payload.data,
        error: null,
      };
    },
  },
});
