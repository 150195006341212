import { useSelector } from 'react-redux';
import { isSuccess, post } from 'src/ajax';
import { useAppDispatch } from 'src/store';
import { actions, initialState, RequestState } from 'src/store/requestStatesStore';
import { selectRequestStates } from 'src/store/selectors/requestStatesSelectors';

export default <T = unknown>(uri: string): [RequestState<T>, (data?: unknown) => Promise<T>] => {
  const dispatch = useAppDispatch();
  const requestStates = useSelector(selectRequestStates);
  const key = `post-${uri}`;

  function postData(data?: Record<string, unknown> | string) {
    dispatch(actions.fetch({ uri: key }));

    return (async () => {
      const response = await post<T>(uri, data);

      if (isSuccess(response)) {
        dispatch(actions.success({ data: response.data, uri: key }));
        return response.data;
      } else {
        dispatch(actions.error({ error: response.error, uri: key }));
      }
    })();
  }
  return [(requestStates[key] || initialState) as RequestState<T>, postData];
};
