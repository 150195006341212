import React from 'react';
import { useParams } from 'react-router-dom';
import { WithLoaderProps } from './withLoader';

export type WithRouteSakIdProps = {
  sakId: string;
};

const withRouteSakId =
  <P extends WithLoaderProps & WithRouteSakIdProps>(
    Component: React.ComponentType<P>
  ): React.FC<Omit<P, keyof WithRouteSakIdProps>> =>
  props => {
    const { sakId } = useParams<{ sakId: string }>();

    const notFound = props.notFound || !sakId;

    const newProps = {
      ...props,
      sakId,
      notFound,
    } as P & WithRouteSakIdProps;

    return <Component {...newProps} />;
  };

export default withRouteSakId;
