import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Field, Form, FormSpy } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { useHistory } from 'react-router-dom';
import { withAkseptertBrukervilkårPage } from 'src/hoc/withPage';
import { NavigationButton, PrimaryButton, SecondaryButton } from '../../components/Buttons';
import ArrowBackSharp from '@material-ui/icons/ArrowBackSharp';
import { Color, Språk } from 'src/constants';
import { H2, H3, InfoType, ErrorType, ConfirmationType } from 'src/components/Typography';
import { isSuccess, post } from 'src/ajax';
import { brukerActions } from 'src/store/brukerStore';
import useTranslate from 'src/hooks/useTranslate';
import NavigationConfirmationModal from 'src/modals/NavigationConfirmationModal';
import useShowErrorMessage from 'src/hooks/useShowErrorMessage';
import Label from './components/Label';
import FinalFormEmail from './components/FinalFormEmail';
import FinalFormPhone from './components/FinalFormPhone';
import Checkbox from './components/Checkbox';
import RadioButton from './components/RadioButton';
import { selectBruker } from 'src/store/selectors/brukerSelectors';
import { GrayContainer, ResponsiveContainer } from 'src/components/Container/Container';

const Heading1 = styled(H2).attrs({ as: 'h1', noMargin: true })`
  margin-bottom: 40px;
`;
const Heading2 = styled(H3).attrs({ as: 'h2', noMargin: true })`
  margin-bottom: 20px;
`;

const Heading3 = styled(H3).attrs({ as: 'h2', noMargin: true })`
  margin-bottom: 20px;
  font-size: 20px;
`;

const StyledHr = styled.hr`
  border-left: solid 1px ${Color.GreyNormal};
  margin-top: 40px;
  margin-bottom: 32px;
  width: 100%;
`;

const MinProfil = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const translate = useTranslate();
  const showErrorMessage = useShowErrorMessage();

  const bruker = useSelector(selectBruker);

  const validateNotificationFields = (_: never, values: FormValues) =>
    !values.epostVarsling && !values.telefonVarsling
      ? translate('profil.validering-varslingskanal')
      : undefined;

  const onSubmit = async (values: FormValues) => {
    const oppdatertKontaktinfo = {
      epost: {
        adresse: values.epost,
        varsling: values.epostVarsling,
      },
      telefon: {
        nummer: values.telefon,
        varsling: values.telefonVarsling,
        // Sender tilbake samme landkode som vi hentet ut
        landkode: bruker.telefon.landkode,
      },
      valgtSpråk: values.valgtSpråk,
    };

    const response = await post('/bruker', oppdatertKontaktinfo);

    if (isSuccess(response)) {
      dispatch(brukerActions.setBruker(Object.assign({}, bruker, oppdatertKontaktinfo)));
    } else {
      return { [FORM_ERROR]: 'profil.oppdatering-feilet' };
    }
  };

  type FormValues = {
    epost: string;
    telefon: string;
    epostVarsling: boolean;
    telefonVarsling: boolean;
    valgtSpråk: Språk;
  };

  const initialValues: FormValues = {
    epost: bruker.epost.adresse,
    telefon: bruker.telefon.nummer,
    epostVarsling: bruker.epost.varsling,
    telefonVarsling: bruker.telefon.varsling,
    valgtSpråk: bruker.valgtSpråk,
  };

  return (
    <>
      <NavigationButton onClick={history.goBack} flexStart noPadding>
        <ArrowBackSharp />
        {translate('tilbake')}
      </NavigationButton>

      <GrayContainer>
        <Heading1>{translate('profil.tittel')}</Heading1>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({
            dirty,
            errors,
            form,
            handleSubmit,
            hasValidationErrors,
            pristine,
            submitSucceeded,
            submitting,
            touched,
          }) => {
            const showSubmitSucceeded = pristine && submitSucceeded;
            const showKontaktinformasjonError =
              (touched.epostVarsling || touched.telefonVarsling) && errors.epostVarsling;

            return (
              <form onSubmit={handleSubmit}>
                <StyledHr />

                <Heading3>{translate('profil.om-meg')}</Heading3>
                <Label value={translate('profil.navn')}>
                  <p style={{ marginLeft: '15px' }}>
                    <InfoType>{`${bruker.fornavn} ${bruker.etternavn}`}</InfoType>
                  </p>
                </Label>

                <ResponsiveContainer>
                  <FinalFormEmail
                    name="epost"
                    label={`${translate('profil.epost')} *`}
                    style={{ flex: 2 }}
                    aria-required="true"
                  />
                  <FinalFormPhone
                    name="telefon"
                    label={`${translate('profil.mobil')} *`}
                    style={{ flex: 1 }}
                    aria-required="true"
                  />
                </ResponsiveContainer>

                <StyledHr />

                <Heading2>{translate('profil.preferanser')}</Heading2>

                <Label value={`${translate('profil.varsling')} *`}>
                  <ResponsiveContainer>
                    <Field
                      name="epostVarsling"
                      type="checkbox"
                      render={props => (
                        <Checkbox input={props.input} label="E-post" id="notify-opt-1" />
                      )}
                      validate={validateNotificationFields}
                    />
                    <Field
                      name="telefonVarsling"
                      type="checkbox"
                      render={props => (
                        <Checkbox input={props.input} label="SMS" id="notify-opt-2" />
                      )}
                      validate={validateNotificationFields}
                    />
                  </ResponsiveContainer>
                  <ErrorType aria-live="polite" noMargin={!showKontaktinformasjonError}>
                    {showKontaktinformasjonError && errors.epostVarsling}
                  </ErrorType>
                </Label>

                <Label value={`${translate('profil.språk')} *`}>
                  <ResponsiveContainer>
                    <Field
                      name="valgtSpråk"
                      render={props => <RadioButton label="Bokmål" id="nb" input={props.input} />}
                      type="radio"
                      value="nb"
                    />
                    <Field
                      name="valgtSpråk"
                      render={props => <RadioButton label="Nynorsk" id="nn" input={props.input} />}
                      type="radio"
                      value="nn"
                    />
                  </ResponsiveContainer>
                </Label>

                <StyledHr />

                <PrimaryButton
                  type="submit"
                  isLoading={submitting}
                  disabled={pristine || hasValidationErrors}
                >
                  {translate('profil.lagre')}
                </PrimaryButton>

                <ConfirmationType as="p" aria-live="polite" noMargin={!showSubmitSucceeded}>
                  {showSubmitSucceeded ? translate('endringer-lagret') : ''}
                </ConfirmationType>

                <FormSpy
                  subscription={{ submitError: true }}
                  onChange={({ submitError }) => {
                    if (submitError) {
                      showErrorMessage(submitError);
                    }
                  }}
                />

                <NavigationConfirmationModal
                  show={dirty}
                  title={translate('profil.advarsel-tittel')}
                  confirmText={translate('ja')}
                  cancelText={translate('avbryt')}
                  // Ved navigering til samme side så vil dialogen aldri forsvinne med mindre formen resetes
                  onConfirm={form.reset}
                >
                  <InfoType as="p">{translate('profil.advarsel-1')}</InfoType>
                  <InfoType as="p">{translate('profil.advarsel-2')}</InfoType>
                </NavigationConfirmationModal>
              </form>
            );
          }}
        />
      </GrayContainer>

      <GrayContainer>
        <Heading2>{translate('profil.slett-bruker')}</Heading2>

        <div style={{ marginBottom: '36px' }}>
          <InfoType as="p">{translate('profil.slett-bruker-info1')}</InfoType>
          <InfoType as="p">{translate('profil.slett-bruker-info2')}</InfoType>
        </div>

        <SecondaryButton onClick={() => history.push('/avregistrering')}>
          {translate('profil.slett-bruker-til-avregistrering')}
        </SecondaryButton>
      </GrayContainer>
    </>
  );
};

export default withAkseptertBrukervilkårPage(MinProfil);
