import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { H4, LabelType } from 'src/components/Typography';
import { Color } from 'src/constants';
import DescriptionSharp from '@material-ui/icons/DescriptionSharp';
import ArrowRightAltSharp from '@material-ui/icons/ArrowRightAltSharp';
import { SecondaryButton } from 'src/components/Buttons';
import useTranslate from 'src/hooks/useTranslate';
import NesteOppgave from './NesteOppgave';
import { Sak } from 'src/domain/sak';

const Entry = styled.li`
  padding: 28px 0;
  display: flex;
  align-items: center;
  cursor: pointer;

  > * {
    margin-right: 24px;
  }

  > *:first-child {
    margin: 0 24px;
  }

  @media (max-width: 540px) {
    > * {
      margin-right: 12px;
    }

    > *:first-child {
      margin: 0 12px;
    }

    button {
      display: none;
    }
  }

  button,
  svg {
    flex: none;
  }
`;

const Saksinfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 50%;
  overflow: hidden;

  @media (max-width: 780px) {
    flex: 1;
  }

  * {
    cursor: pointer;
  }
`;

const StyledIcon = styled(DescriptionSharp)`
  color: ${Color.GreyDark};
  opacity: 0.64;
`;

type SakslisteElementProps = {
  sak: Sak;
};

const SakslisteElement = ({ sak }: SakslisteElementProps) => {
  const history = useHistory();
  const translate = useTranslate();

  const harUlesteDokumenter = sak.dokumenter.some(x => !x.lest && x.nedlastbart);

  return (
    <Entry key={sak.sakId} onClick={() => history.push(`/saker/${sak.sakId}/mine-oppgaver`)}>
      <Saksinfo>
        <LabelType noMargin>{sak.saksnummer}</LabelType>
        <H4 noMargin>{sak.sakstema}</H4>
      </Saksinfo>
      <NesteOppgave sak={sak} />
      {harUlesteDokumenter && (
        <StyledIcon titleAccess={translate('saksoversikt.uleste-dokumenter')} />
      )}
      <SecondaryButton>
        {translate('saksoversikt.til-saken')}
        <ArrowRightAltSharp />
      </SecondaryButton>
    </Entry>
  );
};

export default SakslisteElement;
