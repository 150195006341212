import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetch, isSuccess } from 'src/ajax';
import { useAppDispatch } from 'src/store';
import { actions, initialState, RequestState } from 'src/store/requestStatesStore';
import { selectRequestStates } from 'src/store/selectors/requestStatesSelectors';

export default <T = unknown>(uri: string, callback?: (value: T) => void): RequestState<T> => {
  const dispatch = useAppDispatch();
  const requestStates = useSelector(selectRequestStates)[uri];

  useEffect(() => {
    const fetchData = async () => {
      dispatch(actions.fetch({ uri }));

      const response = await fetch<T>(uri);

      if (isSuccess(response)) {
        if (callback) {
          callback(response.data);
        }
        dispatch(actions.success({ data: response.data, uri }));
      } else {
        dispatch(actions.error({ error: response.error, uri }));
      }
    };
    // Kjører fetch kallet engang uavhengig av respons. Må refreshe siden for å kjøre på nytt.
    // Gjøres kun på nytt hvis det ikke finnes noen request med states.

    if (!requestStates || requestStates.isNotAsked) {
      fetchData();
    }
  }, [requestStates]);
  return (requestStates || initialState) as RequestState<T>;
};
