import React from 'react';
import useTranslate from 'src/hooks/useTranslate';
import Page from 'src/components/Page';
import { ErrorMessage } from 'src/components/Messages';

export default function Innloggingsfeil() {
  const translate = useTranslate();
  return (
    <Page>
      <ErrorMessage title={translate('innloggingsfeil')} />
    </Page>
  );
}
