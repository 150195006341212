import { SvgIconTypeMap, SvgIconProps } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import React from 'react';
import { Color } from 'src/constants';
import IconText from 'src/pages/Sak/components/MineOppgaver/Oppgaver/IconText';
import styled from 'styled-components';

type ContainerProps = {
  maxWidth?: string;
};

const createBaseAlert = function <T>() {
  return styled.div<T & ContainerProps>`
    max-width: ${props => props.maxWidth && props.maxWidth};
    padding-left: 15px;
    margin-top: 36px;
    margin-bottom: 36px;
    display: ${props => (props.hidden ? 'none' : 'flex')};
  `;
};

const BaseAlert = createBaseAlert();

const InfoAlertTemplate = styled(BaseAlert)`
  border: solid 0.6px ${Color.dsBlue};
  background-color: ${Color.dsLightBlue};
`;

const DefaultAlertTemplate = styled(BaseAlert)`
  border: solid 0.6px ${Color.dsGreyDark};
  background-color: ${Color.dsGreyLight2};
`;

const InteractiveAlertTemplate = styled(BaseAlert)`
  background-color: ${Color.dsInteractiveLightest};
  padding-left: 15%;
`;

type AlertProps = {
  Icon?: OverridableComponent<SvgIconTypeMap<SvgIconProps, 'svg'>>;
  iconColor?: string;
  iconText?: string | JSX.Element;
  hidden?: boolean;
};

function createAlertFromTemplate<P = unknown>({
  Template,
  color,
}: {
  Template: React.ComponentType<
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  >;
  color: string;
}): React.FC<AlertProps & P> {
  return ({ children, iconColor = color, iconText, hidden, Icon }) => {
    return (
      <Template hidden={hidden}>
        <IconText Icon={Icon} iconColor={iconColor} iconText={iconText} children={children} />
      </Template>
    );
  };
}

export const InfoAlert = createAlertFromTemplate({
  Template: InfoAlertTemplate,
  color: Color.dsBlue,
});
export const DefaultAlert = createAlertFromTemplate({
  Template: DefaultAlertTemplate,
  color: Color.dsGreyDark,
});
export const InteractiveAlert = createAlertFromTemplate({
  Template: InteractiveAlertTemplate,
  color: Color.dsBlue,
});
