import React, { useState, useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import useTranslate from 'src/hooks/useTranslate';
import { Color } from 'src/constants';
import { InfoType, BodyType } from 'src/components/Typography';
import { StealthButton } from 'src/components/Buttons';
import DeleteSharp from '@material-ui/icons/DeleteSharp';
import OpenInNewSharp from '@material-ui/icons/OpenInNewSharp';

type ContainerProps = {
  isDragAccept: boolean;
  isDragReject: boolean;
};

const getBorderColor = (props: ContainerProps) => {
  if (props.isDragAccept) {
    return Color.Blue;
  }
  if (props.isDragReject) {
    return Color.Red;
  }
  return Color.GreyNormal;
};

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  color: ${Color.GreyDark};
  background-color: transparent;
  border: dashed 2px ${props => getBorderColor(props)};
  outline: none;
  transition: border 0.25s ease-in-out;
`;

const FileList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 12px 0px 48px 0px;
  padding: 0;

  > li:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const FileEntry = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Color.White};
  box-shadow: 1px 1px 1px 1px ${Color.GreyLight2};
  padding: 16px 32px;

  > span {
    flex: 1 1 80%;
    margin-right: 24px;
  }

  > a {
    flex: 1 1 10%;
    margin-right: 24px;
  }

  > button {
    flex: 1 1 10%;
  }
`;

const OpenFile = styled.a`
  display: flex;
  justify-content: center;
  color: ${Color.Blue};
`;

type DropzoneProps = {
  onChange?: (x: File[]) => void;
  files?: File[];
  [x: string]: unknown;
};

type FileUrl = {
  fileName: string;
  value: string;
};

function Dropzone({ onChange, files = [], ...rest }: DropzoneProps) {
  const [fileUrls, setFileUrls] = useState<FileUrl[]>([]);
  const fileUrlsRef = useRef<FileUrl[]>(fileUrls);
  const translate = useTranslate();

  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    accept: {
      'image/png': [],
      'image/jpeg': [],
      'application/pdf': []
    },
    onDrop: acceptedFiles => {
      const existingFileNames = files.map(x => x.name);
      const newFiles = acceptedFiles.filter(x => !existingFileNames.includes(x.name));
      onChange(files.concat(newFiles));
    },
  });

  useEffect(() => {
    const existingFileNamesFromFiles = files.map(x => x.name);
    const existingFileNamesFromFileUrls = fileUrls.map(x => x.fileName);

    const fileUrlsToAdd = files
      .filter(x => !existingFileNamesFromFileUrls.includes(x.name))
      .map(x => ({ fileName: x.name, value: URL.createObjectURL(x) }));

    const fileUrlsToRemove = fileUrls.filter(x => !existingFileNamesFromFiles.includes(x.fileName));
    fileUrlsToRemove.forEach(x => URL.revokeObjectURL(x.value));

    const updatedFileUrls = fileUrls
      .filter(x => !fileUrlsToRemove.includes(x))
      .concat(fileUrlsToAdd);
    setFileUrls(updatedFileUrls);
    fileUrlsRef.current = updatedFileUrls;
  }, [files]);

  useEffect(
    () => () => {
      // State is already cleared when unmounting - use fileUrlsRef instead
      fileUrlsRef.current.forEach(x => URL.revokeObjectURL(x.value));
    },
    []
  );

  const removeFile = (fileUrl: FileUrl) => () => {
    const filteredFiles = files.filter(x => x.name !== fileUrl.fileName);
    onChange(filteredFiles);
  };

  return (
    <>
      {files.length !== 0 && (
        <>
          <InfoType>{translate('filopplastning.opplastede')}</InfoType>
          <FileList>
            {fileUrls.map(x => (
              <FileEntry key={x.fileName}>
                <BodyType>{x.fileName}</BodyType>
                <OpenFile
                  href={x.value}
                  target="_blank"
                  rel="noopener noreferrer"
                  title={translate('filopplastning.forhåndsvisning')}
                >
                  <OpenInNewSharp />
                </OpenFile>
                <StealthButton onClick={removeFile(x)} title={translate('filopplastning.slett')}>
                  <DeleteSharp />
                </StealthButton>
              </FileEntry>
            ))}
          </FileList>
        </>
      )}
      <Container {...getRootProps({ isDragAccept, isDragReject })}>
        <input {...getInputProps()} {...rest} />
        <InfoType>{translate('filopplastning.dra-og-slipp')}</InfoType>
      </Container>
    </>
  );
}

export default Dropzone;
