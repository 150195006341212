import React from 'react';
import styled from 'styled-components';

import Page from 'src/components/Page';
import { H2, H4, LeadType, BodyType } from 'src/components/Typography';
import useTranslate from 'src/hooks/useTranslate';

const StyledH4 = styled(H4)`
  margin-top: 32px;
  margin-bottom: 12px;
  word-wrap: break-word;
`;

const StyledLeadType = styled(LeadType)`
  margin-bottom: 12px;
`;

const Informasjonskapsler = () => {
  const translate = useTranslate();

  return (
    <Page hideLogout>
      <H2>{translate('informasjonskapsler.header')}</H2>
      <BodyType>{translate('informasjonskapsler.info')}</BodyType>
      <StyledH4>{translate('informasjonskapsler.auth')}</StyledH4>
      <StyledLeadType>{translate('informasjonskapsler.aktorportalen-domene')}</StyledLeadType>
      <BodyType>{translate('informasjonskapsler.auth-info')}</BodyType>
      <StyledH4>{translate('informasjonskapsler.auth-expires')}</StyledH4>
      <StyledLeadType>{translate('informasjonskapsler.aktorportalen-domene')}</StyledLeadType>
      <BodyType>{translate('informasjonskapsler.auth-expires-info')}</BodyType>
      <StyledH4>{translate('informasjonskapsler.oidc-correlation')}</StyledH4>
      <StyledLeadType>{translate('informasjonskapsler.aktorportalen-domene')}</StyledLeadType>
      <BodyType>{translate('informasjonskapsler.oidc-correlation-info')}</BodyType>
      <StyledH4>{translate('informasjonskapsler.oidc-nonce')}</StyledH4>
      <StyledLeadType>{translate('informasjonskapsler.aktorportalen-domene')}</StyledLeadType>
      <BodyType>{translate('informasjonskapsler.oidc-nonce-info')}</BodyType>
      <StyledH4>{translate('informasjonskapsler.anti-xsrf')}</StyledH4>
      <StyledLeadType>{translate('informasjonskapsler.aktorportalen-domene')}</StyledLeadType>
      <BodyType>{translate('informasjonskapsler.anti-xsrf-info')}</BodyType>
      <StyledH4>{translate('informasjonskapsler.auth-session-id')}</StyledH4>
      <StyledLeadType>{translate('informasjonskapsler.oidc-domene')}</StyledLeadType>
      <BodyType>{translate('informasjonskapsler.auth-session-id-info')}</BodyType>
      <StyledH4>{translate('informasjonskapsler.kc-restart')}</StyledH4>
      <StyledLeadType>{translate('informasjonskapsler.oidc-domene')}</StyledLeadType>
      <BodyType>{translate('informasjonskapsler.kc-restart-info')}</BodyType>
      <StyledH4>{translate('informasjonskapsler.keycloak-session')}</StyledH4>
      <StyledLeadType>{translate('informasjonskapsler.oidc-domene')}</StyledLeadType>
      <BodyType>{translate('informasjonskapsler.keycloak-session-info')}</BodyType>
      <StyledH4>{translate('informasjonskapsler.keycloak-identity')}</StyledH4>
      <StyledLeadType>{translate('informasjonskapsler.oidc-domene')}</StyledLeadType>
      <BodyType>{translate('informasjonskapsler.keycloak-identity-info')}</BodyType>
    </Page>
  );
};

export default Informasjonskapsler;
