import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { Color } from 'src/constants';
import { BodyType, InfoType } from 'src/components/Typography';

export const Ul = styled.ul`
  color: ${Color.GreyNormal};
  margin: 1rem 0;
`;

export const Li = styled(InfoType).attrs({ as: 'li' })`
  margin: 1rem 0;
`;

export const HorizontalHr = styled.div`
  width: 100%;
  border-top: solid 1px ${Color.GreyNormal};
  margin: 32px 0px;
`;

export const SøknadInfo = styled.div`
  max-width: 456px;
`;

export const SøknadForm = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0;

  > ${BodyType} {
    margin: 16px 0 8px 0;
  }

  > ${InfoType} {
    margin-top: 16px;
    max-width: 456px;
  }

  > textarea {
    margin-bottom: 16px;
  }

  > button {
    margin-top: 64px;
    margin-bottom: 24px;
    align-self: flex-start;
  }
`;

type DropzoneContainerFritakProps = {
  showInfo: boolean;
};

export const DropzoneContainerFritak = styled.div<DropzoneContainerFritakProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 36px;
  background-color: ${Color.GreyLight};
  margin-top: ${props => (props.showInfo ? '12px' : '0')};
`;

export const Anchor = styled(Link)`
  color: #1e66b0;
  font-size: 16px;
  line-height: 20px;
  text-decoration: none;
`;

const flexWrap = css`
  display: flex;
  flex-wrap: wrap;
`;

const rightMarginForFlexWrap = css`
  &:not(:last-child) {
    margin-right: 24px;
  }
`;

const marginsForFlexWrap = css`
  ${rightMarginForFlexWrap}
  margin-bottom: 16px;
`;

export const SøknadFooterContainer = styled.div`
  ${flexWrap}
  justify-content: space-between;

  > div {
    ${flexWrap}
    ${rightMarginForFlexWrap}

    > * {
      ${marginsForFlexWrap}
    }
  }

  > div:first-child {
    a {
      display: flex;
      ${marginsForFlexWrap}

      align-items: center;
      text-decoration: none;
      color: inherit;

      svg {
        margin-right: 8px;
        color: ${Color.GreyDark};
      }
    }
  }

  @media (max-width: 540px) {
    flex-direction: column;

    > div:last-child {
      margin-left: 0;
    }
  }
`;
