import styled from 'styled-components';
import { Color } from 'src/constants';

export const SakerContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: auto;

  h1 {
    margin-left: 46px;
  }

  & > div:nth-of-type(1) {
    align-self: flex-end;
    margin: 40px 46px 24px 46px;
  }

  ul {
    border-top: 2px solid ${Color.GreyDark};
  }
`;
