import { ReactNode } from 'react';
import styled from 'styled-components';

export const PDFToolbarContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

type MarginPositionProps = {
  position?: string;
};

const ToolbarItemContainer = styled.div<MarginPositionProps>`
  padding: 0px 2px;
  ${props => (props.position = undefined ? '' : `margin-${props.position}: auto;`)}
`;

type ToolbarItemProps = {
  position?: string;
  children?: ReactNode;
};

export const ToolbarItem = ({ children, position }: ToolbarItemProps) => {
  return <ToolbarItemContainer position={position}>{children}</ToolbarItemContainer>;
};
