import React from 'react';
import CloseSharp from '@material-ui/icons/CloseSharp';
import MenuSharp from '@material-ui/icons/MenuSharp';

import { NavigationButton } from 'src/components/Buttons';

type HamburgerMenuProps = {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
};

export default function HamburgerMenu({ open, onOpen, onClose }: HamburgerMenuProps) {
  const onClick = open ? onClose : onOpen;
  const Icon = open ? CloseSharp : MenuSharp;

  return (
    <NavigationButton onClick={onClick} aria-label="Åpne menyen">
      <Icon aria-hidden="true" />
    </NavigationButton>
  );
}
