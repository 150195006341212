import React from 'react';
import styled from 'styled-components';
import NavLink from 'src/components/NavLink';

import logo from 'src/images/riksvapen_min.svg';

const StyledLink = styled(NavLink)`
  display: flex;
  text-decoration: none;
  user-select: none;
`;

const Logo = styled.img`
  height: 56px;
  width: 30px;
`;

const BrandTitle = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Arbutus Slab', Helvetica;
  text-transform: uppercase;
  font-size: 11px;
  border-top: 1px solid #4a4a4a;
  border-bottom: 1px solid #4a4a4a;
  color: black;
  letter-spacing: 1px;
  padding: 0.4rem 0;
  margin-left: 1rem;
  align-self: flex-end;
`;

const Brand = () => {
  return (
    <StyledLink to="/" aria-label="Startside" tabIndex={0}>
      <Logo src={logo} alt="Riksvåpen" />
      <BrandTitle>
        <span>DOMSTOLENES</span>
        <span>Aktørportal</span>
      </BrandTitle>
    </StyledLink>
  );
};

export default Brand;
