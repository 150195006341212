import React, { useRef, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Color } from 'src/constants';
import Spinner from 'src/components/Spinner';

export const ButtonType = styled.span`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
`;

const cursor = css`
  :enabled {
    cursor: pointer;
  }
`;

type BaseButtonProps = {
  noPadding?: boolean;
  flexStart?: boolean;
};

const createBaseButton = function <T>() {
  return styled.button<T & BaseButtonProps>`
    ${cursor}
    border: none;
    height: 40px;
    padding: ${props => (props.noPadding ? '0px' : '0px 16px')};
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    ${props =>
      props.flexStart &&
      css`
        align-self: flex-start;
      `}

    ::-moz-focus-inner {
      border: 0;
    }

    :disabled {
      opacity: 0.48;
    }

    > div {
      display: flex;

      > * {
        margin-left: 12px;
      }

      > *:first-child {
        margin-left: 0px;
      }

      span {
        white-space: nowrap;
      }
    }
  `;
};

const BaseButton = createBaseButton();

type ChildDivProps = {
  isLoading: boolean;
};

const ChildDiv = styled.div<ChildDivProps>`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  ${props =>
    props.isLoading
      ? `
          > *:not(:last-child) {
            visibility: hidden;
          }
          
          > *:last-child {
            position: absolute;
            margin: 0;
          }
        `
      : null}
`;

const renderButtonChild = (child: React.ReactNode) =>
  typeof child == 'string' ? <ButtonType>{child}</ButtonType> : child;

type BaseButtonContentProps = {
  isLoading: boolean;
  spinnerColor: Color;
};

const BaseButtonContent: React.FC<BaseButtonContentProps> = ({
  children,
  isLoading,
  spinnerColor,
}) => {
  const [firstChild, secondChild] = React.Children.toArray(children);

  return (
    <ChildDiv isLoading={isLoading}>
      {renderButtonChild(firstChild)}
      {renderButtonChild(secondChild)}
      {isLoading && <Spinner color={spinnerColor} />}
    </ChildDiv>
  );
};

const PrimaryButtonTemplate = styled(BaseButton)`
  color: ${Color.White};
  background-color: ${Color.dsGreyNormal};
  transition: 0.2s;

  :disabled {
    opacity: 45%;
    background-color: ${Color.dsGreyDark};
  }

  :hover {
    background-color: ${Color.dsGreyDark};
  }

  :focus {
    background-color: ${Color.dsGreyDark};
    border: solid 1px #d2231e;
  }
`;

const SecondaryButtonTemplate = styled(BaseButton)`
  color: ${Color.dsGreyDark};
  background-color: transparent;
  border: solid 1px ${Color.dsGreyDark};
  transition: 0.2s;

  :disabled {
    opacity: 45%;
  }

  :hover: {
    background-color ${Color.dsGreyDark};
    opacity: 18%;
  }
`;

const TertiaryButtonTemplate = styled(BaseButton)`
  color: ${Color.dsGreyDark};
  background-color: transparent;

  > div {
    :hover {
      border-bottom: solid 1px ${Color.dsGreyDark};
    }

    :disabled {
      opacity: 45%;
    }
  }
`;

const NavigationButtonTemplate = styled(BaseButton)`
  color: ${Color.GreyDark};
  background-color: ${Color.White};
`;

const DangerButtonTemplate = styled(BaseButton)`
  color: ${Color.White};
  background-color: ${Color.Red};
`;
type SelectableButtonTemplateProps = {
  selected: boolean;
};

const SelectableButtonTemplate = styled(createBaseButton<SelectableButtonTemplateProps>())`
  transition: 0.2s;

  ${props =>
    props.selected
      ? `
        color: ${Color.White};
        background-color: ${Color.dsGreyDark};
        transition: 0.2s;
      `
      : `
        background-color:transparent;
        color: ${Color.dsGreyDark};
        border: solid 1px ${Color.GreyDark};
        transition: 0.2s;

        :hover {
          background-color: rgba(45, 45, 45, 0.10);
        }

        :disabled {
          opacity: 45%;
        }
      `}
`;

type ButtonFromTemplateProps = {
  isLoading?: boolean;
  onClick?: () => void;
  onClickAsync?: () => Promise<void>;
};

export type ButtonProps = BaseButtonProps &
  ButtonFromTemplateProps &
  React.ClassAttributes<HTMLButtonElement> &
  React.ButtonHTMLAttributes<HTMLButtonElement>;

function createButtonFromTemplate<P = unknown>(
  Template: React.ComponentType<
    React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
  >,
  spinnerColor: Color
): React.FC<ButtonProps & P> {
  return ({ children, isLoading = false, onClick, onClickAsync, type = 'button', ...rest }) => {
    const ref = useRef();
    const [loading, setLoading] = useState(isLoading);

    useEffect(() => {
      setLoading(isLoading);
    }, [isLoading]);

    if (typeof onClickAsync === 'function') {
      onClick = () => {
        setLoading(true);
        onClickAsync().finally(() => {
          // Bruker ref til å se om componenten er unmounted
          if (ref.current) {
            setLoading(false);
          }
        });
      };
    }

    return (
      <Template ref={ref} onClick={loading ? null : onClick} type={type} {...rest}>
        <BaseButtonContent isLoading={loading} spinnerColor={spinnerColor}>
          {children}
        </BaseButtonContent>
      </Template>
    );
  };
}

export const PrimaryButton = createButtonFromTemplate(PrimaryButtonTemplate, Color.White);
export const SecondaryButton = createButtonFromTemplate(SecondaryButtonTemplate, Color.GreyDark);
export const TertiaryButton = createButtonFromTemplate(TertiaryButtonTemplate, Color.GreyDark);
export const NavigationButton = createButtonFromTemplate(NavigationButtonTemplate, Color.GreyDark);
export const DangerButton = createButtonFromTemplate(DangerButtonTemplate, Color.Red);

/**
 * Reset button styles.
 */
export const StealthButton = styled.button`
  ${cursor}
  border: none;
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
`;

export const SelectableButton = createButtonFromTemplate<SelectableButtonTemplateProps>(
  SelectableButtonTemplate,
  Color.GreyDark
);
