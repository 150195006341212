export const BASE_URL = '/api';

export enum Språk {
  Bokmål = 'nb',
  Nynorsk = 'nn',
  Samisk = 'se',
}

export enum Color {
  Black = '#000',
  Red = '#d2231e',
  Blue = '#1e66b0',
  White = '#fff',
  GreyDark = '#2d2d2d',
  GreyNormal = '#9b9b9b',
  GreyLight = '#f4f4f4',
  GreyLight2 = '#dedede',
  dsGreyNormal = '#4A4A4A',
  dsGreyNormalLight = '#5c5c5c',
  dsGreyDark = '#2d2d2d',
  dsGreyLight = '#9a9a9a',
  dsGreyLight2 = '#ededed',
  dsBlue = '#618dc1',
  dsLightBlue = '#e0edfa',
  dsInteractiveLightest = '#EAF2F7',
}

export const AuthSessionExpirationCookie = 'MeddommerAktorportalen.AuthSessionExpires';
