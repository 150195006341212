import React from 'react';
import { useHistory } from 'react-router-dom';
import { withAkseptertBrukervilkårPage } from 'src/hoc/withPage';
import { DangerButton, SecondaryButton } from 'src/components/Buttons';
import { H3, InfoType } from 'src/components/Typography';
import styled from 'styled-components';
import useTranslate from 'src/hooks/useTranslate';
import usePost from 'src/hooks/usePost';
import useLogoutCallback from 'src/hooks/useLogoutCallback';

const CenteredContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;
const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 1rem;

  button:last-child {
    margin-left: 0.5rem;
  }
`;

const Avregistrering = () => {
  const history = useHistory();
  const translate = useTranslate();
  const { logoutCallback, setRedirectToAvregistreringPage } = useLogoutCallback();
  const [request, doPost] = usePost('/avregistrer');
  function avregistrer() {
    doPost().then(() => {
      setRedirectToAvregistreringPage(true);
      logoutCallback();
    });
  }
  return (
    <CenteredContent>
      <H3>{translate('avregistrering.vil-du-avregistrere-deg')}</H3>
      <InfoType>{translate('avregistrering.info')}</InfoType>
      <ButtonsContainer>
        <SecondaryButton onClick={() => history.goBack()}>
          {translate('avregistrering.nei')}
        </SecondaryButton>
        <DangerButton onClick={avregistrer} isLoading={request.isPending}>
          {translate('avregistrering.ja')}
        </DangerButton>
      </ButtonsContainer>
    </CenteredContent>
  );
};

export default withAkseptertBrukervilkårPage(Avregistrering);
