export enum HabilitetStatus {
  IkkeValgtHabilitet = 'IkkeValgtHabilitet',
  ValgtInhabil = 'ValgtInhabil',
  ValgtHabil = 'ValgtHabil',
  ValgtHabilitetErUtdatert = 'ValgtHabilitetErUtdatert',
}

export enum OppgaveStatus {
  IkkeTilgjengelig = 'IkkeTilgjengelig',
  IkkeUtført = 'IkkeUtført',
  Utført = 'Utført',
  UnderBehandling = 'UnderBehandling',
}

export enum InnkallingStatus {
  Ukjent = 'Ukjent',
  Ubesvart = 'Ubesvart',
  BekreftetOppmøte = 'BekreftetOppmøte',
  SøktFritak = 'SøktFritak',
  SøktInhabil = 'SøktInhabil',
  InnvilgetFritak = 'InnvilgetFritak',
  InnvilgetInhabil = 'InnvilgetInhabil',
}

export enum FritakType {
  Sykdom = 'Sykdom',
  Reise = 'Reise',
  Studie = 'Studie',
  Arbeidssituasjon = 'Arbeidssituasjon',
  Fødselspermisjon = 'Fødselspermisjon',
  Militærtjeneste = 'Militærtjeneste',
  Fylkestinget = 'Fylkestinget',
  Høyesterett = 'Høyesterett',
  Annet = 'Annet',
}

export enum DokumentType {
  Hoveddokument = 'Hoveddokument',
  Bilag = 'Bilag',
}

export enum AktoerRolle {
  Ukjent = 'Aktør uten rolle',
  Annen = 'Annen aktør rolle',
  Prosessfullmektig = 'Prosessfullmektig',
  Advokatfullmektig = 'Advokatfullmektig',
  Forsvarer = 'Forsvarer',
  Bistandsadvokat = 'Bistandsadvokat',
  Saksbehandler = 'Saksbehandler',
  ForberedendeDommer = 'Forberedende Dommer',
  Hovedforhandlingsdommer = 'Hovedforhandlingsdommer',
  RettsligMedhjelper = 'Rettslig Medhjelper',
  Aktor = 'Aktor',
  AlminneligMeddommer = 'Meddommer',
  FagkyndigMeddommer = 'Fagkyndig Meddommer',
  ArbeidslivskyndigMeddommer = 'Arbeidslivskyndig Meddommer',
  Skjonnsmedlem = 'Skjønnsmedlem',
  VaraMeddommer = 'Varameddommer',
  VaraSkjonnsmedlem = 'Varaskjønnsmedlem',
}
