import React from 'react';
import Page from 'src/components/Page';
import withInnloggetBruker from './withInnloggetBruker';
import withAkseptertBrukervilkår from './withAkseptertBrukervilkår';
import { PageProps } from 'src/components/Page/Page';
import { withFetchDriftsmelding } from './withFetch';

export const withPage = (Component: React.ComponentType) => (props: PageProps) =>
  (
    <Page {...props}>
      <Component />
    </Page>
  );

export const withInnloggetBrukerPage = (Component: React.ComponentType) =>
  withInnloggetBruker(withPage(Component));

export const withAkseptertBrukervilkårPage = (Component: React.ComponentType) =>
  withFetchDriftsmelding(withAkseptertBrukervilkår(withPage(Component)));
