import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  DateRangeOutlined,
  DescriptionOutlined,
  ErrorOutline,
  GetAppOutlined,
  InfoOutlined,
  MailSharp,
  PhoneSharp,
} from '@material-ui/icons';
import dateIsValid from 'date-fns/isValid';
import dateFormat from 'date-fns/format';
import { isSuccess, post } from 'src/ajax';
import { InnkallingStatus } from 'src/domain/enums';
import { oppgaverActions } from 'src/store/oppgaverStore';
import { sakerActions } from 'src/store/sakerStore';
import useTranslate, { TranslateFn } from 'src/hooks/useTranslate';
import { getTranslationKeyForDay, getTranslationKeyForMonth } from 'src/contexts/I18n';
import { SelectableButton } from 'src/components/Buttons';
import IconLink from '../IconLink';
import Oppgave from '../../Oppgave';
import { FlexRow, Hr, MarginContainer, P } from '../Oppgaver.style';
import useShowErrorMessage from 'src/hooks/useShowErrorMessage';
import { OppgaveStatus } from 'src/domain/enums';
import { lagOppgaveForBekreftOppmøte } from 'src/domain/oppgaver';
import { Sak } from 'src/domain/sak';
import { useAppDispatch, AppDispatch } from 'src/store';
import { selectBekreftOppmøte } from 'src/store/selectors/oppgaverSelectors';
import IconText from '../IconText';
import { Color } from 'src/constants';
import CheckSharp from '@material-ui/icons/CheckSharp';
import Tooltip from 'src/components/Tooltip';
import { InfoAlert } from 'src/components/Alert/AlertBox';

type BekreftOppmøteProps = {
  sak: Sak;
};

export default function BekreftOppmøte({ sak }: BekreftOppmøteProps) {
  const dispatch = useAppDispatch();
  const translate = useTranslate();
  const history = useHistory();
  const showErrorMessage = useShowErrorMessage();
  const viewModel = useSelector(selectBekreftOppmøte);
  const componentRef = useRef<HTMLDivElement>();
  const scrollToElement = () =>
    componentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

  async function submit(innkallingsStatus: InnkallingStatus) {
    if (sak.innkalling.status === innkallingsStatus) {
      // Allerede bekreftet oppmøte - åpne neste oppgave uten å gjøre noe mer
      åpneNesteOppgave(dispatch);
      scrollToElement();
      return;
    }

    if (innkallingsStatus === InnkallingStatus.BekreftetOppmøte) {
      const response = await post(`/saker/${sak.sakId}/oppmoete`);

      if (isSuccess(response)) {
        dispatch(
          sakerActions.setInnkallingStatusForSak({
            sakId: sak.sakId,
            innkallingStatus: InnkallingStatus.BekreftetOppmøte,
          })
        );
        åpneNesteOppgave(dispatch);
      } else {
        showErrorMessage('mine-oppgaver.bekreft-oppmøte.feil-beskrivelse');
      }
    }

    if (innkallingsStatus === InnkallingStatus.SøktFritak) {
      history.push(`/saker/${sak.sakId}/fritak`);
      dispatch(oppgaverActions.selectedSøkerFritak());
      dispatch(
        sakerActions.setInnkallingStatusForSak({
          sakId: sak.sakId,
          innkallingStatus: InnkallingStatus.SøktFritak,
        })
      );
    }
  }

  const setInnkallingsStatus = (status: InnkallingStatus) => {
    if (status === InnkallingStatus.BekreftetOppmøte) {
      dispatch(oppgaverActions.selectedBekrefterOppmøte());
      submit(status);
    }

    if (status === InnkallingStatus.SøktFritak) {
      submit(status);
    }
  };

  const { oppgaveStatus, frist } = lagOppgaveForBekreftOppmøte(sak);

  const isVaraMeddommer = (): boolean => {
    return sak.rolle.kode.toString() === ('VaraMeddommer' || 'VaraSkjonnsmedlem');
  };

  const isVaraUtenOppmoetePlikt = (): boolean => {
    return isVaraMeddommer() && !sak.innkalling?.oppmøteplikt;
  };

  const lagInnkallingsTekstForMeddommerType = (translate: TranslateFn, sak: Sak): string => {
    if (isVaraUtenOppmoetePlikt()) {
      return translate(
        'mine-oppgaver.bekreft-oppmøte.varameddommer.ikke-oppmøteplikt.tekst-innkalt',
        getRolleInSak(sak),
        sak.saksnummer
      );
    } else if (isVaraMeddommer()) {
      return translate(
        'mine-oppgaver.bekreft-oppmøte.varameddommer.tekst-innkalt',
        getRolleInSak(sak),
        sak.saksnummer
      );
    } else
      return translate(
        'mine-oppgaver.bekreft-oppmøte.tekst-innkalt',
        getRolleInSak(sak),
        sak.saksnummer
      );
  };

  const beskrivelse = translate(
    {
      [OppgaveStatus.IkkeTilgjengelig]: 'mine-oppgaver.bekreft-oppmøte.beskrivelse-ikke-utført',
      [OppgaveStatus.Utført]: 'mine-oppgaver.bekreft-oppmøte.beskrivelse-utført',
      [OppgaveStatus.IkkeUtført]: 'mine-oppgaver.bekreft-oppmøte.beskrivelse-ikke-utført',
      [OppgaveStatus.UnderBehandling]: 'mine-oppgaver.bekreft-oppmøte.beskrivelse-under-behandling',
    }[oppgaveStatus],
    isVaraUtenOppmoetePlikt() ? 'beredskap' : 'oppmøte'
  );

  const starttidspunkt = new Date(sak.innkalling.starttidspunkt);
  const tekstForStarttidspunkt = lagTekstForStarttidspunkt(translate, starttidspunkt);
  const tekstForAntallDager = lagTekstForAntallDager(translate, sak.innkalling.antallDager);
  const meddommerRolle = lagInnkallingsTekstForMeddommerType(translate, sak);
  const fritakinfo = 'https://www.domstol.no/no/meddommer/praktisk-informasjon/';

  return (
    <Oppgave
      tittel={translate('mine-oppgaver.bekreft-oppmøte.tittel')}
      beskrivelse={beskrivelse}
      oppgaveStatus={oppgaveStatus}
      frist={frist}
      open={viewModel.open}
      onClick={() => dispatch(oppgaverActions.toggleOpenBekreftOppmøte())}
    >
      <div ref={componentRef} className="bekreftOppmote" />
      <MarginContainer>
        <Hr />

        <P>
          <b>{translate('mine-oppgaver.bekreft-oppmøte.tekst-innkalt-overskrift')}</b>
          <P>{meddommerRolle}</P>
          <P>
            <a
              target="_blank"
              href={`/saker/${sak.sakId}/dokumenter/${sak.innkalling.innkallingsbrevId}`}
            >
              <IconText
                Icon={DescriptionOutlined}
                iconText={translate('mine-oppgaver.bekreft-oppmøte.åpne-innkalling')}
                iconColor={Color.dsBlue}
              />
            </a>
            <a
              target="_blank"
              href={`/api/file/${sak.innkalling.innkallingsbrevId}`}
              download={sak.innkalling.innkallingsbrevId}
            >
              <IconText
                Icon={GetAppOutlined}
                iconText={translate('mine-oppgaver.bekreft-oppmøte.last-ned')}
                iconColor={Color.dsBlue}
              />
            </a>
            <a href={`/saker/${sak.sakId}/om-saken`}>
              <IconText
                Icon={InfoOutlined}
                iconText={translate('mine-oppgaver.bekreft-oppmøte.les-mer')}
                iconColor={Color.dsBlue}
              />
            </a>
          </P>
        </P>
        <P>
          <b>{translate('mine-oppgaver.bekreft-oppmøte.tekst-tid-sted-overskrift')}</b>
          <IconText
            Icon={DateRangeOutlined}
            iconColor={Color.dsGreyDark}
            iconText={tekstForStarttidspunkt + ' ' + tekstForAntallDager}
          />
          {isVaraUtenOppmoetePlikt() ? (
            <></>
          ) : (
            <P>{translate('mine-oppgaver.bekreft-oppmøte.tekst-tid-sted-info')}</P>
          )}
        </P>
        <P>
          <b>{translate('mine-oppgaver.bekreft-oppmøte.fritak-info-overskrift')}</b>
        </P>
        <P> {translate('mine-oppgaver.bekreft-oppmøte.fritak-info1')}</P>
        <P>
          {translate('mine-oppgaver.bekreft-oppmøte.fritak-info2')}
          <a href={fritakinfo} target="_blank" rel="noreferrer noopener">
            {translate('mine-oppgaver.bekreft-oppmøte.fritak-info-link')}
          </a>
        </P>

        <Hr />

        <P>
          <b>
            {isVaraUtenOppmoetePlikt()
              ? translate(
                  'mine-oppgaver.bekreft-oppmøte.varameddommer.ikke-oppmøteplikt.vara-info-beredskap',
                  getRolleInSak(sak)
                )
              : translate('mine-oppgaver.bekreft-oppmøte.møteplikt-spørsmål', getRolleInSak(sak))}
          </b>
        </P>

        <FlexRow>
          <Tooltip
            text={
              isVaraUtenOppmoetePlikt()
                ? translate(
                    'mine-oppgaver.bekreft-oppmøte.varameddommer.ikke-oppmøteplikt.tooltip-info'
                  )
                : translate('mine-oppgaver.bekreft-oppmøte.tooltip-info')
            }
            disabled={oppgaveStatus !== OppgaveStatus.IkkeTilgjengelig}
            placement={'bottom'}
          >
            <FlexRow>
              <SelectableButton
                disabled={oppgaveStatus === OppgaveStatus.IkkeTilgjengelig}
                selected={viewModel.bekrefterOppmøte}
                onClick={() => setInnkallingsStatus(InnkallingStatus.BekreftetOppmøte)}
              >
                {isVaraUtenOppmoetePlikt()
                  ? translate(
                      'mine-oppgaver.bekreft-oppmøte.varameddommer.ikke-oppmøteplikt.knapp-bekreft'
                    )
                  : translate('mine-oppgaver.bekreft-oppmøte.knapp-bekreft')}
                {viewModel.bekrefterOppmøte ? <CheckSharp /> : <></>}
              </SelectableButton>
            </FlexRow>
          </Tooltip>
          <FlexRow>
            <SelectableButton
              selected={viewModel.søkerFritak}
              onClick={() => setInnkallingsStatus(InnkallingStatus.SøktFritak)}
            >
              {translate('mine-oppgaver.bekreft-oppmøte.knapp-fritak')}
              {viewModel.søkerFritak ? <CheckSharp /> : <></>}
            </SelectableButton>
          </FlexRow>
        </FlexRow>

        <InfoAlert
          hidden={sak.innkalling.status !== InnkallingStatus.SøktFritak}
          Icon={ErrorOutline}
          iconText={translate('mine-oppgaver.bekreft-oppmøte.beskrivelse-under-behandling')}
        />

        <Hr />
        <FlexRow>
          <IconLink
            Icon={MailSharp}
            href={`mailto:${sak.domstol.epost}`}
            text={sak.domstol.epost}
          />
          <IconLink
            Icon={PhoneSharp}
            href={`tel:${sak.domstol.telefon}`}
            text={sak.domstol.telefon}
            style={{ marginRight: 'auto' }}
          />
        </FlexRow>
      </MarginContainer>
    </Oppgave>
  );
}

function åpneNesteOppgave(dispatch: AppDispatch) {
  dispatch(oppgaverActions.closeBekreftOppmøte());
  dispatch(oppgaverActions.openLesSaksdokumenter());
}

function lagTekstForStarttidspunkt(translate: TranslateFn, starttidspunkt: Date) {
  if (!dateIsValid(starttidspunkt)) {
    return null;
  }

  const day = translate(getTranslationKeyForDay(starttidspunkt));
  const month = translate(getTranslationKeyForMonth(starttidspunkt));

  const dateTemplate = `'${day}' d. '${month}' yyyy`;
  const dateFormatted = dateFormat(starttidspunkt, dateTemplate);
  const timeTemplate = 'HH:mm';
  const timeFormatted = dateFormat(starttidspunkt, timeTemplate);

  return translate(
    'mine-oppgaver.bekreft-oppmøte.tekst-starttidspunkt',
    dateFormatted,
    timeFormatted
  );
}

function lagTekstForAntallDager(translate: TranslateFn, antallDager: number) {
  if (!antallDager) {
    return null;
  }

  const antallDagerMedEnhet = translateValueAndUnit(
    translate,
    antallDager,
    'enhet.dag',
    'enhet.dager'
  );
  return translate('mine-oppgaver.bekreft-oppmøte.tekst-antall-dager', antallDagerMedEnhet);
}

function translateValueAndUnit(
  translate: TranslateFn,
  value: number,
  singularTranslationKey: string,
  pluralTranslationKey: string
) {
  return `${value} ${
    value === 1 ? translate(singularTranslationKey) : translate(pluralTranslationKey)
  }`;
}

function splitCamelCaseValue(string: string): string {
  return string.replace(/([A-Z])/g, ' $1');
}

function checkIfAlminneligMeddommer(s: string): string {
  if (s !== 'AlminneligMeddommer' && s !== 'VaraMeddommer') {
    return splitCamelCaseValue(s);
  } else if (s === 'VaraMeddommer') {
    return s;
  }
  return 'meddommer';
}

const getRolleInSak = (sak: Sak): string => {
  return sak.rolle.kode.toString() === 'VaraMeddommer' || 'AlminneligMeddommer'
    ? checkIfAlminneligMeddommer(sak.rolle.kode.toString()).toLowerCase()
    : splitCamelCaseValue(sak.rolle.kode).toLowerCase();
};
