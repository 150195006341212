import React from 'react';
import styled from 'styled-components';
import useTranslate from 'src/hooks/useTranslate';
import { BodyType, LabelType } from 'src/components/Typography';
import { Color } from 'src/constants';

import { OppgaveStatus } from 'src/domain/enums';
import { lagOppgaveForVurderHabilitet, lagOppgaveForBekreftOppmøte } from 'src/domain/oppgaver';
import { Sak } from 'src/domain/sak';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-left: solid 1px ${Color.GreyDark};
  overflow: hidden;
  flex: 999 0 0;

  > * {
    margin-left: 16px;
  }

  @media (max-width: 780px) {
    display: none;
  }
`;

type NesteOppgaveProps = {
  sak: Sak;
};

export default function NesteOppgave({ sak }: NesteOppgaveProps) {
  const translate = useTranslate();

  const oppgaver: { key: string; oppgaveStatus: OppgaveStatus }[] = [
    {
      key: 'mine-oppgaver.vurder-habilitet.tittel',
      oppgaveStatus: lagOppgaveForVurderHabilitet(sak).oppgaveStatus,
    },
    {
      key: 'mine-oppgaver.bekreft-oppmøte.tittel',
      oppgaveStatus: lagOppgaveForBekreftOppmøte(sak).oppgaveStatus,
    },
  ];

  const nesteOppgave = oppgaver.find(x => x.oppgaveStatus === OppgaveStatus.IkkeUtført);

  if (!nesteOppgave) {
    return null;
  }

  return (
    <Container>
      <LabelType truncate>{translate('saksoversikt.neste-oppgave')}</LabelType>
      <BodyType truncate noMargin>
        {translate(nesteOppgave.key)}
      </BodyType>
    </Container>
  );
}
