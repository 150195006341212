import React from 'react';
import { ParterOgAktørerDto } from 'src/domain/aktor';
import { Brukervilkår } from 'src/domain/brukervilkår';
import { Driftsmelding } from 'src/domain/driftsmelding';
import { Frist } from 'src/domain/frist';
import { Hovedkrav } from 'src/domain/hovedkrav';
import { Møte } from 'src/domain/møte';
import useFetch from 'src/hooks/useFetch';
import { WithLoaderProps } from './withLoader';
import { WithRouteSakIdProps } from './withRouteSakId';

const withFetchBuilder =
  <
    TFetchResponse,
    PFetchResponse extends Record<string, unknown>, // Fetch response after it's mapped to props
    PComponent extends Record<string, unknown>, // Component props before it's enhanced
    PEnhancedComponent extends Record<string, unknown> = Record<string, unknown> // Additional props needed by the enhanced component
  >(
    url: string | ((props: PEnhancedComponent) => string), // The enhanced component can require additional props for building the fetch URL
    mapResponseToProps: (x: TFetchResponse) => PFetchResponse,
    Component: React.ComponentType<PComponent & Partial<PFetchResponse>> // Component to be enhanced doesn't need to use all props from PFetchResponse
  ): React.ComponentType<
    WithLoaderProps & Omit<PComponent, keyof PFetchResponse> & PEnhancedComponent
  > =>
  props => {
    const urlValue = typeof url === 'function' ? url(props) : url;

    const req = useFetch<TFetchResponse>(urlValue);

    const isLoading = props.isLoading || req.isNotAsked || req.isPending;
    const hasError = props.hasError || req.isError;
    const mappedProps =
      !isLoading && !hasError && req.data != null ? mapResponseToProps(req.data) : null;

    const newProps = {
      ...props,
      ...mappedProps,
      isLoading,
      hasError,
    } as unknown as PComponent & PFetchResponse;

    return <Component {...newProps} />;
  };

export const withFetchDriftsmelding = <P extends Record<string, unknown>>(
  Component: React.ComponentType<P>
) =>
  withFetchBuilder<Driftsmelding, { driftsmelding: Driftsmelding }, P>(
    '/driftsmelding',
    response => ({ driftsmelding: response }),
    Component
  );

export const withFetchHovedkrav = <T extends Record<string, unknown>>(
  Component: React.ComponentType<T>
) =>
  withFetchBuilder<Hovedkrav, { hovedkrav: Hovedkrav }, T, WithRouteSakIdProps>(
    props => `/saker/${props.sakId}/hovedkrav`,
    response => ({ hovedkrav: response }),
    Component
  );

type MøterOgFristerResponse = {
  møter: Møte[];
  frister: Frist[];
};

export const withFetchMøterOgFrister = <T extends Record<string, unknown>>(
  Component: React.ComponentType<T>
) =>
  withFetchBuilder<
    MøterOgFristerResponse,
    { møter: Møte[]; frister: Frist[] },
    T,
    WithRouteSakIdProps
  >(
    props => `/saker/${props.sakId}/moeterogfrister`,
    response => ({ møter: response.møter, frister: response.frister }),
    Component
  );

export const withFetchParterOgAktører = <T extends Record<string, unknown>>(
  Component: React.ComponentType<T>
) =>
  withFetchBuilder<
    ParterOgAktørerDto,
    { parterOgAktører: ParterOgAktørerDto },
    T,
    WithRouteSakIdProps
  >(
    props => `/saker/${props.sakId}/aktoerer`,
    response => ({
      parterOgAktører: response,
    }),
    Component
  );

export const withFetchBrukervilkår = <P extends Record<string, unknown>>(
  Component: React.ComponentType<P>
) =>
  withFetchBuilder<Brukervilkår, { brukervilkår: Brukervilkår }, P>(
    '/brukervilkar',
    response => ({ brukervilkår: response }),
    Component
  );
