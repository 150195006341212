import React, { useState, useEffect } from 'react';
import { AnyAction } from 'redux';
import { AppDispatch, useAppDispatch } from 'src/store';
import { WithLoaderProps } from './withLoader';

const withDispatchAction =
  <P extends Record<string, unknown>>(actionBuilder: (props: P) => AnyAction) =>
  (Component: React.ComponentType<P & WithLoaderProps>): React.ComponentType<P & WithLoaderProps> =>
  props => {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const action = actionBuilder(props);

    useEffect(() => {
      const asyncAction = (dispatch: AppDispatch) => {
        dispatch(action);
        return Promise.resolve();
      };

      dispatch(d => asyncAction(d)).then(() => {
        setIsLoading(false);
      });
    }, []);

    return <Component {...props} isLoading={props.isLoading || isLoading} />;
  };

export default withDispatchAction;
