import React, { ReactNode } from 'react';
import Tooltip from 'rc-tooltip';
import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';
import { BodyType } from 'src/components/Typography';
import { Color } from 'src/constants';
import { TooltipProps as RCTooltipProps } from 'rc-tooltip/lib/Tooltip';

export const GlobalTooltipStyles = createGlobalStyle`
  .rc-tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    visibility: visible;
    padding: 1px;
  }

  .rc-tooltip-hidden {
    display: none;
  }

  .rc-tooltip-inner {
    padding: .5rem;
    text-align: left;
    text-decoration: none;
    background-color: white;
    min-height: 15px;
    box-shadow: none;
    border-radius: 0;
    border: 1px solid ${Color.GreyDark}
  }

  .rc-tooltip-placement-bottom {
    .rc-tooltip-arrow {
      top: -8px;
    }

    .rc-tooltip-content {
      position: relative;
      top: 5px;
    }
  }
  .rc-tooltip-placement-top {
    .rc-tooltip-arrow {
      bottom: 0px;
    }

    .rc-tooltip-content {
      position: relative;
      bottom: 5px;
    }
  }

  .rc-tooltip-arrow {
    position: absolute;
    left: calc(48%);

    .arrowUp,
    .arrowDown {
      position: absolute;
      display: inline-block;
    }
    .arrowUp {
      border-bottom: 8px solid ${Color.GreyDark};
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
    }
    .arrowUp.arrowUp-inner {
      top: 1px;
      border-bottom: 8px solid white;
    }
    .arrowDown {
      border-top: 8px solid ${Color.GreyDark};
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
    }
    .arrowDown.arrowDown-inner {
      top: -1px;
      border-top: 8px solid white;
    }
  }
`;

function makeArrowContent(placement: string) {
  return placement === 'bottom'
    ? [
        <div key="arrowUp" className="arrowUp" />,
        <div key="arrowUp-inner" className="arrowUp arrowUp-inner" />,
      ]
    : [
        <div key="arrowDown" className="arrowDown" />,
        <div key="arrowDown-inner" className="arrowDown arrowDown-inner" />,
      ];
}

const TooltipContentWrapper = styled(BodyType)`
  && {
    color: ${Color.GreyDark};
  }
`;

type TooltipProps = {
  text: string;
  disabled: boolean;
  children: ReactNode;
} & Omit<RCTooltipProps, 'overlay' | 'overlayStyle' | 'arrowContent'>;

export default ({
  children,
  text,
  disabled,
  placement = 'bottom',
  destroyTooltipOnHide = true,
  ...rest
}: TooltipProps) => {
  return (
    <>
      <GlobalTooltipStyles />
      <Tooltip
        placement={placement}
        destroyTooltipOnHide={destroyTooltipOnHide}
        {...rest}
        arrowContent={makeArrowContent('bottom')}
        overlay={() => <TooltipContentWrapper>{text}</TooltipContentWrapper>}
        overlayStyle={{ display: disabled ? 'none' : 'block' }}
      >
        {children}
      </Tooltip>
    </>
  );
};
