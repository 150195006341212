import React from 'react';
import { Field } from 'react-final-form';
import useTranslate from 'src/hooks/useTranslate';
import Input from './Input';

const norwegianCharactersRegex = /[æøå]/i;
// https://fightingforalostcause.net/content/misc/2006/compare-email-regex.php
const emailValidationRegex =
  /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/i;

type FinalFormEmailProps = {
  name: string;
  label: string;
  [x: string]: unknown;
};

export default function FinalFormEmail({ name, label, ...rest }: FinalFormEmailProps) {
  const translate = useTranslate();

  const validate = (email: string) => {
    if (norwegianCharactersRegex.test(email)) {
      return translate('profil.validering-epost-norske-tegn');
    }
    if (!emailValidationRegex.test(email)) {
      return translate('profil.validering-epost-format');
    }
  };

  return (
    <Field
      name={name}
      validate={validate}
      render={({ input, meta }) => (
        <Input
          label={label}
          error={(meta.touched && meta.error) || ''}
          type="email"
          {...input}
          {...rest}
        />
      )}
    />
  );
}
