import React from 'react';
import styled from 'styled-components';
import { BodyType, H3 } from 'src/components/Typography';
import EmailSharp from '@material-ui/icons/EmailSharp';
import LocationOnSharp from '@material-ui/icons/LocationOnSharp';
import PhoneSharp from '@material-ui/icons/PhoneSharp';
import { Color } from 'src/constants';
import useTranslate from 'src/hooks/useTranslate';
import withRouteSakId from 'src/hoc/withRouteSakId';
import { withFetchHovedkrav } from 'src/hoc/withFetch';
import withLoader from 'src/hoc/withLoader';
import { Sak } from 'src/domain/sak';
import { Hovedkrav } from 'src/domain/hovedkrav';

const Container = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 660px) {
    flex-direction: column;
  }
`;

const GreyBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2 0;
  margin-right: 32px;
  background-color: ${Color.GreyLight};
  border-top: solid 2px ${Color.GreyDark};
  padding: 48px 32px;

  h3 {
    margin-bottom: 8px;
  }

  @media (max-width: 660px) {
    flex: 1;
    margin-right: 0px;
    margin-bottom: 32px;
  }
`;

const WhiteBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0;
  padding: 48px 32px;
  border: solid 1px ${Color.GreyDark};
  border-top: solid 2px ${Color.GreyDark};

  h3 {
    margin-bottom: 8px;
  }

  @media (max-width: 660px) {
    flex: 1;
  }
`;

const HR = styled.hr`
  color: ${Color.GreyNormal};
  width: 100%;
  margin: 32px 0px;
`;

const ContactEntry = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: min-content min-content;
  gap: 16px;
  align-items: center;

  svg {
    grid-column: 1;
    grid-row: 1;
    color: ${Color.GreyNormal};
  }

  div {
    grid-column: 2;

    a {
      color: #1e66b0;
      font-size: 16px;
      line-height: 20px;
      text-decoration: none;
    }
  }
`;

type OmSakenProps = {
  sak: Sak;
  hovedkrav: Hovedkrav[];
};

const OmSaken = ({ sak, hovedkrav }: OmSakenProps) => {
  const translate = useTranslate();

  return (
    <Container>
      <GreyBlock>
        <H3 noMargin>{translate('om-saken.type')}</H3>
        <BodyType noMargin>{sak.sakstype.beskrivelse}</BodyType>
        <HR />
        <H3 noMargin>{translate('om-saken.saken-gjelder')}</H3>
        <BodyType noMargin>
          {hovedkrav.map((krav, index) => (
            <div key={index}>{krav.kravetGjelder ? krav.kravetGjelder : krav.kravType}</div>
          ))}
        </BodyType>
        <HR />
        <H3 noMargin>{translate('om-saken.status')}</H3>
        <BodyType noMargin>{sak.status.beskrivelse}</BodyType>
      </GreyBlock>
      <WhiteBlock>
        <H3 noMargin>{translate('om-saken.domstol')}</H3>
        <BodyType noMargin>{sak.domstol.navn}</BodyType>
        <HR />
        <ContactEntry>
          <PhoneSharp />
          <div>
            <a href={`tel:${sak.domstol.telefon}`}>{sak.domstol.telefon}</a>
          </div>
        </ContactEntry>
        <ContactEntry>
          <EmailSharp />
          <div>
            <a href={`mailto:${sak.domstol.epost}`}>{sak.domstol.epost}</a>
          </div>
        </ContactEntry>
        <ContactEntry>
          <LocationOnSharp />
          {sak.domstol.adresser.map((x, i) => (
            <div key={i}>
              <a
                href={'http://maps.google.com/?q=' + encodeURIComponent(x)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {x}
              </a>
            </div>
          ))}
        </ContactEntry>
      </WhiteBlock>
    </Container>
  );
};

export default withRouteSakId(
  withFetchHovedkrav(withLoader('om-saken.hent-saksinfo', 'om-saken.hent-saksinfo-feilet')(OmSaken))
);
