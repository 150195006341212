import React, { useState } from 'react';
import newId from 'src/utils/newId';

type LabelProps = {
  htmlFor: string;
};

type InputProps = {
  id: string;
  'aria-invalid': boolean;
  'aria-label'?: string;
  [x: string]: unknown;
};

type ErrorProps = {
  'aria-live': 'polite';
};

type AccessibilityInputProps = {
  id?: string;
  label: string;
  error: string;
  renderLabel?: (props: LabelProps, label: string) => JSX.Element;
  renderInput: (props: InputProps) => JSX.Element;
  renderError: (props: ErrorProps, error: string) => JSX.Element;
  [x: string]: unknown;
};

export default function AccessibilityInput({
  id,
  label,
  error,
  renderLabel,
  renderInput,
  renderError,
  ...rest
}: AccessibilityInputProps) {
  const [inputId] = useState(id ?? newId);

  const hasVisibleLabel = renderLabel !== undefined;
  const hasError = error !== '';

  const labelProps: LabelProps = {
    htmlFor: inputId,
  };

  const inputProps: InputProps = {
    ...rest,
    id: inputId,
    'aria-invalid': hasError,
    'aria-label': hasVisibleLabel ? undefined : label,
  };

  const errorProps: ErrorProps = {
    'aria-live': 'polite',
  };

  // Feil-komponenten må alltid rendres for at aria-live skal fungere
  return (
    <>
      {hasVisibleLabel && renderLabel(labelProps, label)}
      {renderInput(inputProps)}
      {renderError(errorProps, error)}
    </>
  );
}
