import React from 'react';
import styled from 'styled-components';
import AccessibilityInput from './AccessibilityInput';
import { LabelType, ErrorType } from 'src/components/Typography';
import { Color } from 'src/constants';

type InputProps = {
  label: string;
  error: string;
  [x: string]: unknown;
};

const StyledInput = styled.input`
  width: 100%;
  height: 40px;
  background-color: rgba(45, 45, 45, 0.06);
  border: none;
  border-bottom: solid 1px ${Color.GreyDark};
  color: ${Color.GreyDark};
  font-family: 'Proxima Nova';
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 8px;
  margin: 0;
  border-radius: 0;
  transition: border-bottom 150ms;

  :hover {
    border-bottom: solid 1px ${Color.Red};
  }

  :focus {
    border-bottom: solid 1px ${Color.GreyDark};
  }
`;

type ContainerProps = {
  hasError: boolean;
};

const Container = styled.div<ContainerProps>`
  margin: 8px 0;

  ${LabelType} {
    display: block;
    margin-bottom: 8px;
  }

  ${ErrorType} {
    display: block;
    margin-top: ${props => (props.hasError ? '8px' : '0')};
  }
`;

export default function Input({ label, error, style, ...rest }: InputProps) {
  return (
    <Container style={style} hasError={error !== ''}>
      <AccessibilityInput
        label={label}
        error={error}
        renderLabel={(props, label) => <LabelType {...props}>{label}</LabelType>}
        renderInput={props => <StyledInput {...props} />}
        renderError={(props, error) => <ErrorType {...props}>{error}</ErrorType>}
        {...rest}
      />
    </Container>
  );
}
