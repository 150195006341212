import styled, { css } from 'styled-components';
import { Color } from 'src/constants';

type TypographyProps = {
  noMargin?: boolean;
  truncate?: boolean;
};

const noMargin = (props: TypographyProps) => props.noMargin && 'margin: 0;';

const truncate = (props: TypographyProps) =>
  props.truncate &&
  `
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const common = css`
  color: ${Color.GreyDark};
  ${noMargin}
  ${truncate}
`;

export const H1 = styled.h1<TypographyProps>`
  ${common}
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 2.75rem;
  letter-spacing: -0.02px;
`;

export const H2 = styled.h2<TypographyProps>`
  ${common}
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.25rem;
  letter-spacing: -0.02px;
`;

export const H3 = styled.h3<TypographyProps>`
  ${common}
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.875rem;
`;

export const H4 = styled.h4<TypographyProps>`
  ${common}
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
`;

export const BodyType = styled.span<TypographyProps>`
  ${common}
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
`;

export const RegularType = styled.span<TypographyProps>`
  ${common}
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5rem;
`;
export const InfoType = styled.span<TypographyProps>`
  ${common}
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.125rem;
`;

export const ErrorType = styled(InfoType)`
  color: ${Color.Red};
`;

export const ConfirmationType = styled(InfoType)`
  color: ${Color.Blue};
`;

export const LeadType = styled.span<TypographyProps>`
  ${common}
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.5rem;
  letter-spacing: -0.02px;
`;

export const LabelType = styled.label<TypographyProps>`
  ${common}
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.2px;
`;
