import styled, { css } from 'styled-components';
import { Color } from 'src/constants';
import Collapse from '@material-ui/core/Collapse';

const flexCentered = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const paddingLeftRight = css`
  padding-left: 32px;
  padding-right: 32px;

  @media (max-width: 660px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const GridContainer = styled.div`
  background-color: ${Color.GreyLight};
  border-bottom: solid 2px ${Color.GreyDark};
  display: grid;
  grid-template-columns: 1fr 6fr auto;
  grid-template-rows: repeat(3, min-content);
  grid-template-areas:
    'status header  button'
    '.      content .';

  @media (max-width: 660px) {
    grid-template-columns: 1fr min-content;
    grid-template-areas:
      'header  button'
      'content content';
  }
`;

export const WarningContainer = styled.div`
  ${flexCentered}
  background-color: ${Color.Red};
  padding: 20px;

  > span {
    margin-left: 12px;
  }

  > * {
    color: ${Color.White};
  }
`;

export const StatusContainer = styled.div`
  ${flexCentered}
  flex-direction: column;
  grid-area: status;
  margin: 20px 0;
  padding: 0 10px;
  text-align: center;
  border-right: solid 1px ${Color.GreyDark};

  > svg {
    margin-bottom: 2px;
  }

  @media (max-width: 660px) {
    display: none;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-area: header;
  padding-top: 20px;
  padding-bottom: 20px;
  ${paddingLeftRight}
  cursor: pointer;

  * {
    cursor: pointer;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  grid-area: button;
  cursor: pointer;
  padding-right: 16px;
`;

export const ContentContainer = styled.div`
  ${paddingLeftRight}
`;

export const StyledCollapse = styled(Collapse)`
  grid-area: content;
`;
