import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { H2, LeadType } from 'src/components/Typography';
import { TertiaryButton } from 'src/components/Buttons';
import ActionBar from 'src/components/ActionBar';
import { withInnloggetBrukerPage } from 'src/hoc/withPage';
import useTranslate from 'src/hooks/useTranslate';

const TextContainer = styled.div`
  max-width: 536px;
  width: 100%;
  margin-bottom: 80px;
`;

function AvslåBrukervilkår() {
  const history = useHistory();
  const translate = useTranslate();

  function toBrukervilkår() {
    history.push('/brukervilkar');
  }

  return (
    <>
      <TextContainer>
        <H2>{translate('brukervilkår.avslå')}</H2>
        <LeadType noMargin>{translate('brukervilkår.avslå-beskrivelse')}</LeadType>
      </TextContainer>
      <ActionBar>
        <TertiaryButton onClick={toBrukervilkår}>
          {translate('brukervilkår.tilbake')}
        </TertiaryButton>
      </ActionBar>
    </>
  );
}

export default withInnloggetBrukerPage(AvslåBrukervilkår);
