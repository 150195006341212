import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { format } from './stringUtils';

import { Språk } from 'src/constants';

import nbTranslations from './nb.json';
import nnTranslations from './nn.json';
import seTranslations from './se.json';
import { selectBruker } from 'src/store/selectors/brukerSelectors';

const translations = {
  [Språk.Bokmål]: nbTranslations,
  [Språk.Nynorsk]: nnTranslations,
  [Språk.Samisk]: seTranslations,
};

export type I18nContextProps = {
  translate: (key: string) => string;
};

export const I18nContext = React.createContext<I18nContextProps>({
  translate: () => '',
});

function getTranslation(languageCode: Språk, key: string, ...args: string[]) {
  const propertyKeys = key.split('.');
  const translationsForLanguageCode = translations[languageCode];

  const translation = propertyKeys.reduce(
    (acc: string | Record<string, unknown>, cur: string) =>
      typeof acc === 'object' ? acc[cur] : acc,
    translationsForLanguageCode
  );

  return typeof translation === 'string' ? format(translation, ...args) : null;
}

export const I18nProvider = ({ children }: { children: React.ReactNode }) => {
  const bruker = useSelector(selectBruker);
  const språk = (bruker && bruker.valgtSpråk) || Språk.Bokmål;

  const translate = useCallback(
    (key: string, ...args: string[]) => {
      const translation =
        språk === Språk.Bokmål
          ? getTranslation(språk, key, ...args)
          : getTranslation(språk, key, ...args) || getTranslation(Språk.Bokmål, key, ...args);

      if (!translation) {
        console.warn('Mangler verdi for oversettelsesnøkkel ', key);
      }
      return translation || key;
    },
    [språk]
  );

  return <I18nContext.Provider value={{ translate }}>{children}</I18nContext.Provider>;
};

export function getTranslationKeyForDay(date: Date) {
  switch (date.getDay()) {
    case 0:
      return 'dag.søndag';
    case 1:
      return 'dag.mandag';
    case 2:
      return 'dag.tirsdag';
    case 3:
      return 'dag.onsdag';
    case 4:
      return 'dag.torsdag';
    case 5:
      return 'dag.fredag';
    case 6:
      return 'dag.lørdag';
  }
}

export function getTranslationKeyForMonth(date: Date) {
  switch (date.getMonth()) {
    case 0:
      return 'måned.januar';
    case 1:
      return 'måned.februar';
    case 2:
      return 'måned.mars';
    case 3:
      return 'måned.april';
    case 4:
      return 'måned.mai';
    case 5:
      return 'måned.juni';
    case 6:
      return 'måned.juli';
    case 7:
      return 'måned.august';
    case 8:
      return 'måned.september';
    case 9:
      return 'måned.oktober';
    case 10:
      return 'måned.november';
    case 11:
      return 'måned.desember';
  }
}
