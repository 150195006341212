import { InfoMessage } from 'src/components/Messages';
import { H4 } from 'src/components/Typography';
import { ParterOgAktørerDto } from 'src/domain/aktor';
import { Sak } from 'src/domain/sak';
import { withFetchParterOgAktører } from 'src/hoc/withFetch';
import withLoader from 'src/hoc/withLoader';
import withRouteSakId from 'src/hoc/withRouteSakId';
import useTranslate from 'src/hooks/useTranslate';
import styled from 'styled-components';
import { AktørerTabell, ParterMedRepresentanterTabell } from './components/ParterOgAktørerTabell';

type ParterOgAktorerProps = {
  sak: Sak;
  parterOgAktører: ParterOgAktørerDto;
};

const StyledH4 = styled(H4)`
  margin: 0.5rem 0;
`;

export const ParterOgAktører = ({ sak, parterOgAktører }: ParterOgAktorerProps) => {
  const translate = useTranslate();

  if (!parterOgAktører) {
    return <InfoMessage title={translate('henting-aktører-ingen-treff')} />;
  }

  const {
    påtale,
    tiltalte,
    siktede,
    fornærmede,
    etterlatte,
    saksøkere,
    saksøkte,
    ankendeParter,
    ankemotparter,
    andreParter,
    domstolTilsatte,
    andreAktører,
  } = parterOgAktører;

  const tiltalteOgSiktede = tiltalte.concat(siktede);
  const tiltalteOgSiktedeHeader =
    tiltalte.length > 0 && siktede.length > 0
      ? 'aktørliste.tiltalte-og-siktede'
      : tiltalte.length > 0
      ? 'aktørliste.tiltalte'
      : 'aktørliste.siktede';

  return (
    <>
      {påtale.length > 0 && (
        <>
          <StyledH4>{translate('aktørliste.påtalemyndighet')}</StyledH4>
          <ParterMedRepresentanterTabell sak={sak} parter={påtale} />
        </>
      )}

      {tiltalteOgSiktede.length > 0 && (
        <>
          <StyledH4>{translate(tiltalteOgSiktedeHeader)}</StyledH4>
          <ParterMedRepresentanterTabell sak={sak} parter={tiltalteOgSiktede} />
        </>
      )}

      {fornærmede.length > 0 && (
        <>
          <StyledH4>{translate('aktørliste.fornærmede')}</StyledH4>
          <ParterMedRepresentanterTabell sak={sak} parter={fornærmede} />
        </>
      )}

      {etterlatte.length > 0 && (
        <>
          <StyledH4>{translate('aktørliste.etterlatte')}</StyledH4>
          <ParterMedRepresentanterTabell sak={sak} parter={etterlatte} />
        </>
      )}

      {saksøkere.length > 0 && (
        <>
          <StyledH4>{translate('aktørliste.saksøkere')}</StyledH4>
          <ParterMedRepresentanterTabell sak={sak} parter={saksøkere} />
        </>
      )}

      {saksøkte.length > 0 && (
        <>
          <StyledH4>{translate('aktørliste.saksøkte')}</StyledH4>
          <ParterMedRepresentanterTabell sak={sak} parter={saksøkte} />
        </>
      )}

      {ankendeParter.length > 0 && (
        <>
          <StyledH4>{translate('aktørliste.ankende-part')}</StyledH4>
          <ParterMedRepresentanterTabell sak={sak} parter={ankendeParter} />
        </>
      )}

      {ankemotparter.length > 0 && (
        <>
          <StyledH4>{translate('aktørliste.ankende-motparter')}</StyledH4>
          <ParterMedRepresentanterTabell sak={sak} parter={ankemotparter} />
        </>
      )}

      {andreParter.length > 0 && (
        <>
          <StyledH4>{translate('aktørliste.andre-parter')}</StyledH4>
          <ParterMedRepresentanterTabell sak={sak} parter={andreParter} />
        </>
      )}

      {domstolTilsatte.length > 0 && (
        <>
          <StyledH4>{translate('aktørliste.domstol')}</StyledH4>
          <AktørerTabell sak={sak} aktører={domstolTilsatte} />
        </>
      )}

      {andreAktører.length > 0 && (
        <>
          <StyledH4>{translate('aktørliste.andre-aktører')}</StyledH4>
          <AktørerTabell sak={sak} aktører={andreAktører} />
        </>
      )}
    </>
  );
};

export default withRouteSakId(
  withFetchParterOgAktører(withLoader('henter-aktører', 'henting-aktører-feilet')(ParterOgAktører))
);
