import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import ArrowBackSharp from '@material-ui/icons/ArrowBackSharp';
import DateRangeSharp from '@material-ui/icons/DateRangeSharp';
import DescriptionSharp from '@material-ui/icons/DescriptionSharp';
import InfoSharp from '@material-ui/icons/InfoSharp';
import ListSharp from '@material-ui/icons/ListSharp';
import PersonSharp from '@material-ui/icons/PersonSharp';

import MineOppgaver from 'src/pages/Sak/components/MineOppgaver';
import { ParterOgAktørerPage } from 'src/pages/Sak/components/Aktorer';
import MøterOgFrister from 'src/pages/Sak/components/MøterOgFrister';
import OmSaken from 'src/pages/Sak/components/OmSaken';
import SakDropdown from 'src/components/SakDropdown';
import SakFaner from 'src/components/SakFaner';
import Saksdokumenter from 'src/pages/Sak/components/Saksdokumenter';

import { H1, LeadType } from 'src/components/Typography';
import { NavigationButton } from 'src/components/Buttons';
import useTranslate from 'src/hooks/useTranslate';

import { withAkseptertBrukervilkårPage } from 'src/hoc/withPage';
import withRouteSakId from 'src/hoc/withRouteSakId';
import withLoader from 'src/hoc/withLoader';
import { SakTab } from 'src/components/SakFaner/SakFaner';
import { withSak } from 'src/hoc/withSaker';
import { Sak } from 'src/domain/sak';

const SakContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SakHeader = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;

  & > h1 {
    margin-bottom: 8px;
  }
`;

type SakProps = {
  sak: Sak;
};

const SakComponent = ({ sak }: SakProps) => {
  const history = useHistory();
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const translate = useTranslate();

  useEffect(() => {
    const setInnerWidthFunc = () => {
      setInnerWidth(window.innerWidth);
    };

    window.addEventListener('resize', setInnerWidthFunc);

    return () => {
      window.removeEventListener('resize', setInnerWidthFunc);
    };
  }, []);

  const tabContent: SakTab[] = [
    {
      index: 1,
      title: translate('sakside.mine-oppgaver'),
      url: 'mine-oppgaver',
      icon: <ListSharp />,
      ariaLabel: translate('sakside.mine-oppgaver'),
      component: () => <MineOppgaver sak={sak} />,
    },
    {
      index: 2,
      title: translate('sakside.saksdokumenter'),
      url: 'dokumenter',
      icon: <DescriptionSharp />,
      ariaLabel: translate('sakside.saksdokumenter'),
      component: () => <Saksdokumenter sak={sak} />,
    },
    {
      index: 3,
      title: translate('sakside.om-saken'),
      url: 'om-saken',
      icon: <InfoSharp />,
      ariaLabel: translate('sakside.om-saken'),
      component: () => <OmSaken sak={sak} />,
    },
    {
      index: 4,
      title: translate('sakside.aktører'),
      url: 'aktorer',
      icon: <PersonSharp />,
      ariaLabel: translate('sakside.aktører'),
      component: () => <ParterOgAktørerPage sak={sak} />,
    },
    {
      index: 5,
      title: translate('sakside.møter-og-frister'),
      url: 'moter-og-frister',
      icon: <DateRangeSharp />,
      ariaLabel: translate('sakside.møter-og-frister'),
      component: () => <MøterOgFrister />,
    },
  ];

  const { sakstema, saksnummer } = sak;
  return (
    <SakContainer>
      <NavigationButton
        aria-label={translate('sakside.alle-saker')}
        onClick={() => history.push('/')}
        flexStart
        noPadding
      >
        <ArrowBackSharp />
        {translate('sakside.alle-saker')}
      </NavigationButton>
      <SakHeader>
        <H1 noMargin>{sakstema}</H1>
        <LeadType noMargin>{saksnummer}</LeadType>
      </SakHeader>
      {innerWidth > 660 ? (
        <SakFaner tabContent={tabContent} />
      ) : (
        <SakDropdown tabContent={tabContent} />
      )}
    </SakContainer>
  );
};

export default withAkseptertBrukervilkårPage(
  withRouteSakId(withSak(withLoader('sakside.hent-sak', 'sakside.hent-sak-feilet')(SakComponent)))
);
