import styled from 'styled-components';
import { Color } from 'src/constants';
import media from 'src/media';

export const GrayContainer = styled.div`
  border-top: solid 2px ${Color.GreyDark};
  background-color: ${Color.GreyLight};
  padding: 62px 88px 62px 88px;
  margin-top: 40px;

  form {
    max-width: 500px;

    > *:last-child {
      margin-bottom: 0;
    }
  }

  ${media.s`
    padding: 32px;
  `}
`;

export const ResponsiveContainer = styled.div`
  max-width: 400px;
  display: flex;

  > *:not(:first-child) {
    margin-left: 16px;
  }

  ${media.xs`
    max-width: none;  
    flex-direction: column;

    > *:not(:first-child) {
      margin-left: 0;
    }
  `}
`;
