import React from 'react';
import Status from './Status';
import InfoSharp from '@material-ui/icons/InfoSharp';
import { H4, InfoType, LabelType } from 'src/components/Typography';
import CollapseButton from './CollapseButton';
import {
  GridContainer,
  WarningContainer,
  StatusContainer,
  HeaderContainer,
  ButtonContainer,
  ContentContainer,
  StyledCollapse,
} from './Oppgave.style';
import { OppgaveStatus } from 'src/domain/enums';

type OppgaveProps = {
  tittel: string;
  beskrivelse: string;
  oppgaveStatus: OppgaveStatus;
  frist: Date;
  open: boolean;
  advarsel?: string;
  onClick: () => void;
  children: React.ReactNode;
  [x: string]: unknown;
};

export default function Oppgave({
  tittel,
  beskrivelse,
  advarsel,
  open,
  onClick,
  children,
  oppgaveStatus,
  frist,
  ...props
}: OppgaveProps) {
  return (
    <div>
      {advarsel && (
        <WarningContainer>
          <InfoSharp />
          <InfoType>{advarsel}</InfoType>
        </WarningContainer>
      )}
      <GridContainer {...props}>
        <StatusContainer>
          <Status oppgaveStatus={oppgaveStatus} frist={frist} />
        </StatusContainer>
        <HeaderContainer onClick={onClick}>
          <H4 noMargin>{tittel}</H4>
          <LabelType>{beskrivelse}</LabelType>
        </HeaderContainer>
        {React.Children.count(children) !== 0 && (
          <ButtonContainer onClick={onClick}>
            <CollapseButton open={open} />
          </ButtonContainer>
        )}
        <StyledCollapse in={open} aria-hidden={open}>
          <ContentContainer>{children}</ContentContainer>
        </StyledCollapse>
      </GridContainer>
    </div>
  );
}
