import useTranslate from 'src/hooks/useTranslate';
import { Li, MarginContainer, Ul } from '../Oppgaver.style';
import { InteractiveAlert } from 'src/components/Alert/AlertBox';
import { Sak } from 'src/domain/sak';
import { AnchorHTMLAttributes } from 'react';
import styled from 'styled-components';
import { H4 } from 'src/components/Typography/Typography';
import { Color } from 'src/constants';

type LesSaksdokumenterProps = {
  sak: Sak;
};

const A = styled(H4).attrs({ as: 'a' })<AnchorHTMLAttributes<HTMLAnchorElement>>`
  color: inherit;
  text-decoration: none;
  white-space: nowrap;
  text-decoration: underline;
`;

const Header = styled.h4`
  color: ${Color.GreyDark};
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
  margin: 0;
`;

function setHeader(sak: Sak) {
  const translate = useTranslate();

  if (
    sak.rolle.kode.toString() === 'FagkyndigMeddommer' ||
    sak.rolle.kode.toString() === 'ArbeidslivskyndigMeddommer' ||
    sak.rolle.kode.toString() === 'Skjonnsmedlem'
  ) {
    return (
      <Header>
        {translate('mine-oppgaver.les-saksdokumenter.fagkyndig-sivil.tittel-del-1')}
        <A href={`/saker/${sak.sakId}/dokumenter`}>{'saksdokumenter'}</A>
        {translate('mine-oppgaver.les-saksdokumenter.fagkyndig-sivil.tittel-del-2')}
      </Header>
    );
  } else {
    return (
      <Header>
        {translate('mine-oppgaver.les-saksdokumenter.tittel-del-1')}
        <A href={`/saker/${sak.sakId}/dokumenter`}>{'saksdokumenter'}</A>
        {translate('mine-oppgaver.les-saksdokumenter.tittel-del-2')}
      </Header>
    );
  }
}

export default function LesSaksdokumenter({ sak }: LesSaksdokumenterProps) {
  const translate = useTranslate();

  return (
    <MarginContainer>
      <InteractiveAlert iconText={setHeader(sak)}>
        <Ul>
          <Li>{translate('mine-oppgaver.les-saksdokumenter.list-1')}</Li>
          {sak.rolle.kode.toString() === 'FagkyndigMeddommer' ||
          sak.rolle.kode.toString() === 'ArbeidslivskyndigMeddommer' ||
          sak.rolle.kode.toString() === 'Skjonnsmedlem' ? (
            <></>
          ) : (
            <Li>{translate('mine-oppgaver.les-saksdokumenter.list-2')}</Li>
          )}
          <Li>{translate('mine-oppgaver.les-saksdokumenter.list-3')}</Li>
        </Ul>
      </InteractiveAlert>
    </MarginContainer>
  );
}
