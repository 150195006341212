import React from 'react';
import styled from 'styled-components';
import SakslisteElement from './SakslisteElement';
import { Color } from 'src/constants';
import { Sak } from 'src/domain/sak';

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  > li {
    border-bottom: 1px solid ${Color.GreyNormal};
  }
`;

type SakslisteProps = {
  saker: Sak[];
};

const Saksliste = ({ saker }: SakslisteProps) => {
  return (
    <List>
      {saker.map(sak => (
        <SakslisteElement key={sak.sakId} sak={sak} />
      ))}
    </List>
  );
};

export default Saksliste;
