import styled from 'styled-components';
import { InfoMessage } from 'src/components/Messages';
import useTranslate from 'src/hooks/useTranslate';
import { oppgaverActions } from 'src/store/oppgaverStore';
import { VurderHabilitet, BekreftOppmøte, LesSaksdokumenter } from './Oppgaver';
import withRouteSakId from 'src/hoc/withRouteSakId';
import { withFetchHovedkrav, withFetchParterOgAktører } from 'src/hoc/withFetch';
import withHabilitetStatusForSak from 'src/hoc/withHabilitetStatusForSak';
import withLoader from 'src/hoc/withLoader';
import withDispatchAction from 'src/hoc/withDispatchAction';
import { Sak } from 'src/domain/sak';
import { ParterOgAktørerDto } from 'src/domain/aktor';

const MarginContainer = styled.div`
  > * {
    margin: 2rem 0;
  }
`;

type MineOppgaverProps = {
  sak: Sak;
  parterOgAktører: ParterOgAktørerDto;
};

function MineOppgaver({ sak, parterOgAktører }: MineOppgaverProps) {
  const translate = useTranslate();

  if (sak.innkalling == null) {
    return <InfoMessage title={translate('mine-oppgaver.ingen-oppgaver')} />;
  }

  const isVaraMeddommerUtenOppmoeteplikt = () => {
    return (
      sak.rolle.kode.toString() === ('VaraMeddommer' || 'VaraSkjonnsmedlem') &&
      !sak.innkalling?.oppmøteplikt
    );
  };

  return (
    <MarginContainer>
      <VurderHabilitet sak={sak} parterOgAktører={parterOgAktører} />
      <BekreftOppmøte sak={sak} />
      {isVaraMeddommerUtenOppmoeteplikt() ? <></> : <LesSaksdokumenter sak={sak} />}
    </MarginContainer>
  );
}

export default withRouteSakId(
  withFetchHovedkrav(
    withFetchParterOgAktører(
      withHabilitetStatusForSak(
        withDispatchAction<MineOppgaverProps>(props => oppgaverActions.setSak(props.sak))(
          withLoader(
            'mine-oppgaver.hent-oppgaver',
            'mine-oppgaver.hent-oppgaver-feilet'
          )(MineOppgaver)
        )
      )
    )
  )
);
