import React from 'react';
import styled from 'styled-components';
import useTranslate from 'src/hooks/useTranslate';
import { Color } from 'src/constants';

// Artikler om å gjemme DOM-elementer:
// https://allyjs.io/tutorials/hiding-elements.html#how-to-hide-elements-visually
// https://snook.ca/archives/html_and_css/hiding-content-for-accessibility

const HiddenLink = styled.a`
  position: absolute;
  top: 4px;
  left: 4px;
  font-size: 20px;
  color: ${Color.Blue};
  white-space: nowrap;

  :not(:focus) {
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    clip: rect(0 0 0 0);
    overflow: hidden;
  }
`;

export default function SkipToContent() {
  const translate = useTranslate();
  return <HiddenLink href="#content">{translate('hopp-til-hovedinnhold')}</HiddenLink>;
}
