import useTranslate from 'src/hooks/useTranslate';
import { FritakType } from 'src/domain/enums';

type FritakOption = {
  label: string;
  value: FritakType;
  documentation: string;
};

function getFritakOptions(): FritakOption[] {
  const translate = useTranslate();

  return [
    {
      label: translate('søknad.fritak.dropdown.sykdom'),
      value: FritakType.Sykdom,
      documentation: translate('søknad.fritak.dropdown.sykdom-dok'),
    },
    {
      label: translate('søknad.fritak.dropdown.reise'),
      value: FritakType.Reise,
      documentation: translate('søknad.fritak.dropdown.reise-dok'),
    },
    {
      label: translate('søknad.fritak.dropdown.studie'),
      value: FritakType.Studie,
      documentation: translate('søknad.fritak.dropdown.studie-dok'),
    },
    {
      label: translate('søknad.fritak.dropdown.arbeidssituasjon'),
      value: FritakType.Arbeidssituasjon,
      documentation: translate('søknad.fritak.dropdown.arbeidssituasjon-dok'),
    },
    {
      label: translate('søknad.fritak.dropdown.fødselspermisjon'),
      value: FritakType.Fødselspermisjon,
      documentation: translate('søknad.fritak.dropdown.fødselspermisjon-dok'),
    },
    {
      label: translate('søknad.fritak.dropdown.militærtjeneste'),
      value: FritakType.Militærtjeneste,
      documentation: translate('søknad.fritak.dropdown.militærtjeneste-dok'),
    },
    {
      label: translate('søknad.fritak.dropdown.fylkestinget'),
      value: FritakType.Fylkestinget,
      documentation: translate('søknad.fritak.dropdown.fylkestinget-dok'),
    },
    {
      label: translate('søknad.fritak.dropdown.høyesterett'),
      value: FritakType.Høyesterett,
      documentation: translate('søknad.fritak.dropdown.høyesterett-dok'),
    },
    {
      label: translate('søknad.fritak.dropdown.annet'),
      value: FritakType.Annet,
      documentation: translate('søknad.fritak.dropdown.annet-dok'),
    },
  ];
}

export { getFritakOptions };
export type { FritakOption };
