import useTranslate from 'src/hooks/useTranslate';
import useShowErrorMessage from 'src/hooks/useShowErrorMessage';
import React, { ReactElement, useEffect } from 'react';
import { PDFToolbarContainer, ToolbarItem } from './PDFViewerToolbar';
import './style.css';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin, ToolbarProps, ToolbarSlot } from '@react-pdf-viewer/default-layout';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

type ReactPDFViewerProps = {
  pdfUrl: string;
  kanPrinteUtOgLasteNedDokumenter: boolean;
};

const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
  <Toolbar>
    {(slots: ToolbarSlot) => {
      const { EnterFullScreen, Zoom, ZoomIn, ZoomOut } = slots;
      return (
        <PDFToolbarContainer>
          <ToolbarItem position="left">
            <ZoomOut />
          </ToolbarItem>
          <ToolbarItem>
            <Zoom />
          </ToolbarItem>
          <ToolbarItem position="right">
            <ZoomIn />
          </ToolbarItem>
          <ToolbarItem>
            <EnterFullScreen />
          </ToolbarItem>
        </PDFToolbarContainer>
      );
    }}
  </Toolbar>
);

export default ({ pdfUrl, kanPrinteUtOgLasteNedDokumenter }: ReactPDFViewerProps) => {
  const workerUrl = `/scripts/pdf.worker.min.js`;
  const translate = useTranslate();
  const showErrorMessage = useShowErrorMessage();

  const defaultLayoutPluginInstanse = defaultLayoutPlugin();
  const hideDownloadPrintAndSaveButton = defaultLayoutPlugin({
    renderToolbar,
    toolbarPlugin: {
      printPlugin: {
        enableShortcuts: false,
      },
      openPlugin: {
        enableShortcuts: false,
      },
    },
  });

  useEffect(() => {
    function disablePrintAndSaveShortcutsEventListener(e: KeyboardEvent) {
      const key = e.key?.toLowerCase();

      if ((e.ctrlKey || e.metaKey) && (key === 'p' || key === 's')) {
        e.preventDefault();
        e.stopImmediatePropagation();
        showErrorMessage('saksdokumenter.dokument.print-error');
      }
    }

    function displayErrorMessageForPrint(document: Document) {
      const div = document.createElement('div');
      div.id = 'printErrorMessage';
      div.innerHTML = `<h1>${translate('saksdokumenter.dokument.print-error')}</h1>`;
      document.body.appendChild(div);
    }

    if (!kanPrinteUtOgLasteNedDokumenter) {
      document.body.addEventListener('keydown', disablePrintAndSaveShortcutsEventListener);
      document.body.addEventListener('keyup', disablePrintAndSaveShortcutsEventListener);

      displayErrorMessageForPrint(document);
      displayErrorMessageForPrint(window.document);
    }

    return () => {
      document.body.removeEventListener('keydown', disablePrintAndSaveShortcutsEventListener);
      document.body.removeEventListener('keyup', disablePrintAndSaveShortcutsEventListener);
    };
  }, []);

  return (
    <Worker workerUrl={workerUrl}>
      <Viewer
        fileUrl={pdfUrl}
        plugins={
          kanPrinteUtOgLasteNedDokumenter
            ? [defaultLayoutPluginInstanse]
            : [hideDownloadPrintAndSaveButton]
        }
      />
    </Worker>
  );
};
