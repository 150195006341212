import React from 'react';
import styled from 'styled-components';
import { LabelType } from 'src/components/Typography';

type LabelProps = {
  value: string;
  children: React.ReactNode;
};

const Container = styled.div`
  margin: 8px 0;

  ${LabelType} {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
  }
`;

export default function Label({ value, children }: LabelProps) {
  return (
    <Container>
      <LabelType as="span">{value}</LabelType>
      {children}
    </Container>
  );
}
