import React from 'react';
import { H1, LeadType } from 'src/components/Typography';
import useTranslate from 'src/hooks/useTranslate';
import { InfoAlert } from './Alert/AlertBox';
import { InfoOutlined } from '@material-ui/icons';
import { Color } from 'src/constants';

export default function IngenSaker() {
  const translate = useTranslate();
  const godtgjoerelseLink = 'https://www.domstol.no/no/utbetalingogfaktura/godtgjorelse/';
  return (
    <div>
      <H1>{translate('saksoversikt.ikke-registrert')}</H1>
      <LeadType as="p">{translate('saksoversikt.tilgang')}</LeadType>
      <InfoAlert
        Icon={InfoOutlined}
        iconText={
          <>
            {translate('saksoversikt.godtgjoerelse-info1')}
            <a
              href={godtgjoerelseLink}
              target="_blank"
              rel="noreferrer noopener"
              style={{ color: Color.dsGreyDark }}
            >
              {translate('saksoversikt.godtgjoerelse-info2')}
            </a>
          </>
        }
      />
    </div>
  );
}
