import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Dokument } from 'src/domain/dokument';
import { HabilitetStatus, InnkallingStatus } from 'src/domain/enums';
import { Sak } from 'src/domain/sak';

export type SakerState = {
  saker: Sak[];
};

const initialState: SakerState = {
  saker: [],
};

function getSak(state: SakerState, sakId: string) {
  return state.saker.find(sak => sak.sakId === sakId);
}

function setLestForDokument(dokumenter: Dokument[], dokumentId: string, lest: boolean) {
  // Det samme dokumentet kan forekomme flere ganger med forskjellig dokumentnummer
  dokumenter
    .filter(x => x.id === dokumentId)
    .forEach(x => {
      x.lest = lest;
    });
}

export const { actions: sakerActions, reducer: sakerReducer } = createSlice({
  name: 'sakerStore',
  initialState,
  reducers: {
    setSaker: (state, action: PayloadAction<Sak[]>) => {
      state.saker = action.payload;
    },
    setHabilitetStatusForSak: (
      state,
      action: PayloadAction<{
        sakId: string;
        habilitetStatus: HabilitetStatus;
        partOgAktørIderVedHabilitetsvurdering: string[];
      }>
    ) => {
      const { sakId, habilitetStatus, partOgAktørIderVedHabilitetsvurdering } = action.payload;
      const sak = getSak(state, sakId);
      sak.habilitet.status = habilitetStatus;
      sak.habilitet.partOgAktørIderVedHabilitetsvurdering = partOgAktørIderVedHabilitetsvurdering;
    },
    setInnkallingStatusForSak: (
      state,
      action: PayloadAction<{ sakId: string; innkallingStatus: InnkallingStatus }>
    ) => {
      const { sakId, innkallingStatus } = action.payload;
      const sak = getSak(state, sakId);

      if (sak.innkalling != null) {
        sak.innkalling.status = innkallingStatus;
      }
    },
    markerDokumentSomLest: (
      state,
      action: PayloadAction<{ sakId: string; dokumentId: string }>
    ) => {
      const { sakId, dokumentId } = action.payload;
      const sak = getSak(state, sakId);
      setLestForDokument(sak.dokumenter, dokumentId, true);
    },
    markerDokumentSomUlest: (
      state,
      action: PayloadAction<{ sakId: string; dokumentId: string }>
    ) => {
      const { sakId, dokumentId } = action.payload;
      const sak = getSak(state, sakId);
      setLestForDokument(sak.dokumenter, dokumentId, false);
    },
  },
});
