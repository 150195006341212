import { useDispatch } from 'react-redux';
import useTranslate from 'src/hooks/useTranslate';
import { errorModalActions } from 'src/store/errorModalStore';

export default function useShowErrorMessage() {
  const dispatch = useDispatch();
  const translate = useTranslate();
  

  return function showErrorMessage(translationKey: string, translationArgs?: string[], readMoreUrl?: string) {
    dispatch(
      errorModalActions.show({
        title: translate('feil'),
        message: translate(translationKey, ...(translationArgs || [])),
        link: readMoreUrl  
      })
    );
  };
}
