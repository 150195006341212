import React from 'react';
import styled from 'styled-components';

import Menu from './Menu';
import MobileMenu from './MobileMenu';

const Header = styled.header`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1024px;
`;

type NavbarProps = {
  fullName: string;
  hideLogout: boolean;
};

export default function Navbar({ fullName, hideLogout = false }: NavbarProps) {
  const showLogout = !hideLogout;

  return (
    <Header>
      <Menu fullName={fullName} showLogout={showLogout} />
      <MobileMenu fullName={fullName} showLogout={showLogout} />
    </Header>
  );
}
