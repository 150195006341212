import { SvgIconProps, SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { P } from './Oppgaver.style';

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const IconWrapper = styled.div`
  margin-bottom: 0;
  vertical-align: text-bottom;
  margin-right: 1rem;
`;

type IconTextProps = {
  Icon?: OverridableComponent<SvgIconTypeMap<SvgIconProps, 'svg'>>;
  iconColor?: string;
  iconText: string | JSX.Element;
  children?: ReactNode;
};

export default function IconText({ Icon, iconColor, iconText, children }: IconTextProps) {
  return !Icon ? (
    <FlexRow>
      <P>{iconText}</P>
      {children}
    </FlexRow>
  ) : (
    <div>
      <FlexRow>
        <IconWrapper>
          <Icon htmlColor={iconColor} />
        </IconWrapper>
        <P>{iconText}</P>
      </FlexRow>
      {children}
    </div>
  );
}
