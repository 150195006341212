import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HabilitetStatus, InnkallingStatus } from 'src/domain/enums';
import { Sak } from 'src/domain/sak';

type OppgaveState = {
  open: boolean;
};

type Oppgave = 'vurderHabilitet' | 'bekreftOppmøte' | 'lesSaksdokumenter';

export const Oppgave = {
  VurderHabilitet: 'vurderHabilitet' as Oppgave,
  BekreftOppmøte: 'bekreftOppmøte' as Oppgave,
  LesSaksdokumenter: 'lesSaksdokumenter' as Oppgave,
};

const oppgaveInitialState = {
  open: false,
};

type VurderHabilitetState = {
  habilitetStatus: HabilitetStatus;
} & OppgaveState;

type BekreftOppmøteState = {
  bekrefterOppmøte: boolean;
  søkerFritak: boolean;
} & OppgaveState;

type LesSaksdokumenterState = OppgaveState;

export type OppgaverState = {
  sakId: string;
  vurderHabilitet: VurderHabilitetState;
  bekreftOppmøte: BekreftOppmøteState;
  lesSaksdokumenter: LesSaksdokumenterState;
};

function createInitialState(
  sakId: string = null,
  habilitetStatus = HabilitetStatus.IkkeValgtHabilitet,
  innkallingStatus = InnkallingStatus.Ubesvart
): OppgaverState {
  return {
    sakId,
    vurderHabilitet: {
      habilitetStatus: habilitetStatus,
      ...oppgaveInitialState,
    },
    bekreftOppmøte: {
      bekrefterOppmøte: innkallingStatus === InnkallingStatus.BekreftetOppmøte,
      søkerFritak: false,
      ...oppgaveInitialState,
    },
    lesSaksdokumenter: {
      ...oppgaveInitialState,
    },
  };
}

const toggleOpen = (oppgave: Oppgave) => (state: OppgaverState) => {
  state[oppgave].open = !state[oppgave].open;
};

const open = (oppgave: Oppgave) => (state: OppgaverState) => {
  state[oppgave].open = true;
};

const close = (oppgave: Oppgave) => (state: OppgaverState) => {
  state[oppgave].open = false;
};

export const { actions: oppgaverActions, reducer: oppgaverReducer } = createSlice({
  name: 'oppgaverStore',
  initialState: createInitialState(),
  reducers: {
    setSak: (state, action: PayloadAction<Sak>) => {
      const sak = action.payload;
      if (state.sakId !== sak.sakId) {
        return createInitialState(sak.sakId, sak.habilitet.status, sak.innkalling?.status);
      }
    },
    setValgtHabilitet: (state, action: PayloadAction<HabilitetStatus>) => {
      state.vurderHabilitet.habilitetStatus = action.payload;
    },
    selectedBekrefterOppmøte: state => {
      state.bekreftOppmøte.bekrefterOppmøte = true;
      state.bekreftOppmøte.søkerFritak = false;
    },
    selectedSøkerFritak: state => {
      state.bekreftOppmøte.bekrefterOppmøte = false;
      state.bekreftOppmøte.søkerFritak = true;
    },
    toggleOpenVurderHabilitet: toggleOpen(Oppgave.VurderHabilitet),
    toggleOpenBekreftOppmøte: toggleOpen(Oppgave.BekreftOppmøte),
    toggleOpenLesSaksdokumenter: toggleOpen(Oppgave.LesSaksdokumenter),
    openVurderHabilitet: open(Oppgave.VurderHabilitet),
    openBekreftOppmøte: open(Oppgave.BekreftOppmøte),
    openLesSaksdokumenter: open(Oppgave.LesSaksdokumenter),
    closeVurderHabilitet: close(Oppgave.VurderHabilitet),
    closeBekreftOppmøte: close(Oppgave.BekreftOppmøte),
    closeLesSaksdokumenter: close(Oppgave.LesSaksdokumenter),
  },
});
