import React from 'react';
import Logo from './Logo';
import FooterLink from './FooterLink';
import styled from 'styled-components';
import useTranslate from 'src/hooks/useTranslate';

const FooterContainer = styled.div`
  background-color: #f4f4f4;
  width: 100%;
`;

const StyledFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 64px;
  padding-left: 64px;
  padding-bottom: 64px;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;

  a {
    white-space: nowrap;
    width: fit-content;
  }
`;

const FooterLogoContainer = styled.div`
  min-width: 150px;
  padding-bottom: 64px;
  padding-right: 64px;
`;

const FooterContent = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    padding-bottom: 64px;
    padding-right: 64px;
  }

  > a {
    margin-top: 16px;
  }
`;

const FooterContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const FooterHeading = styled.div`
  font-family: 'Proxima Nova';
  font-weight: 600;
  font-size: 16px;
`;

function Footer() {
  const translate = useTranslate();

  return (
    <FooterContainer>
      <StyledFooter>
        <FooterLogoContainer>
          <Logo />
        </FooterLogoContainer>

        <FooterContent>
          <FooterContentColumn>
            <FooterHeading>{translate('footer.hjelp')}</FooterHeading>
            <div>
              <FooterLink href="https://www.domstol.no/no/aktorportalen/aktorportalen-for-meddommere/">
                {translate('footer.informasjonOgVeiledning')}
              </FooterLink>
            </div>
            <div>
              <FooterLink href="https://www.domstol.no/no/utbetalingogfaktura/godtgjorelse/">
                {translate('footer.godtgjorelse')}
              </FooterLink>
            </div>
          </FooterContentColumn>

          <FooterContentColumn>
            <FooterHeading>{translate('footer.omAktørportalen')}</FooterHeading>
            <FooterLink href="https://www.domstol.no/no/aktorportalen/aktorportalen-for-meddommere/">
              {translate('footer.brukervilkår')}
            </FooterLink>
            <FooterLink href="https://www.domstol.no/no/personvernpolicy/aktorportalen/">
              {translate('footer.personvernerklæring')}
            </FooterLink>
            <FooterLink href="https://uustatus.no/nb/erklaringer/publisert/478a0501-a521-46d7-b261-d376f9266384">
              {translate('footer.tilgjengelighetserklæring')}
            </FooterLink>
          </FooterContentColumn>

          <FooterContentColumn>
            <FooterHeading>{translate('footer.kontakt')}</FooterHeading>
            <FooterLink href="https://airtable.com/embed/shrYRpuOdD3HWWHsV">
              {translate('footer.tilbakemelding')}
            </FooterLink>
            <FooterLink href="https://www.domstol.no/no/finn-domstol/">
              {translate('footer.finnKontaktinfo')}
            </FooterLink>
          </FooterContentColumn>
        </FooterContent>
      </StyledFooter>
    </FooterContainer>
  );
}

export default Footer;
