import React from 'react';
import styled from 'styled-components';
import { Color } from '../../../constants';
import CheckSharp from '@material-ui/icons/CheckSharp';
import { FieldInputProps } from 'react-final-form';

const HiddenCheckbox = styled.input`
  position: absolute;
  z-index: -1;
`;

const StyledCheckbox = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 15px;
  height: 15px;
  border: ${props =>
    props['aria-checked'] ? `solid 2px ${Color.dsBlue}` : `solid 2px ${Color.GreyDark}`};
  background-color: ${props => (props['aria-checked'] ? `${Color.dsBlue}` : `${Color.GreyLight}`)};
  transition: all 150ms;
  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px rgba(210, 35, 30, 0.5);
  }
  & > svg {
    visibility: ${props => (props['aria-checked'] ? 'visible' : 'hidden')};
  }
`;

const StyledCheck = styled(CheckSharp)`
  color: white;
  font-size: small;
`;

const CheckboxContainer = styled.div`
  display: inline-flex;
  align-items: center;
  position: relative;
  &:hover ${StyledCheckbox} {
    border: solid 2px ${Color.Red};
  }
`;

const StyledLabel = styled.label`
  position: relative;
  color: ${Color.GreyDark};
  font-size: 14px;
  padding: 8px;
  font-weight: 400;
  line-height: 1.25rem;
  cursor: pointer;
  padding-left: 23px;
  padding-top: 10px;
`;

type CheckboxProps = {
  label: string;
  id: string;
  input: FieldInputProps<never, HTMLElement>;
};

const Checkbox = ({ label, id, input, ...props }: CheckboxProps) => {
  return (
    <CheckboxContainer>
      <HiddenCheckbox {...input} id={id} {...props} />
      <StyledCheckbox aria-checked={input.checked}>
        <StyledCheck />
      </StyledCheckbox>
      <StyledLabel htmlFor={id}>{label}</StyledLabel>
    </CheckboxContainer>
  );
};

export default Checkbox;
