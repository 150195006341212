import { AxiosError, AxiosResponse } from 'axios';
import { BASE_URL } from 'src/constants';
import { ApiErrorResponse, ApiStatus, ApiSuccessResponse } from '.';

export function handleAxiosErrors(err: AxiosError): Promise<ApiErrorResponse> {
  if (err.response == null) {
    // Requests som er pending når man redirecter returnerer en tom response
    return new Promise(() => {});
  }

  if (err.response.status == 401) {
    window.location.href = `${BASE_URL}/signin`;
    // Returnerer Promise som aldri resolver slik at ingenting skjer under redirecten
    return new Promise(() => {});
  }

  return Promise.resolve({
    status: ApiStatus.Error,
    statusCode: err.response.status,
    error: err.message,
    text:
      err.response.headers.getContentType === 'text/plain' ? err.response.statusText : undefined,
  });
}

export function handleAxiosResponse<T = unknown>(res: AxiosResponse): ApiSuccessResponse<T> {
  return {
    status: ApiStatus.Success,
    statusCode: res.status,
    data: res.data as T,
  };
}
