import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Bruker } from 'src/domain/bruker';

export type BrukerState = {
  bruker: Nullable<Bruker>;
};

const initialState: BrukerState = {
  bruker: null,
};

export const { actions: brukerActions, reducer: brukerReducer } = createSlice({
  name: 'brukerStore',
  initialState: initialState,
  reducers: {
    setBruker: (state, action: PayloadAction<Bruker>) => {
      state.bruker = action.payload;
    },
    aksepterBrukervilkår: state => {
      if (state.bruker) {
        state.bruker.måAkseptereBrukervilkår = false;
      }
    },
    bekreftKontaktinfo: state => {
      if (state.bruker) {
        state.bruker.måBekrefteKontaktInfo = false;
      }
    },
  },
});
