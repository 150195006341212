import React from 'react';
import { sakerActions } from 'src/store/sakerStore';
import { isError, post } from 'src/ajax';
import { InfoMessage } from 'src/components/Messages';
import useTranslate from 'src/hooks/useTranslate';
import DokumentTabell from './DokumentTabell';
import { Sak } from 'src/domain/sak';
import { Dokument } from 'src/domain/dokument';
import { useAppDispatch } from 'src/store';

type SaksdokumenterProps = {
  sak: Sak;
};

export default function Saksdokumenter({ sak }: SaksdokumenterProps) {
  const translate = useTranslate();
  const dispatch = useAppDispatch();

  if (sak.dokumenter.length === 0) {
    return <InfoMessage title={translate('saksdokumenter.ingen-dokumenter')} />;
  }

  function dokumentOnClick(dokument: Dokument) {
    if (dokument.lest) return;

    const payload = {
      dokumentId: dokument.id,
      sakId: sak.sakId,
    };

    dispatch(sakerActions.markerDokumentSomLest(payload));

    post(`/dokument/markersomlest`, payload).then(response => {
      // Compensating action
      if (isError(response)) {
        dispatch(sakerActions.markerDokumentSomUlest(payload));
      }
    });
  }

  return <DokumentTabell sak={sak} dokumentOnClick={dokumentOnClick} />;
}
