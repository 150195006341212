import withPopulateBrukerStore from 'src/hoc/withPopulateBrukerStore';
import { withAktørportalenLoader } from 'src/hoc/withLoader';
import { withRedirectToBrukervilkår, withRedirectToProfil } from 'src/hoc/withRedirect';

const withAkseptertBrukervilkår = <P extends Record<string, unknown>>(
  Component: React.ComponentType<P>
) =>
  withPopulateBrukerStore(
    withAktørportalenLoader(withRedirectToBrukervilkår(withRedirectToProfil(Component)))
  );

export default withAkseptertBrukervilkår;
