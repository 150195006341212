import styled from 'styled-components';
import { Color } from 'src/constants';
import { ReactNode } from 'react';

type ContainerProps = {
  maxWidth?: string;
};

const InformationBoxContainer = styled.div<ContainerProps>`
  border-left: solid 1px ${Color.Red};
  padding-left: 24px;
  max-width: ${props => props.maxWidth && props.maxWidth};
`;

type InformationBoxProps = {
  maxWidth?: string;
  children?: ReactNode;
};

export function InformationBox({ children, maxWidth }: InformationBoxProps) {
  return <InformationBoxContainer maxWidth={maxWidth}>{children}</InformationBoxContainer>;
}
