import React from 'react';
import ClockSharp from '@material-ui/icons/ScheduleSharp';
import CheckSharp from '@material-ui/icons/CheckSharp';
import useTranslate from 'src/hooks/useTranslate';
import { LabelType, InfoType } from 'src/components/Typography';
import dateFormat from 'date-fns/format';
import dateIsValid from 'date-fns/isValid';
import { OppgaveStatus } from 'src/domain/enums';

type StatusProps = {
  oppgaveStatus: OppgaveStatus;
  frist: Date;
};

export default function Status({ oppgaveStatus, frist }: StatusProps) {
  const translate = useTranslate();

  switch (oppgaveStatus) {
    case OppgaveStatus.IkkeUtført:
      return dateIsValid(frist) ? (
        <>
          <InfoType>{dateFormat(frist, 'dd.MM.yyyy')}</InfoType>
          <LabelType>{translate('mine-oppgaver.frist')}</LabelType>
        </>
      ) : (
        <LabelType>{translate('mine-oppgaver.ingen-frist')}</LabelType>
      );

    case OppgaveStatus.Utført:
      return (
        <>
          <CheckSharp />
          <LabelType>{translate('mine-oppgaver.utført')}</LabelType>
        </>
      );

    case OppgaveStatus.UnderBehandling:
      return (
        <>
          <ClockSharp />
          <LabelType>{translate('mine-oppgaver.under-behandling')}</LabelType>
        </>
      );
    default:
      return null;
  }
}
