import React from 'react';
import { useParams } from 'react-router-dom';
import { ErrorMessage } from 'src/components/Messages';
import Page from 'src/components/Page';
import ReactPDFViewer from 'src/components/ReactPDFViewer';
import { Sak } from 'src/domain/sak';
import withAkseptertBrukervilkår from 'src/hoc/withAkseptertBrukervilkår';
import withLoader from 'src/hoc/withLoader';
import withRouteSakId from 'src/hoc/withRouteSakId';
import { withSak } from 'src/hoc/withSaker';
import useTranslate from 'src/hooks/useTranslate';

type PDFProps = {
  sak: Sak;
};

const PDF = ({ sak }: PDFProps) => {
  const translate = useTranslate();

  const { dokumentId } = useParams<{ dokumentId: string }>();
  const pdfUrl = `/api/file/${dokumentId}`;
  const dokument = sak.dokumenter.find(x => x.id === dokumentId);
  const isInnkallingsbrev = dokument?.id === sak.innkalling?.innkallingsbrevId;
  
  if (!dokument) {
    return (
      <Page>
        <ErrorMessage title={translate('saksdokumenter.dokument.hent-feilet')} />
      </Page>
    );
  }

  window.document.title = dokument.dokumentNavn;

  return (
    <ReactPDFViewer
      pdfUrl={pdfUrl}
      kanPrinteUtOgLasteNedDokumenter={sak.kanPrinteUtOgLasteNedDokumenter || isInnkallingsbrev}
    />
  );
};

export default withAkseptertBrukervilkår(
  withRouteSakId(
    withSak(
      withLoader('saksdokumenter.dokument.henter', 'saksdokumenter.dokument.hent-feilet')(PDF)
    )
  )
);
