import React from 'react';
import { History } from 'history';
import { useHistory } from 'react-router-dom';

type WithHistoryProps = {
  history: History<unknown>;
  [x: string]: unknown;
};

export default function withHistory<T extends WithHistoryProps>(
  Component: React.ComponentType<WithHistoryProps>
): React.ComponentType<Omit<WithHistoryProps, 'history'>> {
  return (props: T) => {
    const history = useHistory();
    return <Component {...props} history={history} />;
  };
}
