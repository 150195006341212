import React from 'react';
import { Route, Switch, withRouter, Redirect, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import NavLink from 'src/components/NavLink';

import { Color } from 'src/constants';
import { ErrorBoundary } from 'src/components/ErrorBoundary';

const List = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0;
  margin-bottom: 80px;
  padding: 0;
  border-bottom: solid 1px ${Color.GreyNormal};
`;

const StyledNavLink = styled(NavLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  padding: 4px 16px;
  margin: 8px 0px -1px 0px;
  opacity: 0.88;
  color: ${Color.GreyDark};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  white-space: nowrap;

  :hover {
    opacity: 1;
    border-bottom: solid 2px ${Color.GreyDark};
  }
`;

const activeNavLink = {
  color: Color.Red,
  borderBottom: `solid 2px ${Color.Red}`,
};

export type SakTab = {
  index: number;
  title: string;
  url: string;
  icon: JSX.Element;
  ariaLabel: string;
  component: () => JSX.Element;
};

type SakFanerProps = RouteComponentProps & {
  tabContent: SakTab[];
};

const SakFaner = ({ match, tabContent }: SakFanerProps) => {
  return (
    <div>
      <List>
        {tabContent.map(({ index, title, url, icon, ariaLabel }) => (
          <StyledNavLink
            aria-label={ariaLabel}
            to={`${match.url}/${url}`}
            activeStyle={activeNavLink}
            key={index}
          >
            {icon}
            {title}
          </StyledNavLink>
        ))}
      </List>
      <ErrorBoundary>
        <Switch>
          {tabContent.map(({ url, component }) => (
            <Route exact path={`${match.path}/${url}`} render={component} key={url} />
          ))}
          <Route render={() => <Redirect to={`${match.url}/mine-oppgaver`} />} />
        </Switch>
      </ErrorBoundary>
    </div>
  );
};

export default withRouter(SakFaner);
