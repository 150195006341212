import React, { useState, useEffect } from 'react';
import {
  Route,
  Switch,
  withRouter,
  Redirect,
  useLocation,
  RouteComponentProps,
} from 'react-router-dom';
import styled from 'styled-components';
import KeyboardArrowDownSharp from '@material-ui/icons/KeyboardArrowDownSharp';
import { Color } from 'src/constants';
import NavLink from 'src/components/NavLink';
import { SakTab } from '../SakFaner/SakFaner';

const DropdownContainer = styled.div`
  width: 100%;
`;

const Dropdown = styled.button<{ dropdownOpen: boolean }>`
  display: flex;
  flex-direction: ${props => (props.dropdownOpen ? 'column' : 'row')};
  align-items: ${props => (props.dropdownOpen ? 'stretch' : 'center')};
  cursor: pointer;
  margin-bottom: 40px;
  width: 272px;
  padding: 0;
  border: solid 1px ${Color.GreyDark};

  & > svg {
    margin-left: auto;
    margin-right: 16px;
  }
`;

const StyledActiveElement = styled.span`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${Color.GreyDark};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding: 16px;
  color: ${Color.Red};

  & > svg {
    margin-right: 16px;
  }
`;

const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${Color.GreyDark};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding: 16px;

  & > svg {
    margin-right: 16px;
  }
`;

type SakDropdownProps = RouteComponentProps & {
  tabContent: SakTab[];
};

const activeNavLinkStyle = {
  color: Color.Red,
};

const SakDropdown = ({ match, tabContent }: SakDropdownProps) => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<number>(null);
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  useEffect(() => {
    const path = location.pathname;
    for (let index = 0; index < tabContent.length; index++) {
      if (tabContent[index].url === path.substring(path.lastIndexOf('/') + 1, path.length)) {
        setActiveTab(tabContent[index].index);
      }
    }
  }, []);

  return (
    <DropdownContainer>
      <Dropdown onClick={() => setDropdownOpen(!dropdownOpen)} dropdownOpen={dropdownOpen}>
        {tabContent.map(({ index, title, icon, url, ariaLabel }) => {
          if (!dropdownOpen) {
            if (index == activeTab) {
              return (
                <StyledActiveElement key={index} aria-label={ariaLabel}>
                  {icon}
                  {title}
                </StyledActiveElement>
              );
            }
          } else {
            return (
              <StyledNavLink
                to={`${match.url}/${url}`}
                key={index}
                activeStyle={activeNavLinkStyle}
                onClick={() => setActiveTab(index)}
                aria-label={ariaLabel}
              >
                {icon}
                {title}
              </StyledNavLink>
            );
          }
        })}
        {dropdownOpen ? null : <KeyboardArrowDownSharp />}
      </Dropdown>
      <Switch>
        {tabContent.map(({ url, component }) => (
          <Route exact path={`${match.path}/${url}`} render={component} key={url} />
        ))}
        <Route render={() => <Redirect to={`${match.url}/mine-oppgaver`} />} />
      </Switch>
    </DropdownContainer>
  );
};

export default withRouter(SakDropdown);
