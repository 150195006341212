import { OppgaveStatus, HabilitetStatus, InnkallingStatus } from './enums';
import { Sak } from './sak';

export type OppgaveFrist = {
  oppgaveStatus: OppgaveStatus;
  frist: Date;
};

const ikkeTilgjengeligOppgave: OppgaveFrist = {
  oppgaveStatus: OppgaveStatus.IkkeTilgjengelig,
  frist: null,
};

export function lagOppgaveForVurderHabilitet(sak: Sak): OppgaveFrist {
  if (sak.innkalling == null) {
    return ikkeTilgjengeligOppgave;
  }

  let oppgaveStatus: OppgaveStatus;

  switch (sak.habilitet.status) {
    case HabilitetStatus.ValgtHabil:
      oppgaveStatus = OppgaveStatus.Utført;
      break;
    case HabilitetStatus.ValgtInhabil:
      oppgaveStatus = OppgaveStatus.UnderBehandling;
      break;
    case HabilitetStatus.IkkeValgtHabilitet:
    case HabilitetStatus.ValgtHabilitetErUtdatert:
    default:
      oppgaveStatus = OppgaveStatus.IkkeUtført;
  }

  return {
    oppgaveStatus,
    frist: new Date(sak.innkalling.svarfrist),
  };
}

export function lagOppgaveForBekreftOppmøte(sak: Sak): OppgaveFrist {
  if (
    sak.habilitet.status === HabilitetStatus.IkkeValgtHabilitet ||
    sak.habilitet.status === HabilitetStatus.ValgtInhabil ||
    sak.innkalling == null
  ) {
    return ikkeTilgjengeligOppgave;
  }

  let oppgaveStatus: OppgaveStatus;

  switch (sak.innkalling.status) {
    case InnkallingStatus.BekreftetOppmøte:
    case InnkallingStatus.InnvilgetFritak:
    case InnkallingStatus.InnvilgetInhabil:
      oppgaveStatus = OppgaveStatus.Utført;
      break;
    case InnkallingStatus.SøktFritak:
      oppgaveStatus = OppgaveStatus.UnderBehandling;
      break;
    case InnkallingStatus.Ubesvart:
    default:
      oppgaveStatus = OppgaveStatus.IkkeUtført;
  }

  return {
    oppgaveStatus,
    frist: new Date(sak.innkalling.svarfrist),
  };
}
