import React from 'react';
import { useSelector } from 'react-redux';
import Page from 'src/components/Page';
import IngenSaker from 'src/components/IngenSaker';
import { brukerActions } from 'src/store/brukerStore';
import useFetch from 'src/hooks/useFetch';
import { selectBruker } from 'src/store/selectors/brukerSelectors';
import { WithLoaderProps } from './withLoader';
import { useAppDispatch } from 'src/store';
import { Bruker } from 'src/domain/bruker';

const withPopulateBrukerStore =
  <P extends Record<string, unknown>>(
    Component: React.ComponentType<P>
  ): React.ComponentType<WithLoaderProps & P> =>
  props => {
    const dispatch = useAppDispatch();

    const response = useFetch<Bruker>('/bruker', bruker => {
      dispatch(brukerActions.setBruker(bruker));
    });
    const bruker = useSelector(selectBruker);

    if (response.isError && response.error.includes('403')) {
      return (
        <Page>
          <IngenSaker />
        </Page>
      );
    }

    const isLoading = props.isLoading || (!response.isError && bruker === null);
    const hasError = props.hasError || response.isError;

    return <Component {...props} isLoading={isLoading} hasError={hasError} />;
  };

export default withPopulateBrukerStore;
