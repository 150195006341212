import React, { useState } from 'react';
import styled from 'styled-components';

import useTranslate from 'src/hooks/useTranslate';
import HamburgerMenu from './HamburgerMenu';
import Brand from 'src/components/Brand';
import LogoutNavButton from './LogoutNavButton';
import { activeNavLink, StyledNavLink } from './Menu.style';

const MobileMenuContainer = styled.div`
  width: 100%;

  @media (min-width: 425px) {
    display: none;
  }
`;

const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 8px 0 8px;

  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const BrandAndMenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type MobileMenuProps = {
  fullName: string;
  showLogout: boolean;
};

export default function MobileMenu({ fullName, showLogout }: MobileMenuProps) {
  const translate = useTranslate();

  const [open, setOpen] = useState(false);
  const showProfileAndSaker = !!fullName;
  const showMenu = showProfileAndSaker || showLogout;

  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  const SaksoversiktNavLink = () => (
    <StyledNavLink to="/" exact activeStyle={activeNavLink}>
      {translate('saksoversikt.tittel')}
    </StyledNavLink>
  );

  const MinProfilNavLink = () => (
    <StyledNavLink to="/min-profil" exact activeStyle={activeNavLink}>
      {translate('profil.tittel')}
    </StyledNavLink>
  );

  return (
    <MobileMenuContainer>
      <BrandAndMenuContainer>
        <Brand />
        {showMenu && <HamburgerMenu open={open} onOpen={onOpen} onClose={onClose} />}
      </BrandAndMenuContainer>
      {open && (
        <NavContainer>
          {showProfileAndSaker && <SaksoversiktNavLink />}
          {showProfileAndSaker && <MinProfilNavLink />}
          {showLogout && <LogoutNavButton />}
        </NavContainer>
      )}
    </MobileMenuContainer>
  );
}
