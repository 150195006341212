import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Location, Action } from 'history';
import { useLastLocation } from 'react-router-last-location';
import { PrimaryButton, SecondaryButton } from 'src/components/Buttons';
import Modal from '../Modal';

type NavigationConfirmationModalProps = {
  show: boolean;
  title: string;
  confirmText: string;
  cancelText: string;
  onConfirm: () => void;
  children: React.ReactNode;
};

// Denne implementasjonen er basert på:
// https://medium.com/@michaelchan_13570/using-react-router-v4-prompt-with-custom-modal-component-ca839f5faf39

export default function NavigationConfirmationModal({
  show,
  title,
  confirmText,
  cancelText,
  onConfirm: onConfirmCallback,
  children,
}: NavigationConfirmationModalProps) {
  const history = useHistory();
  const lastLocation = useLastLocation();
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState<Action | undefined>();
  const [location, setLocation] = useState<Location | undefined>();

  const goToLocation = useCallback(() => {
    switch (action) {
      case 'POP':
        // History APIet tilbyr ingen måte å si om brukeren trykket på back eller forward.
        // Velger derfor alltid back siden det er det vanligste.
        history.goBack();
        break;
      case 'REPLACE':
        history.replace(location);
        break;
      case 'PUSH':
      default:
        history.push(location);
    }
  }, [action, location, lastLocation]);

  function onConfirm() {
    setOpen(false);
    onConfirmCallback();
    goToLocation();
  }

  function onCancel() {
    setOpen(false);
  }

  function blockNavigation() {
    const unblockNavigation = history.block((location, action) => {
      if (!open && show) {
        setOpen(true);
        setAction(action);
        setLocation(location);
        return false;
      }
    });

    return unblockNavigation;
  }

  useEffect(blockNavigation, [open, show]);

  return (
    <Modal
      id="confirmation-modal"
      open={open}
      onClose={onCancel}
      title={title}
      renderContent={() => children}
      renderActions={() => (
        <>
          <SecondaryButton onClick={onCancel}>{cancelText}</SecondaryButton>
          <PrimaryButton autoFocus onClick={onConfirm}>
            {confirmText}
          </PrimaryButton>
        </>
      )}
    />
  );
}
