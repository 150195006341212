import React, { useState, useMemo } from 'react';
import { Color } from 'src/constants';
import styled, { css } from 'styled-components';
import { LabelType, InfoType } from 'src/components/Typography';
import { StealthButton } from 'src/components/Buttons/Buttons';
import SearchField from 'src/components/SearchField';
import dateFormat from 'date-fns/format';
import { sortBy, sortByAlphaNum, DESC, ASC, Sort, Direction, KeysWithStringValue } from './sort';
import DokumentTypeFilter, { DokumentTyper } from './DokumentTypeFilter';
import { AktoerRolle, DokumentType } from 'src/domain/enums';
import Tooltip from 'src/components/Tooltip';

import KeyboardArrowDownSharp from '@material-ui/icons/KeyboardArrowDownSharp';
import KeyboardArrowUpSharp from '@material-ui/icons/KeyboardArrowUpSharp';
import AttachFileSharp from '@material-ui/icons/AttachFileSharp';
import DescriptionSharp from '@material-ui/icons/DescriptionSharp';
import useTranslate from 'src/hooks/useTranslate';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { SvgIconTypeMap } from '@material-ui/core/SvgIcon';
import { Dokument } from 'src/domain/dokument';
import { Sak } from 'src/domain/sak';
import { InfoAlert } from 'src/components/Alert/AlertBox';
import { ErrorOutline } from '@material-ui/icons';

type SortIconProps = {
  Icon: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>>;
  small?: boolean;
};

const sortIconStyle = css<{ small?: boolean }>`
  && {
    margin-left: 0.5rem;
    font-size: 1.4rem;
    ${props => (props.small ? 'font-size: 1rem;' : '')}
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SortIcon = styled(({ Icon, small, ...rest }: SortIconProps) => <Icon {...rest} />)`
  ${sortIconStyle}
`;

const ArrowNoSort = styled(({ className }: { className?: string }) => (
  <span className={className}>
    <SortIcon Icon={KeyboardArrowUpSharp} small />
    <SortIcon Icon={KeyboardArrowDownSharp} small />
  </span>
))`
  && {
    display: flex;
    flex-direction: column;

    > svg:first-child {
      margin-bottom: -3px;
    }
    > svg:last-child {
      margin-top: -3px;
    }
  }
`;

const SortIcons = ({ field, sort }: { field: string; sort: Sort<Dokument> }) => {
  if (!(sort.field == field) || sort.field == null) {
    return <ArrowNoSort />;
  }
  if (sort.direction == DESC) {
    return <SortIcon Icon={KeyboardArrowUpSharp} />;
  }
  return <SortIcon Icon={KeyboardArrowDownSharp} />;
};

const DokumentTabellContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 660px) {
    > div:first-child {
      border-top: solid 2px ${Color.GreyDark};
    }
  }
`;

const Header = styled.div`
  display: flex;
  padding: 16px;
  border-top: solid 2px ${Color.GreyDark};
  border-bottom: solid 1px ${Color.GreyDark};

  > button:not(:first-child):not(:last-child) {
    flex: 1 1 28%;
  }

  > button:first-child {
    flex: 1 0 8%;
    min-width: 100px;
  }

  > button:last-child {
    flex: 1 0 8%;
    min-width: 100px;
  }

  > button:not(:last-child) {
    margin-right: 8px;
  }

  @media (max-width: 660px) {
    > button:not(:first-child):not(:last-child) {
      flex: 1 1 60%;
    }

    > button:first-child {
      flex: 1 0 20%;
      min-width: 0;
    }

    > :double-button:last-child {
      flex: 1 0 20%;
      min-width: 0;
    }
  }
`;

const TableMenu = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  @media (max-width: 660px) {
    flex-direction: column;
    align-items: center;
  }
`;

const HeaderLabel = styled(StealthButton)`
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  cursor: pointer;

  > label {
    cursor: inherit;
  }
`;

const ListElement = styled.a<{ disabled: boolean; highlighted: boolean }>`
  text-decoration: none;
  color: inherit;
  display: flex;
  padding: 16px;
  align-items: center;
  border-bottom: 1px solid ${Color.GreyLight2};
  cursor: pointer;

  > *:not(:first-child):not(:last-child) {
    flex: 1 1 28%;
  }

  > span:last-child {
    flex: 1 0 8%;
    min-width: 100px;
  }

  > *:not(:last-child) {
    margin-right: 8px;
  }

  @media (max-width: 660px) {
    > span:not(:first-child):not(:last-child) {
      flex: 1 1 60%;
    }

    > span:first-child {
      flex: 1 0 20%;
      min-width: 0;
    }

    > span:last-child {
      flex: 1 0 20%;
      min-width: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-self: flex-start;
    }
  }

  ${props =>
    props.disabled &&
    `
    cursor: default;

    > * {
      color: ${Color.GreyNormal}
    }
    `}

  ${props =>
    props.highlighted &&
    `
    > * {
      font-weight: 700;
    }
    `}
`;

const ListElementItemWithIcon = styled.div`
  display: flex;
  flex: 1 0 8%;
  min-width: 100px;
  align-items: center;
`;

type DokumentTabellProps = {
  dokumentOnClick: (dokument: Dokument) => void;
  sak: Sak;
};

export default function DokumentTabell({ dokumentOnClick, sak }: DokumentTabellProps) {
  const [innerWidth] = useState(window.innerWidth);
  const [sort, setSort] = useState<Sort<Dokument>>({ field: 'nummer', direction: ASC });
  const [søk, setSøk] = useState('');
  const [valgteDokumentTyper, oppdaterValgteDokumentTyper] = useState<DokumentTyper>({
    [DokumentType.Hoveddokument]: true,
    [DokumentType.Bilag]: true,
  });
  const translate = useTranslate();

  const onSort = (field: KeysWithStringValue<Dokument>) => {
    let direction: Direction = ASC;
    if (sort.field == field && sort.direction === ASC) {
      direction = DESC;
    }

    setSort({ field, direction });
  };

  const sortFunc = useMemo(
    () => (sort.field === 'nummer' ? sortByAlphaNum<Dokument>(sort) : sortBy<Dokument>(sort)),
    [sort]
  );

  const dokumenterSortertOgFiltrert = [...sak.dokumenter]
    .sort(sortFunc)
    .filter(
      dokument =>
        dokument.dokumentNavn.toLowerCase().indexOf(søk.toLowerCase()) > -1 ||
        dokument.avsender.toLowerCase().indexOf(søk.toLowerCase()) > -1 ||
        dokument.mottaker.toLowerCase().indexOf(søk.toLowerCase()) > -1
    )
    .filter(d => {
      return valgteDokumentTyper[d.type];
    });

  const getAriaLabel = (field: string) => {
    field = field.toLowerCase();
    return sort.field !== field
      ? `${translate(`saksdokumenter.sorter-${field}`)}, stigende`
      : `${translate(`saksdokumenter.sorter-${field}`)}, ${
          sort.direction
            ? translate('saksdokumenter.stigende')
            : translate('saksdokumenter.synkende')
        }`;
  };

  const onMouseDown = (dokument: Dokument) => (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (e.button === 0 || e.button === 1) {
      // Left or middle mouse button
      dokumentOnClick(dokument);
    }
  };

  const PDFUrl = (sak: Sak, dokument: Dokument): string => {
    return `/saker/${sak.sakId}/dokumenter/${dokument.id}`;
  };

  const TekstInfoOmTilgangPåSaksdokumenter = () => {
    // Varameddommer / varamedlem uten oppmøteplikt
    if (
      !sak.innkalling?.oppmøteplikt &&
      sak.rolle.kode.toString() === ('VaraMeddommer' || 'VaraSkjonnsmedlem')
    ) {
      return (
        <InfoAlert
          Icon={ErrorOutline}
          iconText={translate(
            'saksdokumenter.meddommer-info-vara',
            sak.rolle.beskrivelse.toLowerCase()
          )}
        />
      );
    }

    // Alminnelig meddommer eller varameddommer / skjønnsmedlem med oppmøteplikt
    if (
      new Date(sak.dokumentTilgangsDato) > new Date() &&
      (sak.rolle.beskrivelse === AktoerRolle.AlminneligMeddommer.toString() ||
        (sak.innkalling?.oppmøteplikt &&
          sak.rolle.kode.toString() === ('VaraMeddommer' || 'VaraSkjonnsmedlem')))
    ) {
      return (
        <InfoAlert
          Icon={ErrorOutline}
          iconText={translate(
            'saksdokumenter.meddommer-info-alminnelig',
            dateFormat(new Date(sak.dokumentTilgangsDato), 'dd.MM.yyyy')
          )}
        />
      );
    }
    return <></>;
  };

  return (
    <>
      {innerWidth > 660 ? (
        <>
          <TekstInfoOmTilgangPåSaksdokumenter />
          <TableMenu>
            <SearchField
              value={søk}
              onChange={e => setSøk(e.target.value)}
              type="text"
              placeholder={translate('saksdokumenter.søk-i-dokumenter')}
              maxWidth="400px"
            />
            <DokumentTypeFilter
              dokumenter={sak.dokumenter}
              valgteDokumentTyper={valgteDokumentTyper}
              oppdaterValgteDokumentTyper={oppdaterValgteDokumentTyper}
            />
          </TableMenu>
          <DokumentTabellContainer>
            <Header>
              <HeaderLabel onClick={() => onSort('nummer')} aria-label={getAriaLabel('nummer')}>
                <LabelType>{translate('saksdokumenter.nr').toUpperCase()}</LabelType>
                <SortIcons field="nummer" sort={sort} />
              </HeaderLabel>
              <HeaderLabel
                onClick={() => onSort('dokumentNavn')}
                aria-label={getAriaLabel('dokumentNavn')}
              >
                <LabelType>{translate('saksdokumenter.dokumentnavn').toUpperCase()}</LabelType>
                <SortIcons field="dokumentNavn" sort={sort} />
              </HeaderLabel>
              <HeaderLabel onClick={() => onSort('avsender')} aria-label={getAriaLabel('avsender')}>
                <LabelType>{translate('saksdokumenter.avsender').toUpperCase()}</LabelType>
                <SortIcons field="avsender" sort={sort} />
              </HeaderLabel>
              <HeaderLabel onClick={() => onSort('mottaker')} aria-label={getAriaLabel('mottaker')}>
                <LabelType>{translate('saksdokumenter.mottaker').toUpperCase()}</LabelType>
                <SortIcons field="mottaker" sort={sort} />
              </HeaderLabel>
              <HeaderLabel onClick={() => onSort('dato')} aria-label={getAriaLabel('dato')}>
                <LabelType>{translate('saksdokumenter.sendt').toUpperCase()}</LabelType>
                <SortIcons field="dato" sort={sort} />
              </HeaderLabel>
            </Header>
            {dokumenterSortertOgFiltrert.map((dokument, index) => (
              <Tooltip
                key={index}
                disabled={dokument.nedlastbart}
                text={translate('saksdokumenter.kan-ikke-åpnes')}
              >
                <ListElement
                  data-tip
                  data-for="ikke-nedlastbart-tooltip"
                  disabled={!dokument.nedlastbart}
                  highlighted={dokument.nedlastbart && !dokument.lest}
                  onMouseDown={onMouseDown(dokument)}
                  tabIndex={dokument.nedlastbart ? 0 : -1}
                  href={dokument.nedlastbart ? PDFUrl(sak, dokument) : null}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={
                    dokument.nedlastbart
                      ? `${translate('saksdokumenter.åpne-saksdokument')} ${dokument.dokumentNavn}`
                      : translate('saksdokumenter.kan-ikke-åpnes')
                  }
                >
                  <ListElementItemWithIcon>
                    {dokument.type === DokumentType.Hoveddokument ? (
                      <DescriptionSharp />
                    ) : (
                      <AttachFileSharp />
                    )}
                    <InfoType style={{ marginLeft: '.5rem' }}>{dokument.nummer}</InfoType>
                  </ListElementItemWithIcon>
                  <InfoType>{dokument.dokumentNavn}</InfoType>
                  <InfoType>{dokument.avsender}</InfoType>
                  <InfoType>{dokument.mottaker}</InfoType>
                  <InfoType>{dateFormat(new Date(dokument.dato), 'dd.MM.yyyy')}</InfoType>
                </ListElement>
              </Tooltip>
            ))}
          </DokumentTabellContainer>
        </>
      ) : (
        <>
          <TableMenu>
            <SearchField
              value={søk}
              onChange={e => setSøk(e.target.value)}
              type="text"
              placeholder={translate('saksdokumenter.søk-i-dokumenter')}
              maxWidth="400px"
            />
          </TableMenu>
          <DokumentTabellContainer>
            <Header>
              <HeaderLabel onClick={() => onSort('nummer')} aria-label={getAriaLabel('nummer')}>
                <LabelType>{translate('saksdokumenter.nr').toUpperCase()}</LabelType>
                <SortIcons field="nummer" sort={sort} />
              </HeaderLabel>
              <HeaderLabel
                onClick={() => onSort('dokumentNavn')}
                aria-label={getAriaLabel('dokumentNavn')}
              >
                <LabelType>{translate('saksdokumenter.dokumentnavn').toUpperCase()}</LabelType>
                <SortIcons field="dokumentNavn" sort={sort} />
              </HeaderLabel>
              <HeaderLabel onClick={() => onSort('dato')} aria-label={getAriaLabel('dato')}>
                <LabelType>{translate('saksdokumenter.sendt').toUpperCase()}</LabelType>
                <SortIcons field="dato" sort={sort} />
              </HeaderLabel>
            </Header>
            {dokumenterSortertOgFiltrert.map((dokument, index) => (
              <ListElement
                key={index}
                disabled={!dokument.nedlastbart}
                highlighted={dokument.nedlastbart && !dokument.lest}
                onMouseDown={onMouseDown(dokument)}
                tabIndex={dokument.nedlastbart ? 0 : -1}
                href={dokument.nedlastbart ? PDFUrl(sak, dokument) : null}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={
                  dokument.nedlastbart
                    ? `${translate('saksdokumenter.åpne-saksdokument')} ${dokument.dokumentNavn}`
                    : translate('saksdokumenter.kan-ikke-åpnes')
                }
              >
                <InfoType>{dokument.nummer}</InfoType>
                <InfoType>{dokument.dokumentNavn}</InfoType>
                <InfoType>{dateFormat(new Date(dokument.dato), 'dd.MM.yyyy')}</InfoType>
              </ListElement>
            ))}
          </DokumentTabellContainer>
        </>
      )}
    </>
  );
}
