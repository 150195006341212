import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import Navbar from 'src/components/Navbar';
import DriftsmeldingPanel from 'src/components/Driftsmelding';
import SkipToContent from './SkipToContent';
import { ContentContainer, PageContainer } from './Page.style';
import { selectBruker } from 'src/store/selectors/brukerSelectors';
import { Driftsmelding } from 'src/domain/driftsmelding';
import Footer from './Footer/Footer';

export type PageProps = {
  driftsmelding?: Driftsmelding;
  hideLogout?: boolean;
  children: ReactNode;
};

export default function Page({ driftsmelding, children, hideLogout = false }: PageProps) {
  const bruker = useSelector(selectBruker);
  const navn = bruker ? [bruker.fornavn, bruker.etternavn].join(' ').trim() : null;

  return (
    <>
      <PageContainer>
        <SkipToContent />
        <Navbar fullName={navn} hideLogout={hideLogout && bruker === null} />
        {driftsmelding && <DriftsmeldingPanel driftsmelding={driftsmelding} />}
        <ContentContainer id="content">{children}</ContentContainer>
      </PageContainer>
      <Footer />
    </>
  );
}
