import React from 'react';
import { useHistory } from 'react-router-dom';
import useTranslate from 'src/hooks/useTranslate';
import Page from 'src/components/Page';
import { InfoMessage } from 'src/components/Messages';
import { PrimaryButton } from 'src/components/Buttons';

export const NotFoundMessage = () => {
  const history = useHistory();
  const translate = useTranslate();

  return (
    <InfoMessage title={translate('siden-finnes-ikke')}>
      <PrimaryButton onClick={() => history.push(`/`)}>{translate('til-forsiden')}</PrimaryButton>
    </InfoMessage>
  );
};

const NotFound = () => (
  <Page>
    <NotFoundMessage />
  </Page>
);

export default NotFound;
