import React, { TextareaHTMLAttributes } from 'react';
import styled from 'styled-components';
import { Color } from 'src/constants';
import { InfoType } from '../Typography';

const StyledTextarea = styled.textarea`
  border: none;
  border-bottom: solid 1px ${Color.GreyDark};
  background-color: ${Color.GreyLight};
  height: 144px;
  resize: none;
  font-size: 1rem;
`;

const ErrorType = styled(InfoType)`
  margin-top: 8px;
  color: ${Color.Red};
`;

type TextareaProps = {
  errorMessage?: string;
} & TextareaHTMLAttributes<HTMLTextAreaElement>;

const Textarea = ({ errorMessage, ...rest }: TextareaProps) => (
  <>
    <StyledTextarea {...rest} />
    {errorMessage && <ErrorType>{errorMessage}</ErrorType>}
  </>
);

export default Textarea;
