import React from 'react';
import styled from 'styled-components';
import { Color } from 'src/constants';
import { FieldInputProps } from 'react-final-form';

const HiddenRadioButton = styled.input`
  position: absolute;
  z-index: -1;
`;

const StyledRadioButton = styled.div`
  position: absolute;
  border: 2px solid ${Color.GreyDark};
  border-radius: 100%;
  height: 20px;
  width: 20px;
  transition: all 150ms;
  &::before {
    position: absolute;
    content: '';
    border-radius: 100%;
    height: 10px;
    width: 10px;
    top: 3px;
    left: 3px;
    margin: auto;
    ${HiddenRadioButton}:checked + & {
      background: ${Color.dsBlue};
    }
  }
  ${HiddenRadioButton}:checked + & {
    border: 2px solid ${Color.dsBlue};
  }
  ${HiddenRadioButton}:focus + & {
    box-shadow: 0 0 0 3px rgba(210, 35, 30, 0.5);
  }
`;

const RadioButtonContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  &:hover ${StyledRadioButton} {
    border: 2px solid ${Color.dsBlue};
  }
`;

const StyledLabel = styled.label`
  position: relative;
  color: ${Color.GreyDark};
  font-size: 14px;
  padding: 8px;
  font-weight: 400;
  line-height: 1.25rem;
  cursor: pointer;
  padding-left: 28px;
`;

type RadioButtonProps = {
  label: string;
  id: string;
  input: FieldInputProps<string, HTMLElement>;
};

const RadioButton = ({ label, id, input }: RadioButtonProps) => (
  <RadioButtonContainer>
    <HiddenRadioButton id={id} {...input} />
    <StyledRadioButton />
    <StyledLabel htmlFor={id}>{label}</StyledLabel>
  </RadioButtonContainer>
);

export default RadioButton;
