import { Habilitet } from 'src/domain/sak';
import useFetch from 'src/hooks/useFetch';
import { useAppDispatch } from 'src/store';
import { sakerActions } from 'src/store/sakerStore';
import { WithLoaderProps } from './withLoader';

type WithHabilitetStatusForSakProps = {
  sakId: string;
};

const withHabilitetStatusForSak =
  <P extends Record<string, unknown>>(
    Component: React.ComponentType<P & WithLoaderProps>
  ): React.ComponentType<P & WithHabilitetStatusForSakProps & WithLoaderProps> =>
  props => {
    const dispatch = useAppDispatch();

    const response = useFetch<Habilitet>(`/saker/${props.sakId}/habilitet`, habilitet => {
      dispatch(
        sakerActions.setHabilitetStatusForSak({
          sakId: props.sakId,
          habilitetStatus: habilitet.status,
          partOgAktørIderVedHabilitetsvurdering: habilitet.partOgAktørIderVedHabilitetsvurdering,
        })
      );
    });

    const isLoading = props.isLoading || response.isNotAsked || response.isPending;
    const isError = props.isError || response.isError;

    return <Component {...props} isLoading={isLoading} isError={isError} />;
  };

export default withHabilitetStatusForSak;
