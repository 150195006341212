import React, { ReactNode } from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import { H3 } from 'src/components/Typography';

const ActionsContainer = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 12px;

  > *:not(:last-child) {
    margin-right: 12px;
  }
`;

// 224 er 320 (min-bredde) minus marginene til dialog-komponenten
const PaddedContainer = styled.div`
  min-width: 224px;
  padding: 8px 32px;
`;

// https://www.w3.org/TR/wai-aria-practices/examples/dialog-modal/dialog.html

type ModalProps = {
  id: string;
  open: boolean;
  onClose: () => void;
  title: string;
  renderContent: () => ReactNode;
  renderActions: () => ReactNode;
};

export default function Modal({
  id,
  title,
  open,
  onClose,
  renderContent,
  renderActions,
}: ModalProps) {
  const labelId = `${id}-title`;
  const descriptionId = `${id}-description`;
  return (
    <Dialog
      open={open}
      aria-labelledby={labelId}
      aria-describedby={descriptionId}
      aria-modal
      fullWidth
      maxWidth="sm"
      onClose={onClose}
    >
      <PaddedContainer>
        <H3 id={labelId}>{title}</H3>
        <div id={descriptionId}>{renderContent()}</div>
        <ActionsContainer>{renderActions()}</ActionsContainer>
      </PaddedContainer>
    </Dialog>
  );
}
