import React, { createRef, useEffect } from 'react';
import Cookies from 'js-cookie';

import useLogoutCallback from 'src/hooks/useLogoutCallback';
import { BASE_URL, AuthSessionExpirationCookie } from 'src/constants';

export default function HiddenLogoutForm() {
  const ref = createRef<HTMLFormElement>();
  const { setLogoutCallback, redirectToAvregistreringPage } = useLogoutCallback();

  useEffect(() => {
    const form = ref.current;

    setLogoutCallback(() => {
      Cookies.remove(AuthSessionExpirationCookie);
      form.submit();
    });
  }, []);

  return (
    <form
      method="post"
      action={`${BASE_URL}/signout?avregistrert=${redirectToAvregistreringPage}`}
      style={{ display: 'none' }}
      aria-hidden="true"
      ref={ref}
    />
  );
}
