import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import dateFormat from 'date-fns/format';
import dateIsValid from 'date-fns/isValid';
import { isSuccess, post } from 'src/ajax';
import useInterval from 'src/hooks/useInterval';
import useLogoutCallback from 'src/hooks/useLogoutCallback';
import useTranslate from 'src/hooks/useTranslate';
import { PrimaryButton, SecondaryButton } from 'src/components/Buttons';
import { BodyType, InfoType } from 'src/components/Typography';
import { AuthSessionExpirationCookie } from 'src/constants';
import Modal from '../Modal';
import useShowErrorMessage from 'src/hooks/useShowErrorMessage';

const CenteredBodyType = styled(BodyType).attrs({ as: 'p' })`
  width: 100%;
  text-align: center;
`;

const oneSecond = 1000;
const thirtySeconds = 30 * 1000;
const fiveMinutes = 5 * 60 * 1000;

export default function InactiveLogoutModal() {
  const { logoutCallback } = useLogoutCallback();
  const translate = useTranslate();
  const showErrorMessage = useShowErrorMessage();
  const [sessionExpiryDate, setSessionExpiryDate] = useState<Nullable<number>>(null);
  const [msUntilLogout, setMsUntilLogout] = useState<Nullable<number>>(null);

  const updateSessionExpiryDate = () => {
    const cookieValue = Cookies.get(AuthSessionExpirationCookie);

    if (cookieValue) {
      const sessionExpiryDate = new Date(cookieValue);

      if (dateIsValid(sessionExpiryDate)) {
        setSessionExpiryDate(sessionExpiryDate.getTime());
      }
    }
  };

  const updateSecondsUntilLogout = () => {
    if (sessionExpiryDate !== null) {
      setMsUntilLogout(Math.max(sessionExpiryDate - Date.now(), 0));
    }
  };

  useInterval(updateSessionExpiryDate, thirtySeconds);
  useInterval(updateSecondsUntilLogout, oneSecond);

  useEffect(() => {
    if (msUntilLogout === 0) {
      logoutCallback();
    }
  }, [msUntilLogout]);

  const extendSession = async () => {
    const response = await post('/bruker/forlengSesjon');

    if (isSuccess(response)) {
      setSessionExpiryDate(null);
      setMsUntilLogout(null);
    } else {
      showErrorMessage('inactive-logout-modal.feil-beskrivelse');
    }
  };

  if (msUntilLogout === null || msUntilLogout > fiveMinutes) {
    return null;
  }

  return (
    <Modal
      id="inactive-logout-modal"
      open
      onClose={extendSession}
      title={translate('inactive-logout-modal.tittel')}
      renderContent={() => (
        <>
          <InfoType as="p">{translate('inactive-logout-modal.beskrivelse')}</InfoType>
          <CenteredBodyType role="timer">{dateFormat(msUntilLogout, 'mm:ss')}</CenteredBodyType>
        </>
      )}
      renderActions={() => (
        <>
          <SecondaryButton onClick={logoutCallback}>{translate('logg-ut')}</SecondaryButton>
          <PrimaryButton autoFocus onClickAsync={extendSession}>
            {translate('inactive-logout-modal.knapp-forbli-innlogget')}
          </PrimaryButton>
        </>
      )}
    />
  );
}
