import React from 'react';
import useTranslate from 'src/hooks/useTranslate';
import Page from 'src/components/Page';
import { NotFoundMessage } from 'src/pages/NotFound';
import { LoadingMessage, ErrorMessage } from 'src/components/Messages';

type WithLoaderProps = {
  isLoading?: boolean;
  hasError?: boolean;
  notFound?: boolean;
};

const withLoaderBuilder =
  (
    LoadingComponent: () => JSX.Element,
    ErrorComponent: () => JSX.Element,
    NotFoundComponent: () => JSX.Element
  ) =>
  <P extends Record<string, unknown>>(
    Component: React.ComponentType<P>
  ): React.ComponentType<WithLoaderProps & P> =>
  props => {
    if (props.isLoading) {
      return <LoadingComponent />;
    }

    if (props.hasError) {
      return <ErrorComponent />;
    }

    if (props.notFound) {
      return <NotFoundComponent />;
    }

    return <Component {...props} />;
  };

const TranslatedLoader = (key: string) => () => {
  const translate = useTranslate();
  return <LoadingMessage title={translate(key)} />;
};

const TranslatedError = (key: string) => () => {
  const translate = useTranslate();
  return <ErrorMessage title={translate(key)} />;
};

const withLoader = (loadingKey: string, errorKey: string) =>
  withLoaderBuilder(TranslatedLoader(loadingKey), TranslatedError(errorKey), NotFoundMessage);

const AktørportalenLoader = () => {
  const translate = useTranslate();
  return (
    <Page hideLogout>
      <LoadingMessage title={translate('laster-aktorportalen')} />
    </Page>
  );
};

const AktørportalenError = () => {
  const translate = useTranslate();
  return (
    <Page hideLogout>
      <ErrorMessage title={translate('laster-aktorportalen-feilet')} />
    </Page>
  );
};

export type { WithLoaderProps };

export default withLoader;

export const withAktørportalenLoader = withLoaderBuilder(
  AktørportalenLoader,
  AktørportalenError,
  NotFoundMessage
);
