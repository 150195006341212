import React from 'react';
import styled from 'styled-components';
import Select, { StylesConfig, Props as ReactSelectProps } from 'react-select';

import { Color } from 'src/constants';
import { InfoType } from '../Typography';

type OptionType = {
  label: string;
  value: string;
  [key: string]: unknown;
};

const colorStyles: StylesConfig<OptionType, false> = {
  container: styles => ({ ...styles, maxWidth: '288px' }),
  control: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: Color.GreyLight,
    border: 'none',
    borderRadius: 'none',
    borderBottom: `solid 1px ${Color.GreyDark}`,
    boxShadow: isFocused ? '0 0 0 3px rgba(210, 35, 30, 0.5)' : null,
    ':hover': {
      ...styles[':hover'],
      borderBottom: `solid 1px ${Color.GreyDark}`,
    },
  }),
  option: (styles, { isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected ? Color.Red : isFocused ? 'rgb(210, 35, 30, 0.1)' : null,
      color: isSelected ? 'white' : Color.GreyDark,

      ':active': {
        ...styles[':active'],
        backgroundColor: isSelected ? Color.Red : 'rgb(210, 35, 30, 0.3)',
      },
    };
  },
  indicatorSeparator: styles => ({ ...styles, display: 'none' }),
  dropdownIndicator: (styles, { selectProps: { menuIsOpen } }) => {
    return {
      ...styles,
      color: Color.GreyDark,
      transition: 'all .2s ease',
      transform: menuIsOpen ? 'rotate(180deg)' : null,
      ':hover': {
        ...styles[':hover'],
        color: Color.GreyDark,
      },
    };
  },
};

const ErrorType = styled(InfoType)`
  margin-top: 8px;
  color: ${Color.Red};
`;

type DropdownProps = {
  errorMessage?: string;
} & ReactSelectProps;

const Dropdown = ({ options, errorMessage, ...rest }: DropdownProps) => (
  <>
    <Select options={options} styles={colorStyles} {...rest} />
    {errorMessage && <ErrorType>{errorMessage}</ErrorType>}
  </>
);
export default Dropdown;
