import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Field, Form, FormSpy } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { useHistory } from 'react-router-dom';
import { withInnloggetBrukerPage } from 'src/hoc/withPage';
import { PrimaryButton } from '../../components/Buttons';
import { Color, Språk } from 'src/constants';
import {
  H2,
  H3,
  InfoType,
  ErrorType,
  ConfirmationType,
  RegularType,
} from 'src/components/Typography';
import { isSuccess, post } from 'src/ajax';
import { brukerActions } from 'src/store/brukerStore';
import useTranslate from 'src/hooks/useTranslate';
import useShowErrorMessage from 'src/hooks/useShowErrorMessage';
import Label from './components/Label';
import FinalFormEmail from './components/FinalFormEmail';
import FinalFormPhone from './components/FinalFormPhone';
import Checkbox from './components/Checkbox';
import RadioButton from './components/RadioButton';
import { selectBruker } from 'src/store/selectors/brukerSelectors';
import { GrayContainer, ResponsiveContainer } from 'src/components/Container/Container';

const Heading1 = styled(H2).attrs({ as: 'h1', noMargin: true })`
  margin-bottom: 40px;
`;
const Heading2 = styled(H3).attrs({ as: 'h2', noMargin: true })`
  margin-bottom: 20px;
`;

const Heading3 = styled(H3).attrs({ as: 'h2', noMargin: true })`
  margin-bottom: 20px;
  font-size: 20px;
`;

const StyledHr = styled.hr`
  border-left: solid 1px ${Color.GreyNormal};
  margin-top: 40px;
  margin-bottom: 32px;
  width: 100%;
`;

const BekreftProfil = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const translate = useTranslate();
  const showErrorMessage = useShowErrorMessage();

  const bruker = useSelector(selectBruker);

  const validateNotificationFields = (_: never, values: FormValues) =>
    !values.epostVarsling && !values.telefonVarsling
      ? translate('profil.validering-varslingskanal')
      : undefined;

  const onSubmit = async (values: FormValues) => {
    const oppdatertKontaktinfo = {
      epost: {
        adresse: values.epost,
        varsling: values.epostVarsling,
      },
      telefon: {
        nummer: values.telefon,
        varsling: values.telefonVarsling,
        // Sender tilbake samme landkode som vi hentet ut
        landkode: bruker.telefon.landkode,
      },
      valgtSpråk: values.valgtSpråk,
    };

    const response = await post('/bruker', oppdatertKontaktinfo);

    if (isSuccess(response)) {
      dispatch(brukerActions.setBruker(Object.assign({}, bruker, oppdatertKontaktinfo)));
      dispatch(brukerActions.bekreftKontaktinfo());
      history.replace('/');
    } else {
      return { [FORM_ERROR]: 'profil.oppdatering-feilet' };
    }
  };

  type FormValues = {
    epost: string;
    telefon: string;
    epostVarsling: boolean;
    telefonVarsling: boolean;
    valgtSpråk: Språk;
  };

  const initialValues: FormValues = {
    epost: !!bruker.epost.adresse ? bruker.epost.adresse : undefined,
    telefon: !!bruker.telefon.nummer ? bruker.telefon.nummer : undefined,
    epostVarsling: bruker.epost.varsling,
    telefonVarsling: bruker.telefon.varsling,
    valgtSpråk: !!bruker.valgtSpråk ? bruker.valgtSpråk : undefined,
  };

  return (
    <>
      <GrayContainer>
        <Heading1>{translate('profil.bekreft-info')}</Heading1>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ errors, handleSubmit, pristine, submitSucceeded, submitting, touched }) => {
            const showSubmitSucceeded = pristine && submitSucceeded;

            const showKontaktinformasjonError =
              (touched.epostVarsling || touched.telefonVarsling) && errors.epostVarsling;

            return (
              <form onSubmit={handleSubmit}>
                <RegularType>{translate('profil.bekreft-info-tekst')}</RegularType>

                <StyledHr />
                <Heading3>{translate('profil.kontaktinformasjon')}</Heading3>
                <Label value={translate('profil.navn')}>
                  <p style={{ marginLeft: '15px' }}>
                    <InfoType>{`${bruker.fornavn} ${bruker.etternavn}`}</InfoType>
                  </p>
                </Label>

                <ResponsiveContainer>
                  <FinalFormEmail
                    name="epost"
                    label={`${translate('profil.epost')} *`}
                    style={{ flex: 2 }}
                    aria-required="true"
                  />
                  <FinalFormPhone
                    name="telefon"
                    label={`${translate('profil.mobil')} *`}
                    style={{ flex: 1 }}
                    aria-required="true"
                  />
                </ResponsiveContainer>

                <StyledHr />

                <Heading2>{translate('profil.preferanser')}</Heading2>

                <Label value={`${translate('profil.varsling')} *`}>
                  <ResponsiveContainer>
                    <Field
                      name="epostVarsling"
                      type="checkbox"
                      render={props => (
                        <Checkbox label="E-post" id="notify-opt-1" input={props.input} />
                      )}
                      validate={validateNotificationFields}
                    />
                    <Field
                      name="telefonVarsling"
                      type="checkbox"
                      render={props => (
                        <Checkbox label="SMS" id="notify-opt-2" input={props.input} />
                      )}
                      validate={validateNotificationFields}
                    />
                  </ResponsiveContainer>
                  <ErrorType aria-live="polite" noMargin={!showKontaktinformasjonError}>
                    {showKontaktinformasjonError && errors.epostVarsling}
                  </ErrorType>
                </Label>

                <Label value={`${translate('profil.språk')} *`}>
                  <ResponsiveContainer>
                    <Field
                      name="valgtSpråk"
                      render={props => <RadioButton input={props.input} label="Bokmål" id="nb" />}
                      type="radio"
                      value="nb"
                    />
                    <Field
                      name="valgtSpråk"
                      render={props => <RadioButton input={props.input} label="Nynorsk" id="nn" />}
                      type="radio"
                      value="nn"
                    />
                  </ResponsiveContainer>
                </Label>

                <StyledHr />

                <ResponsiveContainer>
                  <PrimaryButton type="submit" isLoading={submitting}>
                    {translate('profil.lagre-videre')}
                  </PrimaryButton>
                </ResponsiveContainer>

                <ConfirmationType as="p" aria-live="polite" noMargin={!showSubmitSucceeded}>
                  {showSubmitSucceeded ? translate('endringer-lagret') : ''}
                </ConfirmationType>

                <FormSpy
                  subscription={{ submitError: true }}
                  onChange={({ submitError }) => {
                    if (submitError) {
                      showErrorMessage(submitError);
                    }
                  }}
                />
              </form>
            );
          }}
        />
      </GrayContainer>
    </>
  );
};

export default withInnloggetBrukerPage(BekreftProfil);
