import React from 'react';
import MailSharp from '@material-ui/icons/MailSharp';
import PhoneSharp from '@material-ui/icons/PhoneSharp';
import { InfoType } from 'src/components/Typography';
import { SøknadFooterContainer } from './Søknad.style';

type FooterProps = {
  epost: string;
  telefon: string;
  children: React.ReactNode;
};

export default function Footer({ epost, telefon, children }: FooterProps) {
  return (
    <SøknadFooterContainer>
      <div>
        <InfoType truncate as="a" href={`mailto:${epost}`}>
          <MailSharp />
          <span>{epost}</span>
        </InfoType>
        <InfoType truncate as="a" href={`tel:${telefon}`}>
          <PhoneSharp />
          <span>{telefon}</span>
        </InfoType>
      </div>
      <div>{children}</div>
    </SøknadFooterContainer>
  );
}
