import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import SearchSharp from '@material-ui/icons/SearchSharp';
import { Color } from 'src/constants';

const Container = styled.div<{ maxWidth: string }>`
  display: flex;
  height: 40px;
  width: calc(100% - 92px);
  max-width: ${props => props.maxWidth};
  display: inline-block;
  position: relative;
`;

const StyledInput = styled.input`
  width: 100%;
  height: inherit;
  background-color: rgba(45, 45, 45, 0.08);
  border: none;
  padding: 4px 16px;
  border-bottom: solid 1px ${Color.GreyNormal};
  font-size: 1rem;
  line-height: 1.25rem;
  border-radius: 0;
  color: ${Color.GreyDark};

  ::placeholder {
    opacity: 0.56;
  }
`;

const StyledSearch = styled(SearchSharp)`
  margin: 8px -36px;
  opacity: 0.56;
  position: absolute;
`;

type SearchFieldProps = {
  maxWidth: string;
} & InputHTMLAttributes<HTMLInputElement>;

const SearchField = ({
  className,
  onChange,
  placeholder,
  value,
  maxWidth,
  ...rest
}: SearchFieldProps) => {
  return (
    <Container maxWidth={maxWidth}>
      <StyledInput
        value={value || ''}
        onChange={onChange}
        className={className}
        aria-label={placeholder}
        placeholder={placeholder}
        {...rest}
      />
      <StyledSearch />
    </Container>
  );
};

export default SearchField;
