import { RootState } from '..';

export const selectBruker = (state: RootState) => state.brukerStore.bruker;

export const selectMåAkseptereBrukervilkår = (state: RootState) =>
  state.brukerStore.bruker?.måAkseptereBrukervilkår;

export const selectValgtSpråk = (state: RootState) => state.brukerStore.bruker?.valgtSpråk;

export const selectMåBekrefteKontaktinformasjon = (state: RootState) =>
  state.brukerStore.bruker?.måBekrefteKontaktInfo;
