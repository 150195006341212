import React from 'react';
import { NavLink as RouterNavLink, NavLinkProps, useLocation } from 'react-router-dom';

// Bruk denne wrapper-komponenten i stedet for å referere til react-router-dom direkte.
// React Router sin NavLink pusher til history selv om man allerede er på siden.

export default function NavLink({ replace, ...rest }: NavLinkProps) {
  const { pathname, search, hash } = useLocation();
  const location = pathname + search + hash;

  return <RouterNavLink replace={replace || location === rest.to} {...rest} />;
}
