import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTranslate from 'src/hooks/useTranslate';
import { errorModalActions } from 'src/store/errorModalStore';
import { PrimaryButton } from 'src/components/Buttons';
import { InfoType } from 'src/components/Typography';
import Modal from '../Modal';
import { selectErrorModalState } from 'src/store/selectors/errorModalSelectors';

export default function ErrorModal() {
  const dispatch = useDispatch();
  const { open, title, message, link } = useSelector(selectErrorModalState);
  const translate = useTranslate();
  const onClose = () => dispatch(errorModalActions.hide());

  if (!open) {
    return null;
  }

  return (
    <Modal
      id="error-modal"
      open
      onClose={onClose}
      title={title}
      renderContent={() => (
        <>
          <InfoType style={{ textAlign: 'right' }} as="p">
            {translate('tidspunkt')}: {new Date().toLocaleString(translate('locale'))}
          </InfoType>
          <InfoType as="p">{message}</InfoType>
          {link && <a href={link}>{translate('les-mer')}</a>}
        </>
      )}
      renderActions={() => (
        <>
          <PrimaryButton autoFocus onClick={onClose}>
            {translate('lukk')}
          </PrimaryButton>
        </>
      )}
    />
  );
}
