import React from 'react';
import useTranslate from 'src/hooks/useTranslate';
import { PrimaryButton } from 'src/components/Buttons';
import { H1 } from 'src/components/Typography';
import { HorizontalHr, SøknadInfo } from './Søknad.style';
import Footer from './Footer';

type KvitteringProps = {
  tittel: string;
  epost: string;
  telefon: string;
  onTilbake: () => void;
  children: React.ReactNode;
};

export default function Kvittering({
  tittel,
  epost,
  telefon,
  onTilbake,
  children,
}: KvitteringProps) {
  const translate = useTranslate();

  return (
    <>
      <H1>{tittel}</H1>
      <SøknadInfo>{children}</SøknadInfo>
      <HorizontalHr />
      <Footer epost={epost} telefon={telefon}>
        <PrimaryButton role="link" onClick={onTilbake}>
          {translate('tilbake')}
        </PrimaryButton>
      </Footer>
    </>
  );
}
