import React, { AnchorHTMLAttributes } from 'react';
import styled from 'styled-components';
import { InfoType } from 'src/components/Typography';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { SvgIconTypeMap } from '@material-ui/core';

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const A = styled(InfoType).attrs({ as: 'a' })<AnchorHTMLAttributes<HTMLAnchorElement>>`
  color: inherit;
  margin-left: 0.5rem;
  text-decoration: none;
  white-space: nowrap;
`;

type IconLinkProps = {
  Icon: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>>;
  href: string;
  text: string;
  [x: string]: unknown;
};

export default function IconLink({ Icon, href, text, ...props }: IconLinkProps) {
  return (
    <FlexRow {...props}>
      <Icon />
      <A href={href}>{text}</A>
    </FlexRow>
  );
}
