import React from 'react';

import useLogoutCallback from 'src/hooks/useLogoutCallback';
import useTranslate from 'src/hooks/useTranslate';
import { StyledNavButton } from './Menu.style';

export default function LogoutNavButton() {
  const { logoutCallback } = useLogoutCallback();
  const translate = useTranslate();

  return (
    <StyledNavButton aria-label={translate('logg-ut')} onClick={logoutCallback} noUnderline>
      {translate('logg-ut')}
    </StyledNavButton>
  );
}
