import React from 'react';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import styled from 'styled-components';

type FooterLinkProps = {
  children: string;
  href: string;
};

const FooterLinkAnchor = styled.a`
  font-family: 'Proxima Nova';
  font-weight: 400;
  font-size: 14px;
  color: #2d2d2d;
`;

const FooterLinkText = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 6px;
`;

function FooterLink({ children, href }: FooterLinkProps) {
  return (
    <FooterLinkAnchor href={href} target="_blank">
      <FooterLinkText>
        {children}
        <OpenInNewIcon fontSize="inherit" />
      </FooterLinkText>
    </FooterLinkAnchor>
  );
}

export default FooterLink;
